import React from 'react';
import {Sidebar} from '../components/Sidebar';
import {ids} from '../utils/constants';
import {useExpiresToken} from '../hooks/useExpiresToken';
import styles from './layouts.module.scss';
import {Header} from './Header';
import {ILayoutsProps} from './types';
import {Footer} from './Footer';

export const MainLayout = ({children}: ILayoutsProps) => {
    useExpiresToken();
    return (
        <div className={styles.wrapper}>
            <Header/>
            <div className={styles.content}>
                <div className={styles.content__box}>
                    <Sidebar/>
                </div>
                <div className={styles.content__box} id={ids.layoutRightBox}>
                    {children}
                </div>
            </div>
            <Footer/>
        </div>
    );
};
