import React from 'react';
import {useExpiresToken} from '../hooks/useExpiresToken';
import {useScrollTop} from '../hooks/useScrollTop';
import {IconButton} from '../fields';
import {IconButtonType} from '../fields/types';
import {ArrowUp} from '../icons';
import {colors} from '../utils/constants';
import {ISingleProps} from './types';
import styles from './layouts.module.scss';
import {Header} from './Header';
import {Footer} from './Footer';

export const SingleLayout = ({children}: ISingleProps) => {
    const {showScrollTop, scrollTop} = useScrollTop();
    useExpiresToken();

    return (
        <div className={styles.wrapper}>
            <Header/>
            <div className={styles.contentColumn}>
                {children}
                {showScrollTop
                    ? (<IconButton
                        text={''}
                        className={styles.scrollTopButton}
                        icon={<ArrowUp width={24} height={24} color={colors.blue} />}
                        viewType={IconButtonType.outline}
                        type={'button'}
                        onClick={scrollTop}
                    />)
                    : null}
            </div>
            <Footer/>

        </div>
    );
};
