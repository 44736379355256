import React, {useState} from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import classNames from 'classnames';
import {Button, Checkbox, Input, Select} from '../../../fields';
import {ButtonType} from '../../../fields/types';
import {useResizeWindow} from '../../../hooks';
import {Close} from '../../../icons';
import {colors} from '../../../utils/constants';
import styles from './componentsStyle.module.scss';

export const Filter = () => {
    const width = useResizeWindow();
    const [show, setShow] = useState<boolean>(false);
    const methods = useForm();

    const submit = methods.handleSubmit(values => {

    });

    return !show && width < 1000
        ? (
            <div
                className={styles.button}>
                <Button text={'Фильтры'} viewType={ButtonType.link} onClick={() => setShow(true)}/>
            </div>
        ) : (
            <FormProvider {...methods} >
                <div className={styles.filter}>
                    <div className={styles.form}>
                        <div className={styles.form__header}>
                            <h3 className={styles.form__title}>Фильтры</h3>
                            <Close
                                width={40}
                                height={40}
                                color={colors.grey200}
                                style={{cursor: 'pointer'}}
                                onClick={() => setShow(false)}
                            />
                        </div>
                        <div className={styles.form__row}>
                            <div className={classNames(styles.form__box, styles.form__boxGrow)}>
                                <Select
                                    label={'Город'}
                                    options={[]}
                                    multiple={false}
                                    control={methods.control}
                                    fieldName={'1'}
                                    required={false}
                                    register={methods.register}
                                    errors={methods.formState.errors}/>
                            </div>
                            <div className={classNames(styles.form__box, styles.form__boxGrow)}>
                                <Input
                                    label={'Удаленность до, км'}
                                    placeholder={''}
                                    register={methods.register}
                                    control={methods.control}
                                    fieldName={'2'}
                                    errors={methods.formState.errors}
                                    required={false}
                                />
                            </div>
                            <div className={classNames(styles.form__box, styles.form__boxGrow)}>
                                <Input
                                    label={'Бренд'}
                                    placeholder={''}
                                    register={methods.register}
                                    control={methods.control}
                                    fieldName={'3'}
                                    errors={methods.formState.errors}
                                    required={false}
                                />
                            </div>
                            <div className={classNames(styles.form__box, styles.form__boxGrow)}>
                                <Select
                                    label={'Доставка'}
                                    options={[]}
                                    multiple={false}
                                    control={methods.control}
                                    fieldName={'1'}
                                    required={false}
                                    register={methods.register}
                                    errors={methods.formState.errors}/>
                            </div>
                            <div className={classNames(styles.form__box, styles.form__boxGrow)}>
                                <Select
                                    label={'Тип товаров'}
                                    options={[]}
                                    multiple={false}
                                    control={methods.control}
                                    fieldName={'5'}
                                    required={false}
                                    register={methods.register}
                                    errors={methods.formState.errors}/>
                            </div>
                            <div className={classNames(styles.form__box, styles.form__boxGrow)}>
                                <Checkbox label={'Проверенные'} state={true} changeState={() => null}/>
                            </div>
                        </div>
                        <div className={styles.form__row}>
                            <div className={styles.form__box}>
                                <Checkbox label={'Избранные поставщики'} state={false} changeState={() => null}/>
                            </div>
                        </div>
                        <div className={styles.form__buttons}>
                            <Button
                                text={'Подобрать'}
                                className={styles.form__button}
                                viewType={ButtonType.filling}
                                onClick={submit}/>
                            <Button
                                text={'Очистить фильтры'}
                                className={styles.form__button}
                                viewType={width > 1000 ? ButtonType.link : ButtonType.outline}
                                onClick={() => methods.reset()}/>
                        </div>
                    </div>
                </div>
            </FormProvider>
        );
};
