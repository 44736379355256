import {createAsyncThunk} from '@reduxjs/toolkit';
import {IQueryItem} from '../../helpers/types';
import {IResponseSearch, IStateApp} from '../../store/types';
import {api} from '../../api';
import {helperHandleError} from '../../helpers';
import {IReqData, IRequest} from '../../utils/commonTypes';
import {setStepsToTypes} from '../../helpers/setStepsToTypes';
import {IDisk} from './types';
import {clearDisks, setFilterDisksPayload} from './Disks.slice';

export const sendFilterDisks = createAsyncThunk(
    'disks/sendFilterDisks',
    async ({data, sort}:IRequest, {dispatch, getState, signal}) => {
        const {app} = getState() as { app: IStateApp };
        try {
            await dispatch(clearDisks());
            await dispatch(setFilterDisksPayload(data));

            const reqData:IReqData = {query: data};
            if(sort.key !== null) {
                reqData.order_by = sort;
            }

            const res = await api.post<IResponseSearch<IDisk[]>>('/search/disks', reqData, {
                headers: {
                    'Authorization': `${app.token?.token_type} ${app.token?.token}`
                }, signal
            });
            const result = await res.data;
            if (Object.values(data).some(i => i.group === 'rear')) {
                return {
                    data: setStepsToTypes(result.data) as IDisk[],
                    meta: result.meta,
                    links: result.links
                };
            }
            return {
                data: result.data,
                meta: result.meta,
                links: result.links
            };
        } catch (e) {

            helperHandleError('sendFilter, disks', e, dispatch);
        }
    }
);

export const getPageDisk = createAsyncThunk(
    'disks/getPage',
    async ({data, url, sort}:IRequest, {dispatch, getState}) => {
        const {app} = getState() as { app: IStateApp };
        try {
            await dispatch(clearDisks());

            const reqData:IReqData = {query: data};
            if(sort.key !== null) reqData.order_by = sort;

            const res = await api.post<IResponseSearch<IDisk[]>>(url, reqData, {
                headers: {
                    'Authorization': `${app.token?.token_type} ${app.token?.token}`
                }
            });
            const result = await res.data;
            if (Object.values(data).some(i => i.group === 'rear')) {
                return {
                    data: setStepsToTypes(result.data) as IDisk[],
                    meta: result.meta,
                    links: result.links
                };
            }
            return {
                data: result.data,
                meta: result.meta,
                links: result.links
            };
        } catch (e) {
            helperHandleError('getPage, tires', e, dispatch);
        }
    }
);

export const showMoreDisks = createAsyncThunk(
    'disks/showMoreTires',
    async ({data, url, sort}:IRequest, {dispatch, getState}) => {
        const {app} = getState() as { app: IStateApp };
        try {
            const reqData:IReqData = {query: data};
            if(sort.key !== null) reqData.order_by = sort;

            const res = await api.post<IResponseSearch<IDisk[]>>(url, reqData, {
                headers: {
                    'Authorization': `${app.token?.token_type} ${app.token?.token}`
                }
            });
            const result = await res.data;
            if (Object.values(data).some(i => i.group === 'rear')) {
                return {
                    data: setStepsToTypes(result.data) as IDisk[],
                    meta: result.meta,
                    links: result.links
                };
            }
            return {
                data: result.data,
                meta: result.meta,
                links: result.links
            };
        } catch (e) {
            helperHandleError('getPage, tires', e, dispatch);
        }
    }
);

export const getDisksRests = createAsyncThunk(
    'disks/getTiresRests',
    async ({data, product_id}: { data: IQueryItem[], product_id: string }, {dispatch, getState}) => {
        const {app} = getState() as { app: IStateApp };
        try {
            const res = await api.post<IResponseSearch<any[]>>(`/product/${product_id}/rests`, {query: data}, {
                headers: {
                    'Authorization': `${app.token?.token_type} ${app.token?.token}`
                }
            });
            return res.data;
        } catch (e) {
            helperHandleError('getPage, tires', e, dispatch);
        }
    }
);
