import React, {useState} from 'react';
import {EyeOff, Eye} from '../../icons';
import {colors} from '../../utils/constants';
import {IEyeComponentProps} from './types';
import styles from './EyeComponent.module.scss';

export const EyeComponent = ({initState = false, onChange}: IEyeComponentProps) => {
    const [isShow, setShow] = useState<boolean>(initState);

    return (
        <div className={styles.eyeComponent} onClick={() => {
            setShow(!isShow);
            onChange(!isShow);
        }}>
            {isShow
                ? <Eye
                    width={24}
                    height={24}
                    color={colors.grey200}/>
                : <EyeOff
                    width={24}
                    height={24}
                    color={colors.grey200}/>
            }
        </div>
    );
};

