import React, {useState, KeyboardEvent, useEffect} from 'react';
import classNames from 'classnames';
import {Checked, Unchecked} from '../icons';
import {colors} from '../utils/constants';
import {Tooltip} from '../components/Tooltip';
import styles from './fields.module.scss';
import {CheckboxProps} from './types';

export const Checkbox = (
    {
        state = false,
        label,
        changeState,
        tooltip,
        ...props
    }: CheckboxProps
) => {
    const [isHover, setHover] = useState(false);
    const [checked, setChecked] = useState<boolean>(false);

    useEffect(() => {
        setChecked(state);
    }, []);

    const handleChecked = () => {
        changeState(!checked);
        setChecked(!checked);
    };

    const listenKeyDown = (event:KeyboardEvent<HTMLInputElement>) => {
        if( event.code === 'Space') {
            setChecked(!checked);
            changeState(!checked);
        }
    };

    return (
        <div {...props} className={classNames(styles.checkbox, props.className)} onClick={handleChecked}>
            <input
                type={'text'}
                className={styles.hideInput}
                onFocus={() => setHover(true)}
                onKeyDown={(event:KeyboardEvent<HTMLInputElement>) => listenKeyDown(event)}
                onBlur={() => setHover(false)} />
            {checked
                ? <Checked width={24} height={24} color={colors.blue}/>
                : <Unchecked
                    width={24}
                    height={24}
                    color={isHover ? colors.grey100 : colors.grey300}
                    onMouseOver={() => setHover(true)}
                    onMouseOut={() => setHover(false)}
                />}
            {label
                ? <span
                    className={
                        checked
                            ? classNames(styles.checkbox__label, styles.colorActive)
                            : styles.checkbox__label
                    }
                >{label}{tooltip !== undefined && <Tooltip colorIcon={colors.grey100} text={tooltip} width={200}/>} </span>: null}
        </div>
    );
};
