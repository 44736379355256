import React from 'react';
import { IconButton} from '../../../../fields';
import {IconButtonType} from '../../../../fields/types';
import {File} from '../../../../icons';
import {colors} from '../../../../utils/constants';
import styles from './styles.module.scss';
import {DataItem} from './DataItem';


export const CompanyData = () => {
    return (
        <>
            <h3 className={styles.company__subtitle}>Основная информация</h3>
            <div className={styles.company__info}>
                <DataItem label={'Публичный Телефон'} text={'Показать телефон'}/>
                <DataItem label={'Страна'} text={'Россия'}/>
                <DataItem label={'Публичный Email'} text={'company_A_examplemail@gmail.com'}/>
                <DataItem label={'Город'} text={'Екатеринбург'}/>
                <DataItem label={'Сайт'} text={'companyA.ru'}/>
                <DataItem label={'Дата регистрации компании'} text={'27 сентября 2006'}/>
            </div>
            <h3 className={styles.company__subtitle}>Описание</h3>
            <p className={styles.company__text}>
                Наш интернет-магазин шин и дисков имеет многолетний опыт работы с клиентами. Мы заботимся о своей
                репутации, постоянно совершенствуем свой сервис и расширяем модельный ряд. Кроме того, наш магазин
                является официальным поставщиком ведущих производителей шин в Екатеринбурге, что позволяет нам
                поддерживать действительно низкие цены.
            </p>
            <h3 className={styles.company__subtitle}>Банковские данные</h3>
            <div className={styles.company__info}>
                <DataItem label={'Юридическое название'} text={'ООО Компания A'}/>
                <DataItem label={'БИК'} text={'046577774'}/>
                <DataItem label={'Р/С'} text={'40702810300130012901'}/>
                <DataItem label={'Банк'} text={'ООО Банк «НЕЙВА»'}/>
                <DataItem label={'К/С'} text={'30101810400000000774'}/>
            </div>
            <IconButton
                text={'Реквизиты_2021.docx'}
                icon={
                    <File width={24} height={24} color={colors.blue} style={{marginRight: '10px'}}/>
                }
                viewType={IconButtonType.outline}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '16px'
                }}/>
            <h3 className={styles.company__subtitle}>ЭДО</h3>
            <div className={styles.company__info}>
                <DataItem label={'Оператор'} text={'30101810400000000774'}/>
                <DataItem label={'Оператор'} text={'30101810400000000774'}/>
            </div>
        </>
    );
};
