import React from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useNavigate} from 'react-router-dom';
import styles from '../Registration.module.scss';
import {Button, ConfirmPassword, Input, InputPassword} from '../../../fields';
import {ButtonType} from '../../../fields/types';
import {RulesLinks} from '../../../components/RulesLinks';
import {useAppDispatch} from '../../../hooks';
import {registration} from '../../../store/app.slice';

const isValid = /^[\w]{1}[\w-\.]*@[\w-]+\.[a-z]{2,4}|[\d\+][\d\(\)\ -]{4,14}\d$/i;

const schema = yup.object({
    login: yup.string().matches(isValid, 'Телефон или e-mail').required('Это поле обязательно к заполнению'),
    password: yup.string().min(6, 'Пароль не может быть меньше 6 символов').required('Придумайте пароль'),
    confirm_password: yup.string().oneOf([yup.ref('password')], 'Пароли не совпадают')
});

export const RegistrationForm = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const {control, register, setValue, formState: {errors}, handleSubmit} = useForm<any>({
        resolver: yupResolver(schema),
        mode: 'onBlur'
    });


    const generatePassword = () => {
        const generatePws = Math.random().toString(36).slice(-8);
        setValue('password', generatePws);
        setValue('confirm_password', generatePws);
    };

    const handleJump = () => {
        navigate('/login');
    };

    const submit = handleSubmit(values => {
        dispatch(registration({...values, navigate}));
    });

    return (
        <form className={styles.form} onSubmit={submit}>
            <h1 className={styles.form__title}>Регистрация</h1>
            <div className={styles.form__box}>
                <Input
                    label={'Логин'}
                    placeholder={''}
                    register={register}
                    control={control}
                    fieldName={'login'}
                    required={true}
                    errors={errors}/>
            </div>
            <div className={styles.form__box}>
                <button
                    className={styles.generatePassword}
                    onClick={generatePassword}
                >Сгенерировать пароль
                </button>
            </div>
            <div className={styles.form__box}>
                <InputPassword
                    label={'Пароль'}
                    placeholder={''}
                    register={register}
                    control={control}
                    fieldName={'password'}
                    required={true}
                    errors={errors}
                />
            </div>
            <div className={styles.form__box}>
                <ConfirmPassword
                    label={'Повторите пароль'}
                    placeholder={''}
                    register={register}
                    control={control}
                    fieldName={'confirm_password'}
                    errors={errors}
                    required={true}
                />
            </div>
            <div className={styles.form__box}>
                <Button
                    text={'Зарегистрироваться'}
                    viewType={ButtonType.filling}
                    style={{
                        width: '100%'
                    }}
                    type={'submit'}
                />
            </div>
            <div className={styles.form__box}>
                <RulesLinks/>
            </div>
            <div className={styles.form__box}>
                <Button
                    text={'Уже есть аккаунт'}
                    viewType={ButtonType.outline}
                    style={{
                        width: '100%'
                    }}
                    type={'button'}
                    onClick={handleJump}
                />
            </div>
        </form>
    );
};
