import React from 'react';
import styles from './styles.module.scss';
import {DataItem} from './DataItem';

export const CompanyInfo = () => {
    return (
        <>
            <h3 className={styles.company__subtitle}>Информация о производстве</h3>
            <div className={styles.company__info}>
                <DataItem label={'Вид деятельности'} text={'Интернет-магазин, магазин'}/>
                <DataItem label={'Может уточнить дату выпуска шин'} text={'Да'}/>
                <DataItem label={'Тип оплаты'} text={'Безналичная, перевод на карту'}/>
                <DataItem label={'Готов быть точкой выдачи'} text={'Да'}/>
                <DataItem label={'Виды доставки'} text={'ПЭК, КИТ'}/>
            </div>
            <h3 className={styles.company__subtitle}>Поставщик</h3>
            <div className={styles.company__info}>
                <DataItem label={'Товары'} text={'Да'}/>
                <DataItem label={'Оптовый склад'} text={'Да'}/>
                <DataItem label={'Оператор'} text={'Диски, шины, грузовые шины'}/>
            </div>
            <h3 className={styles.company__subtitle}>Дополнительная информация</h3>
            <div className={styles.company__info}>
                <DataItem label={'Кол-во позиций'} text={'4780'}/>
                <DataItem label={'Тариф'} text={''}/>
                <DataItem label={'Количество запросов номера'} text={'279'}/>
                <DataItem label={'Заявки на почту'} text={''}/>
            </div>
        </>
    );
};
