import React from 'react';
import classNames from 'classnames';
import {Button} from '../../../fields';
import {ButtonType} from '../../../fields/types';
import {Pagination} from '../../../components/Pagination';
import {useAppSelector, useResizeWindow} from '../../../hooks';
import {TableRow} from './TableRow';
import {table} from './config';
import styles from './componentsStyle.module.scss';

export const Table = () => {
    const width = useResizeWindow();

    const {meta} = useAppSelector(state => state.suppliers);

    const handlePagination = () => {
    };
    return (
        <div className={styles.table}>
            <div className={styles.table__body}>
                <div className={styles.table__header}>
                    {table.heads.map(i =>
                        <span key={i}
                              className={classNames(styles.table__head, styles.tableCell)}>
                            {i}</span>)}
                </div>
                {table.rows.map((i, index) =>
                    <TableRow
                        site={i.site}
                        distance={i.distance}
                        city={i.city}
                        image={i.image}
                        company={i.company}
                        key={index}/>)}
            </div>
            <div className={styles.table__footer}>
                <Button style={{width: '100%'}} text={'Показать еще'} viewType={ButtonType.light}/>
                <Pagination
                    showLessItems={width < 768}
                    count={meta.total}
                    handle={handlePagination}
                    pageSize={meta.per_page}
                    loading={false}
                    current={1}/>
            </div>
        </div>
    );
};
