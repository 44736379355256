import React, {useEffect} from 'react';
import {SingleLayout} from '../../layouts';
import {useAppDispatch, useAppSelector} from '../../hooks';
import styles from './Disks.module.scss';
import {FilterDisks} from './components/filter';
import {TableProduct} from './components/table';
import {sendFilterDisks} from './thunks';

export const Disks = () => {
    const {meta} = useAppSelector(state => state.disks);

    return (
        <SingleLayout>
            <div className={styles.disks}>
                <FilterDisks/>
                <TableProduct/>
            </div>
        </SingleLayout>
    );
};
