import React from 'react';
import classNames from 'classnames';
import {ButtonProps, ButtonType} from './types';
import styles from './fields.module.scss';

export const Button = (
    {
        text,
        viewType,
        className,
        ...props
    }: ButtonProps
) => {
    return (
        <button {...props} className={classNames(styles[ButtonType[viewType]], className)}>
            {text}
        </button>
    );
};
