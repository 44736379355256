import React, {useRef, useState} from 'react';
import classNames from 'classnames';
import {colors} from '../utils/constants';
import {ArrowDown, ArrowUp} from '../icons';
import {useListenClickDocument} from '../hooks';
import {IOption, SelectNativeProps, SelectProps} from './types';
import styles from './fields.module.scss';

export const SelectSort = (
    {
        placeholder,
        label,
        defaultValue,
        options,
        handleSelect,
        onMouseDown,
        onMouseOut,
    }: SelectNativeProps
) => {
    const [open, setOpen] = useState(false);
    const [selectItem, setSelectItem] = useState<string>('');
    const selectRef = useRef<HTMLDivElement>(null);
    useListenClickDocument({
        ref: selectRef,
        cb: (state) => {
            setOpen(state);
        }
    });
    const renderIcon = (flag: boolean) => {
        return flag
            ? <ArrowUp height={24} width={24} color={colors.blue}/>
            : <ArrowDown height={24} width={24} color={colors.grey200}/>;
    };


    const selected = ({label, value}: IOption) => {
        setSelectItem(label);
        handleSelect({label, value});
        setOpen(false);
    };

    return (
        <div className={styles.select} ref={selectRef}>
            <label className={
                open
                    ? classNames(styles.select__label, styles.colorActive)
                    : styles.select__labels
            }>{label}</label>
            <div
                className={styles.select__header}
                style={{
                    borderColor: open
                        ? colors.blue
                        : colors.grey200
                }}
                onClick={() => setOpen(!open)}
                onMouseOut={onMouseOut}
                onMouseDown={onMouseDown}>
                <input
                    placeholder={placeholder}
                    className={styles.select__output}
                    value={defaultValue}
                    readOnly
                />
                {renderIcon(open)}
            </div>

            {open
                ? <ul className={styles.select__list} style={{top: label ? '22px' : '0px', minHeight: '224px'}}>
                    {options.map((i, index) =>
                        <li
                            key={index}
                            className={
                                selectItem === i.value
                                    ? classNames(styles.select__item, styles.select__itemActive)
                                    : styles.select__item
                            }
                            onClick={() => selected(i)}
                        >{i.label}</li>
                    )}
                </ul>
                : null
            }
        </div>
    );
};
