import {createSlice} from '@reduxjs/toolkit';
import {defaultLinks, defaultMeta} from '../../utils/commonTypes';
import {setStepsToTypes} from '../../helpers/setStepsToTypes';
import {ITiresState} from './types';
import {getPage, getTiresRests, sendFilter, showMoreTires} from './thunks';


const initialState: ITiresState = {
    loading: false,
    getMoreLoading: false,
    restLoading: false,
    tires: [],
    rests: [],
    metaRests: defaultMeta,
    linksRests: defaultLinks,
    meta: defaultMeta,
    links: defaultLinks,
    filterPayload: null,
    brands: [],
};

const TiresSlice = createSlice({
    name: 'tires',
    initialState,
    reducers: {
        clearTires: (state) => {
            state.tires = [];
            state.meta = defaultMeta;
            state.links = defaultLinks;
        },
        setFilterPayload: (state, {payload}) => {
            state.filterPayload = payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(sendFilter.pending, (state, {payload}) => {
            state.loading = true;
        });
        builder.addCase(sendFilter.fulfilled, (state: ITiresState, {payload}) => {
            state.tires = payload.data;
            state.meta = payload.meta;
            state.links = payload.links;
            state.loading = false;
        });
        builder.addCase(sendFilter.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getPage.pending, (state, {payload}) => {
            state.loading = true;
        });
        builder.addCase(getPage.fulfilled, (state: ITiresState, {payload}) => {
            state.tires = payload.data;
            state.meta = payload.meta;
            state.links = payload.links;
            state.loading = false;
        });
        builder.addCase(showMoreTires.pending, (state, {payload}) => {
            state.getMoreLoading = true;
        });
        builder.addCase(showMoreTires.fulfilled, (state: ITiresState, {payload}) => {
            state.tires = [...state.tires, ...payload.data];
            state.meta = payload.meta;
            state.links = payload.links;
            state.getMoreLoading = false;
        });
        builder.addCase(getTiresRests.pending, (state, {payload}) => {
            state.restLoading = true;
        });
        builder.addCase(getTiresRests.fulfilled, (state: ITiresState, {payload}) => {
            state.rests = payload.data;
            state.metaRests = payload.meta;
            state.linksRests = payload.links;
            state.restLoading = false;
        });

    }
});

export const {clearTires, setFilterPayload} = TiresSlice.actions;

export default TiresSlice.reducer;
