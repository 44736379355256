import React from 'react';
import classNames from 'classnames';
import styles from './tableStyles.module.scss';

export const TableHeader = ({inner}:{inner: boolean}) => {
    return (
        <div className={styles.header} style={{padding: inner ? '16px' : '16px 0'}}>
            <div className={classNames(styles.box, styles.box__name)}>
                <span className={styles.head}>Название</span>
            </div>
            <div className={classNames(styles.box, styles.box__empty)}/>
            <div className={classNames(styles.box, styles.box__remainder)}>
                <span className={styles.head}>Остаток</span>
            </div>
            <div className={classNames(styles.box, styles.box__wholesale)}>
                <span className={styles.head}>Опт, ₽</span>
            </div>
            <div className={classNames(styles.box, styles.box__retail)}>
                <span className={styles.head}>Розница, ₽</span>
            </div>
        </div>
    );
};
