import React  from 'react';
import ReactCodeInput from 'react-verification-code-input';
import {Timer} from '../Timer';
import {ICodeInputProps} from './types';
import styles from './CodeInput.module.scss';

export const CodeInput = ({sendCode, handleReSendCode, show, time, error}:ICodeInputProps) => {

    return (
        <div className={styles.codeInput}>
            {show ? (
                <>
                    <ReactCodeInput
                        fieldWidth={30}
                        fieldHeight={30}
                        className={error ? styles.codeInput__codeError : styles.codeInput__code}
                        onComplete={sendCode}
                    />
                    <Timer
                        expiryTimestamp={time}
                        handleReSendCode={handleReSendCode} />
                </>
            ) : null}

        </div>
    );
};

