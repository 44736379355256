import {IOption} from '../fields/types';

export const colors = {
    white: '#ffffff',
    black: '#000000',
    red: '#FF295B',
    grey100: '#8A89A3',
    grey300: '#E9E9F2',
    grey200: '#BCBCCC',
    grey400: '#F7F7FA',
    blueLight: '#6952FF',
    blue: '#472CF5',
    blueDark: '#3A20E6',
    green: '#09D66F',
    orange: '#FF9124',
    yellow: '#FFD91A',
    winter: '#528BFF'
};

export const ids = {
    layoutRightBox: 'right-box'
};

export const isValidPhoneAndEmail = /^[\w]{1}[\w-\.]*@[\w-]+\.[a-z]{2,4}|[\d\+][\d\(\)\ -]{4,14}\d$/i;
export const isValidEmail = /^[\w]{1}[\w-\.]*@[\w-]+\.[a-z]{2,4}$/i;

export const sortOptions: IOption[] = [
    {label: 'По цене больше', value: 'desc'},
    {label: 'По цене меньше', value: 'asc'},
    {label: 'По наличию больше', value: 'desc'},
    {label: 'По наличию меньше', value: 'asc'},
    {label: 'По наименованию А-Я', value: 'asc'},
    {label: 'По наименованию Я-А', value: 'desc'},
];

export const sortKeys: { [key: string]: string } = {
    'По цене больше': 'bulk_price',
    'По цене меньше': 'bulk_price',
    'По наличию больше': 'quantity',
    'По наличию меньше': 'quantity',
    'По наименованию А-Я': 'title',
    'По наименованию Я-А': 'title',
};

export const sortValues: { [key: string]: string } = {
    bulk_price_desc: 'По цене больше',
    bulk_price_asc: 'По цене меньше',
    quantity_desc: 'По наличию больше',
    quantity_asc: 'По наличию меньше',
    title_desc: 'По наименованию А-Я',
    title_asc: 'По наименованию Я-А'
};

export const defaultOptionItem:IOption = {label: 'Любой', value: ''};
