import React from 'react';
import classNames from 'classnames';
import {Close} from '../../icons';
import {colors} from '../../utils/constants';
import styles from './Alert.module.scss';

export const Alert = ({style, options, message, close}: any) => {
    return (
        <div className={
            options.type === 'success'
                ? classNames(styles.wrapper, styles.success) : classNames(styles.wrapper, styles.error)} style={style}>
            <div className={styles.alert}>
                <span className={styles.alert__text}>{message}</span>
                <Close width={24} height={24} color={colors.white} onClick={close}/>
            </div>
        </div>
    );
};

