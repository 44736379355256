import React, {ChangeEvent, KeyboardEvent, useEffect, useState} from 'react';
import classNames from 'classnames';
import PhoneInput from 'react-phone-input-2';
import {colors} from '../../utils/constants';
import {CodeInput} from '../CodeInput';
import {InputPhone} from '../../fields';
import {IPhoneProps} from './types';
import styles from './Phone.module.scss';

const isValid = /[\d\+][\d\(\)\ -]{4,14}\d$/i;

export const Phone = ({defaultValue, handleSendPhone, handleSendCode, isVerify}: IPhoneProps) => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + 3600); // 1 hour timer

    const [phone, setPhone] = useState<string>('');
    const [isFocus, setFocus] = useState<boolean>(false);
    const [isCode, setCode] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        defaultValue && setPhone(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
        if (isVerify !== null) {
            setCode(false);
        }
    }, [isVerify]);

    const confirmPhone = () => {
        if (phone.match(isValid)) {
            setCode(!isCode);
            setError(false);
            !isCode && handleSendPhone(phone);
        } else {
            setError(true);
        }
    };

    const sendCode = (code: string) => {
        if (code.length === 6) {
            handleSendCode(code);
            setCode(false);
        }
    };

    const handleReSendCode = () => {
        handleSendPhone(phone);
    };
    const regPhone = /[^0-9 | +]/g;
    return (
        <div className={styles.phone}>
            <label
                className={
                    isFocus
                        ? error
                            ? classNames(styles.phone__label, styles.colorError)
                            : classNames(styles.phone__label, styles.colorActive)
                        : error
                            ? classNames(styles.phone__label, styles.colorError)
                            : styles.phone__label
                }
            >Телефон</label>
            <PhoneInput
                country={'ru'}
                prefix={'+'}
                specialLabel={''}
                placeholder={'+7(ХХХ) ХХХ-ХХ-ХХ'}
                inputClass={styles.phone__input}
                inputStyle={{
                    borderColor: isFocus
                        ? error ? colors.red : colors.blue
                        : error ? colors.red : colors.grey200
                }}
                value={phone}
                onChange={phone => setPhone(phone) }
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
            />
            {/*<input*/}
            {/*    className={styles.phone__input}*/}
            {/*    style={{*/}
            {/*        borderColor: isFocus*/}
            {/*            ? error ? colors.red : colors.blue*/}
            {/*            : error ? colors.red : colors.grey200*/}
            {/*    }}*/}
            {/*    readOnly={isCode}*/}
            {/*    value={phone}*/}
            {/*    onChange={(event: ChangeEvent<HTMLInputElement>) => {*/}
            {/*        setPhone(event.target.value);*/}
            {/*        setError(false);*/}
            {/*    }}*/}

            {/*    maxLength={12}*/}
            {/*/>*/}
            <p className={styles.phone__changeText}>
                {isVerify === null ? 'Телефон не подтвержден.' : 'Смена телефона.'}
                <button
                    className={styles.phone__button}
                    onClick={confirmPhone}
                >{isCode ? 'Отмена' : defaultValue === phone && defaultValue !== '' ? 'Подтверждено' : 'Подтвердить'}</button>
            </p>

            <CodeInput show={isCode} error={false} handleReSendCode={handleReSendCode} sendCode={sendCode} time={time}/>

            {error ? <p className={styles.errorText}>{'Правильный формат +7ХХХХХХХХХХ'}</p> : null}
        </div>
    );
};

