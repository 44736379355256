import {IToken} from '../store/types';

export interface IRequestProps<T> {
    token: IToken | null,
    body: T
}

export enum cond {
    gte = 'gte', // - Значение больше или равно
    gt = 'gt', // - Значение строго больше
    eq = 'eq', // - Значение равно
    lt = 'lt', // - Значение строго меньше
    lte = 'lte', // - Значение меньше или равно
    in = 'in', // - Значение одно из
    btw = 'btw', // - Значение в интервале от, до
}

export type singleObj = { [key: string]: string }
