import React from 'react';
import {useFormContext} from 'react-hook-form';
import {IDataTireProps} from '../../types';
import {UiSelect} from '../../../../components/UiSelect';
import {Input} from '../../../../fields';
import {defaultOptionItem} from '../../../../utils/constants';
import styles from './style.module.scss';

export const TypesData = ({season = [], brand, handleSearchFromApi, handleHasMore}: IDataTireProps) => {
    const {control, register,formState: {errors}} = useFormContext();

    return (
        <>
            <div className={styles.boxData}>
                <UiSelect
                    label={'Сезон'}
                    options={[...season, defaultOptionItem]}
                    control={control}
                    fieldName={'season'}
                    useFilter={false}
                    heightList={131}
                    register={register}
                    errors={errors}
                    isInfinity={false}
                />
            </div>
            <div className={styles.boxData}>
                <UiSelect
                    label={'Производитель'}
                    options={brand}
                    control={control}
                    fieldName={'brand'}
                    register={register}
                    errors={errors}
                    handleHasMore={handleHasMore}
                    handleFetchApi={handleSearchFromApi}
                    isInfinity={true}
                />
            </div>
            <div className={styles.boxData}>
                <Input
                    label={'Артикул или название'}
                    placeholder={''}
                    register={register}
                    control={control}
                    fieldName={'original_sku'}
                    required={false}
                    errors={errors}/>
            </div>
        </>
    );
};
