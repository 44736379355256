import defaultImage from '../../../assets/images/company-logo-default.png';
export interface IRow {
    image: string, company: string, city: string, distance: string, site: string
}
export const table = {
    heads: ['','Название', 'Город', 'Удаленность до, км', 'Сайт', ''],
    rows: [
        {image: defaultImage, company: 'Компания A', city: 'Екатеринбург', distance: '10', site: 'companyA.ru'},
        {image: defaultImage, company: 'Компания b', city: 'Екатеринбург', distance: '20', site: 'companyA.ru'},
        {image: defaultImage, company: 'Компания c', city: 'Екатеринбург', distance: '50', site: 'companyA.ru'},
        {image: defaultImage, company: 'Компания d', city: 'Екатеринбург', distance: '12', site: 'companyA.ru'},
        {image: defaultImage, company: 'Компания i', city: 'Екатеринбург', distance: '17', site: 'companyA.ru'},
        {image: defaultImage, company: 'Компания f', city: 'Екатеринбург', distance: '34', site: 'companyA.ru'},
        {image: defaultImage, company: 'Компания g', city: 'Екатеринбург', distance: '56', site: 'companyA.ru'},
    ]
};
