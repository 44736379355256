import {isValidEmail} from '../utils/constants';
import {IProfile} from '../pages/Profile/types';

export const reSaveCredential = async ({type, data}:{type: 'email' |'phone', data:IProfile}) => {
    const credential = localStorage.getItem('credential');
    if(credential) {
        const values = JSON.parse(credential);
        if(type === 'email' && isValidEmail.test(values.login)) {
            localStorage.setItem('credential', JSON.stringify({...values, login : data.email}));
        }
        if (type === 'phone' && !isValidEmail.test(values.login)) {
            localStorage.setItem('credential', JSON.stringify({...values, login : data.phone}));
        }
    }
};
