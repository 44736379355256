import React from 'react';
import {useGetUserProfileQuery} from '../../pages/Profile/Profile.api';
import {useAppSelector} from '../../hooks';
import {avatarSizes, IAvatarProps} from './types';
import {AvatarLarge, AvatarMedium, AvatarSmall} from './components';

export const Avatar = ({size, full_name}:IAvatarProps) => {
    const {token} = useAppSelector(state => state.app);
    const {data} = useGetUserProfileQuery({token: token});

    if(size === avatarSizes.sm) {
        return <AvatarSmall  image={data?.avatar} />;
    }

    if(size === avatarSizes.m) {
        return <AvatarMedium full_name={full_name} image={data?.avatar} />;
    }

    return (
        <AvatarLarge full_name={full_name} image={data?.avatar} />
    );
};

