import React, {useState} from 'react';
import {Controller} from 'react-hook-form';
import {ErrorMessage} from '@hookform/error-message';
import classNames from 'classnames';
import {colors} from '../utils/constants';
import {EyeComponent} from '../components/EyeComponent';
import {InputProps, message} from './types';
import styles from './fields.module.scss';


export const InputPassword = (
    {
        register,
        control,
        errors,
        label,
        fieldName,
        ...props
    }: InputProps
) => {
    const [isFocus, setFocus] = useState<boolean>(false);
    const [isType, setType] = useState<boolean>(false);

    return (
        <Controller
            control={control}
            name={fieldName}
            render={({field: {name}, fieldState: {error}}) => (
                <div
                    className={styles.inputField}
                >
                    <label
                        className={
                            isFocus
                                ? error
                                    ? classNames(styles.inputField__label, styles.colorError)
                                    : classNames(styles.inputField__label, styles.colorActive)
                                : error
                                    ? classNames(styles.inputField__label, styles.colorError)
                                    : styles.inputField__label
                        }
                    >{label}</label>
                    <div className={styles.inputField__inner}>
                        <input
                            {...props}
                            {...register(fieldName)}
                            className={styles.inputField__input}
                            type={isType ? 'text' : 'password'}
                            style={{
                                borderColor: isFocus
                                    ? error ? colors.red : colors.blue
                                    : error ? colors.red : colors.grey200
                            }}
                            onFocus={() => setFocus(true)}
                            onBlur={() => setFocus(false)}
                        />
                        <EyeComponent initState={false} onChange={(state) => setType(state)} />
                    </div>
                    <ErrorMessage
                        errors={errors}
                        name={name}
                        render={({message}: { message: message }) => <p className={styles.errorText}>{message}</p>}
                    />
                </div>
            )}
        />
    );
};
