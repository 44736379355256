import React from 'react';
import {IconProps} from './types';

export const Home = ({width, height, color, ...props}:IconProps) => {
    return (
        <svg {...props} width={width} height={height} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.7998 15V8C1.7998 7.37049 2.09619 6.77771 2.5998 6.4L8.5998 1.9C9.31092 1.36667 10.2887 1.36667 10.9998 1.9L16.9998 6.4C17.5034 6.77771 17.7998 7.37049 17.7998 8V15C17.7998 16.1046 16.9044 17 15.7998 17H3.7998C2.69524 17 1.7998 16.1046 1.7998 15Z"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.7998 17V11C6.7998 10.4477 7.24752 10 7.7998 10H11.7998C12.3521 10 12.7998 10.4477 12.7998 11V17"
                  stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};
