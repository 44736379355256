import React from 'react';
import classNames from 'classnames';
import {Close} from '../../../../icons';
import {colors} from '../../../../utils/constants';
import {ITableItemProps} from '../../types';
import styles from './tableStyles.module.scss';
import {InnerTableTires} from './InnerTableTires';
import {Product} from './Product';
import {TableHeader} from './TableHeader';

export const TableItem = (
    {
        handleClose,
        isVisible,
        id,
        title, quantity, bulk_price, retail_price, model, original_sku, season,
        brand, is_spikes
    }: ITableItemProps) => {

    return (
        <div className={isVisible ? classNames(styles.item, styles.item__visible) : styles.item}>
            <div className={styles.item__close}>
                <Close
                    width={40}
                    height={40}
                    color={colors.grey200}
                    onClick={handleClose}/>
            </div>
            <div className={styles.table}>
                <div className={styles.table__body}>
                    <TableHeader inner={true}/>
                    <div className={styles.productPrev}>

                        <Product
                            title={title}
                            id={id}
                            brand={brand}
                            retail_price={retail_price}
                            bulk_price={bulk_price}
                            quantity={quantity}
                            season={season}
                            model={model}
                            original_sku={original_sku}
                            is_spikes={is_spikes}
                            showChevron={false}
                            isOpen={isVisible}
                        />
                    </div>
                    <InnerTableTires/>
                </div>
            </div>
        </div>
    );
};
