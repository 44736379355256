import {ISidebarConfig} from './types';

export const sidebarConfig:ISidebarConfig[] = [
    {path: '/profile', label: 'Мой профиль'},

    // {path: '/history', label: 'История заявок'},
    // {path: '/favorite-suppliers', label: 'Избранные поставщики'},
    // {path: '/companies', label: 'Компании'},
    {path: 'exit', label: 'Выйти'},
];
