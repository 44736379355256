//?token=3d144af3fea76f04b1b9d77acd16041ce9b99b0f63c38691a4ad441d21c82181&email=kontakt.vt@mail.ru

export const getParams = (str: string) => {
    let params: any = [];
    const arr = str.replace(/[?]/, '').split('&');
    for (let i = 0; i < arr.length; i++) {
        const data = arr[i].split('=');
        params = [...params, {[data[0]]: data[1]}];
    }

    return params;
};
