import {NavigateFunction} from 'react-router-dom';
import {IError, ISuccess} from '../helpers/types';
import {ILinksResponse, IMetaResponse} from '../utils/commonTypes';
import {IOption} from '../fields/types';

export interface IStateApp {
    loading: boolean;
    errorReq: IError | null;
    successReq: ISuccess | null;
    resetSuccess: boolean;
    token: IToken | null;
    expires_at: string | null;
    brands: IOption[];
    carBrands: IOption[];
    brandsTotal: number,
    carBrandsTotal: number,
    order_by: {
        key: string | null;
        direction: string | null;
    }
}

export interface IToken {
    token_type: string;
    token: string;
    expires_at: string;
}

export interface ITokenRes {
    token: IToken;
    expires_at: string;
}

export interface IResponseSearch<T> {
    data: T,
    meta: IMetaResponse;
    links: ILinksResponse;
}

export interface IRegistrationProps {
    login: string;
    password: string;
    navigate: NavigateFunction;
}

export interface ILoginProps {
    login: string;
    password: string;
    remember_me: boolean;
    navigate: NavigateFunction;
}

export interface IUpdatePasswordProps {
    login: string;
    password: string;
    token: string;
    navigate: NavigateFunction;
}

export const alertTypes = {
    INFO: 'info',
    SUCCESS: 'success',
    ERROR: 'error'
};

export interface IAlert {
    type: string;
    text: string;
}
