import React from 'react';
import classNames from 'classnames';
import {Close} from '../../../../icons';
import {colors} from '../../../../utils/constants';
import {IDisk} from '../../types';
import styles from './tableStyles.module.scss';
import {InnerTableProduct} from './InnerTableProduct';
import {Product} from './Product';
import {TableHeader} from './TableHeader';

interface ITableItemProps extends IDisk {
    viewType: 'grid' | 'list';
    handleClose: () => void;
    isVisible: boolean;
}

export const TableItem = (
    {
        handleClose,
        isVisible,
        brand,
        bulk_price,
        countries,
        id,
        model,
        original_sku,
        quantity,
        retail_price,
        title,
        type, color, viewType, image
    }: ITableItemProps) => {

    return (
        <div className={isVisible ? classNames(styles.item, styles.item__visible) : styles.item}>
            <div className={styles.item__close}>
                <Close
                    width={40}
                    height={40}
                    color={colors.grey200}
                    onClick={handleClose}/>
            </div>
            <div className={styles.table}>
                <div className={viewType === 'list' ? styles.table__body : classNames(styles.table__body, styles.table__bodyCard)}>
                    <TableHeader inner={true} />
                    <div className={styles.productPrev}>
                        <Product
                            brand={brand}
                            bulk_price={bulk_price}
                            countries={countries}
                            id={id}
                            image={image}
                            model={model}
                            original_sku={original_sku}
                            quantity={quantity}
                            retail_price={retail_price}
                            title={title}
                            type={type}
                            color={color}
                            isOpen={false}
                            showChevron={false}/>
                    </div>

                    <InnerTableProduct/>
                </div>
            </div>
        </div>
    );
};
