import React from 'react';
import {IconProps} from './types';

export const Question = ({width, height, color, ...props}:IconProps) => {
    return (
        <svg {...props} width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.49951 10.6099H8.12988C8.13346 10.2876 8.15853 10.0101 8.20508 9.77734C8.25521 9.54102 8.33936 9.32796 8.45752 9.13818C8.57926 8.9484 8.7404 8.76042 8.94092 8.57422C9.10921 8.42383 9.25602 8.2806 9.38135 8.14453C9.50667 8.00846 9.60514 7.86882 9.67676 7.72559C9.74837 7.57878 9.78418 7.41585 9.78418 7.23682C9.78418 7.02913 9.75195 6.85726 9.6875 6.72119C9.62663 6.58154 9.53353 6.47591 9.4082 6.4043C9.28646 6.33268 9.13249 6.29688 8.94629 6.29688C8.79232 6.29688 8.64909 6.33089 8.5166 6.39893C8.38411 6.46338 8.2749 6.56364 8.18896 6.69971C8.10661 6.83577 8.06364 7.01481 8.06006 7.23682H6.50244C6.51318 6.74626 6.62598 6.34163 6.84082 6.02295C7.05924 5.70068 7.35107 5.46257 7.71631 5.30859C8.08154 5.15104 8.49154 5.07227 8.94629 5.07227C9.44759 5.07227 9.87728 5.15462 10.2354 5.31934C10.5934 5.48047 10.8674 5.71859 11.0571 6.03369C11.2469 6.34521 11.3418 6.72477 11.3418 7.17236C11.3418 7.48389 11.2809 7.76139 11.1592 8.00488C11.0374 8.24479 10.8781 8.46859 10.6812 8.67627C10.4842 8.88395 10.2676 9.0988 10.0312 9.3208C9.82715 9.50342 9.6875 9.69499 9.6123 9.89551C9.54069 10.096 9.50309 10.3341 9.49951 10.6099ZM7.96875 12.2695C7.96875 12.0404 8.04753 11.8506 8.20508 11.7002C8.36263 11.5462 8.57389 11.4692 8.83887 11.4692C9.10026 11.4692 9.30973 11.5462 9.46729 11.7002C9.62842 11.8506 9.70898 12.0404 9.70898 12.2695C9.70898 12.4915 9.62842 12.6795 9.46729 12.8335C9.30973 12.9875 9.10026 13.0645 8.83887 13.0645C8.57389 13.0645 8.36263 12.9875 8.20508 12.8335C8.04753 12.6795 7.96875 12.4915 7.96875 12.2695Z"
                fill={color}/>
            <circle cx="9" cy="9" r="8.5" stroke={color}/>
        </svg>
    );
};
