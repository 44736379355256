import React, {useEffect} from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import {useAppSelector} from './app';

export const useExpiresToken = ():{not_expires: boolean} => {
    const currentDate = new Date();
    const {token} = useAppSelector(state => state.app);
    const navigate = useNavigate();

    useEffect(() => {
       const listen =  setInterval(() => {
            if(new Date(token?.expires_at) < currentDate) {
               // localStorage.removeItem('credential');
                localStorage.removeItem('token');
                return navigate('/login');
            }
        }, 1000);

       return () => clearInterval(listen);
    }, []);
    return {not_expires : new Date(token?.expires_at) > currentDate };
};
