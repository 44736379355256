import {createSlice} from '@reduxjs/toolkit';
import {defaultLinks, defaultMeta} from '../../utils/commonTypes';
import {IDisksState} from './types';
import {getDisksRests, getPageDisk, sendFilterDisks, showMoreDisks} from './thunks';

const initialState:IDisksState = {
    loading: false,
    getMoreLoading: false,
    restLoading: false,
    disks: [],
    rests: [],
    meta: defaultMeta,
    links: defaultLinks,
    metaRests: defaultMeta,
    linksRests: defaultLinks,
    filterPayload: null
};

const DisksSlice = createSlice({
    name: 'disks',
    initialState,
    reducers:{
        clearDisks: (state) => {
            state.disks = [];
            state.meta = defaultMeta;
            state.links = defaultLinks;
            state.filterPayload = [];
        },
        setFilterDisksPayload: (state, {payload}) => {
            state.filterPayload = payload;
        }
    },
     extraReducers: builder => {
        builder.addCase(sendFilterDisks.pending, (state, {payload}) => {
            state.loading = true;
        });
        builder.addCase(sendFilterDisks.fulfilled, (state, {payload}) => {
            state.disks = payload.data;
            state.meta = payload.meta;
            state.links = payload.links;
            state.loading = false;
        });
        builder.addCase(sendFilterDisks.rejected, (state) => {
            state.loading = false;
        });
         builder.addCase(getPageDisk.pending, (state, {payload}) => {
             state.loading = true;
         });
         builder.addCase(getPageDisk.fulfilled, (state: IDisksState, {payload}) => {
             state.disks = payload.data;
             state.meta = payload.meta;
             state.links = payload.links;
             state.loading = false;
         });
         builder.addCase(showMoreDisks.pending, (state, {payload}) => {
             state.getMoreLoading = true;
         });
         builder.addCase(showMoreDisks.fulfilled, (state: IDisksState, {payload}) => {
             state.disks = [...state.disks, ...payload.data];
             state.meta = payload.meta;
             state.links = payload.links;
             state.getMoreLoading = false;
         });
         builder.addCase(getDisksRests.pending, (state, {payload}) => {
             state.restLoading = true;
         });
         builder.addCase(getDisksRests.fulfilled, (state: IDisksState, {payload}) => {
             state.rests = payload.data;
             state.metaRests = payload.meta;
             state.linksRests = payload.links;
             state.restLoading = false;
         });
     }
});

export const {clearDisks, setFilterDisksPayload} = DisksSlice.actions;

export default DisksSlice.reducer;
