import React from 'react';
import classNames from 'classnames';
import {Hearts} from 'react-loader-spinner';
import {ArrowDown, ArrowLeft, ArrowRight, ArrowUp} from '../../../../icons';
import {colors} from '../../../../utils/constants';
import {IDisk} from '../../types';
import {numberFormat} from '../../../../helpers';
import styles from './tableStyles.module.scss';
const defaultImage = require('../../../../assets/images/disk.png');

interface IProductProps extends IDisk{
    showChevron: boolean;
    isOpen: boolean;
    handleShowDetails?: () => void;
}

export const Product = (
    {
        isOpen,
        showChevron,
        handleShowDetails,
        brand,
        bulk_price,
        countries,
        id,
        model,
        original_sku,
        quantity,
        retail_price,
        title,
        type, color, image
    }: IProductProps) => {
    return (
        <>
            {showChevron ? <div className={classNames(styles.box, styles.box__sm)}>
                {isOpen
                    ? <ArrowLeft
                        width={8}
                        height={16}
                        color={colors.grey200}
                        style={{cursor: 'pointer'}}
                        onClick={handleShowDetails}/>
                    : <ArrowRight
                        width={8}
                        height={16}
                        color={colors.grey200}
                        style={{cursor: 'pointer'}}
                        onClick={handleShowDetails}/>}
            </div> : null}
            <div className={classNames(styles.box, styles.box__name)}>
                <div className={styles.image}><img src={image !== null ? image : defaultImage} alt={'tire'}/></div>
                <p className={styles.text}>{title}</p>
            </div>
            <div className={classNames(styles.box, styles.box__empty)}>
                <p className={styles.text}>{color ? color : '-'}</p>
            </div>
            <div className={classNames(styles.box, styles.box__remainder)}>
                <p className={styles.text}>{numberFormat(quantity)}</p>
            </div>
            <div className={classNames(styles.box, styles.box__wholesale)}>
                <p className={styles.text}> {bulk_price === 0 ? '-' : `${numberFormat(bulk_price)} ₽`}</p>
            </div>
            <div className={classNames(styles.box, styles.box__retail)}>
                <p className={styles.text}>{retail_price === 0 ? '-' : `${numberFormat(retail_price)} ₽`}</p>
            </div>
        </>
    );
};
