import React, {ChangeEvent, KeyboardEvent, useState} from 'react';
import classNames from 'classnames';
import {colors} from '../utils/constants';
import styles from './fields.module.scss';
import {InputNativeProps} from './types';

export const InputNative = (
    {
        label, value, placeholder, handleChange, onKeyDown
    }: InputNativeProps
) => {
    const [isFocus, setFocus] = useState<boolean>(true);

    return (
        <div
            className={styles.inputField}
        >
            <label
                className={
                    isFocus ? classNames(styles.inputField__label, styles.colorActive)
                        : styles.inputField__label
                }
            >{label}</label>
            <input
                placeholder={placeholder}
                className={styles.inputField__input}
                value={value}
                style={{
                    borderColor: isFocus
                        ? colors.blue
                        : colors.grey200
                }}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event.target.value)}
                autoFocus={true}
                onKeyDown={onKeyDown}
            />
        </div>
    );
};
