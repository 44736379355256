import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {NavLink, useNavigate} from 'react-router-dom';
import styles from '../Login.module.scss';
import {Button, Checkbox, Input, InputPassword} from '../../../fields';
import {ButtonType} from '../../../fields/types';
import {useAppDispatch} from '../../../hooks';
import {login, setToken} from '../../../store/app.slice';
import {isValidPhoneAndEmail} from '../../../utils/constants';


const schema = yup.object({
    login: yup.string().matches(isValidPhoneAndEmail, 'Телефон или e-mail').required('Это поле обязательно к заполнению'),
    password: yup.string().required('Это поле обязательно к заполнению'),
});

export const LoginForm = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isSaved,  setSaved] = useState<boolean>();


    const {control, register, formState: {errors}, handleSubmit} = useForm<any>({
        resolver: yupResolver(schema),
        mode: 'onBlur'
    });

    const handleJump = () => {
        navigate('/registration');
    };
    const submit = handleSubmit(values => {
        const saved = {...values, remember_me: isSaved};
        dispatch(login({...saved, navigate}));
    });

    return (
        <form className={styles.form} onSubmit={submit}>
            <h1 className={styles.form__title}>Вход</h1>
            <div className={styles.form__box}>
                <Input
                    label={'Логин'}
                    placeholder={''}
                    register={register}
                    control={control}
                    fieldName={'login'}
                    required={true}
                    errors={errors}/>
            </div>
            <div className={styles.form__box}>
                <InputPassword
                    label={'Пароль'}
                    placeholder={''}
                    register={register}
                    control={control}
                    fieldName={'password'}
                    required={true}
                    errors={errors}
                />
            </div>
            <div className={styles.form__box}>
                <NavLink to={'/recovery-password'} className={styles.link}>Не помню пароль</NavLink>
            </div>
            <div className={styles.savedCheckbox}>
                <Checkbox
                    label={'Запомнить этот компьютер'}
                    changeState={() => setSaved(!isSaved)}
                    state={isSaved}
                />
            </div>
            <div className={styles.form__box}>
                <Button
                    text={'Войти'}
                    viewType={ButtonType.filling}
                    style={{
                        width: '100%'
                    }}
                    type={'submit'}
                    //onClick={submit}
                />
            </div>
            <div className={styles.form__box}>
                <Button
                    text={'Зарегистрироваться'}
                    viewType={ButtonType.outline}
                    style={{
                        width: '100%'
                    }}
                    type={'button'}
                    onClick={handleJump}
                />
            </div>
        </form>
    );
};
