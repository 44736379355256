import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {useFormContext} from 'react-hook-form';
import {Checkbox, Input, Select} from '../../../../fields';
import {RadioButton} from '../../../../fields/RadioButton';
import {IDisksAdditionalOptionProps} from '../../types';
import {UiSelect} from '../../../../components/UiSelect';
import {defaultOptionItem} from '../../../../utils/constants';
import styles from './filterStyle.module.scss';

export const AdditionalSections = (
    {
        colors,
        type = [],
        city,
        car_brand,
        quantity,
        setQuantity,
        handleSearchFromApi,
        handleHasMore
    }: IDisksAdditionalOptionProps) => {

    const {register, unregister, control, watch, setValue, formState: {errors}} = useFormContext();

    useEffect(() => {
       return () =>  {
            unregister([
                'bulk_price',
                'bulk_price_to',
                'quantity',
                'car_brand',
                'type',
                'city',
                'color'
            ]);
        };
    }, []);
    return (
        <>
            <div className={styles.form__row}>
                <div className={styles.form__colPrice}>
                    <div className={classNames(styles.form__box)}>
                        <Input
                            label={'Цена, от'}
                            placeholder={''}
                            register={register}
                            control={control}
                            fieldName={'bulk_price'}
                            required={false}
                            inputType={'number'}
                            errors={errors}
                        />
                    </div>
                    <div className={classNames(styles.form__box)}>
                        <Input
                            label={'Цена, до'}
                            placeholder={''}
                            register={register}
                            control={control}
                            fieldName={'bulk_price_to'}
                            required={false}
                            inputType={'number'}
                            errors={errors}
                        />
                    </div>
                    <div className={classNames(styles.form__box)}>
                        <Input
                            label={'Количество от'}
                            placeholder={''}
                            register={register}
                            control={control}
                            fieldName={'quantity'}
                            required={false}
                            inputType={'number'}
                            errors={errors}
                        />
                    </div>
                </div>
                <div className={classNames(styles.form__colNewS)}>
                    <div className={classNames(styles.form__box)}>
                        <UiSelect
                            label={'Марка авто'}
                            options={car_brand}
                            control={control}
                            fieldName={'car_brand'}
                            register={register}
                            errors={errors}
                            isInfinity={false}
                        />
                    </div>
                    <div className={classNames(styles.form__box)}>
                        <UiSelect
                            label={'Тип диска'}
                            options={[...type, defaultOptionItem]}
                            control={control}
                            fieldName={'type'}
                            useFilter={false}
                            register={register}
                            heightList={166}
                            errors={errors}
                            isInfinity={false}
                        />
                    </div>
                </div>
                <div className={styles.form__colCheckboxes}>
                    <div className={classNames(styles.form__box)}>
                        <UiSelect
                            label={'Город'}
                            options={city}
                            control={control}
                            fieldName={'city'}
                            register={register}
                            errors={errors}
                            isInfinity={true}
                            handleFetchApi={handleSearchFromApi}
                            handleHasMore={handleHasMore}
                        />
                    </div>
                    <div className={classNames(styles.form__box)}>
                        <Checkbox
                            className={styles.form__checkbox}
                            label={'В наличии'}
                            state={quantity}
                            changeState={setQuantity}/>
                    </div>
                </div>
            </div>
            <div className={styles.form__row}>
                <div className={styles.form__colBottom}>
                    {colors?.map((item, index) => (
                        <div key={item.value} className={styles.colors}>
                            <RadioButton
                                initStatus={watch('color') === item.value}
                                changeStatus={(checked) => {
                                    checked
                                        ? setValue('color', item.value)
                                        : setValue('color', '');
                                }}
                                label={item.label}/>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};
