import {useEffect, useState} from 'react';

interface IScrollTopProps {
    offsetTop: number;
}

export const useScrollTop = () => {
    const [showScrollTop, setShowScrollTop] = useState<boolean>(false);

    useEffect(() => {
        window.addEventListener('scroll', function () {
            window.pageYOffset > 400
                ? setShowScrollTop(true)
                : setShowScrollTop(false);
        },{capture: true});
    }, []);

    function scrollTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    return {scrollTop, showScrollTop};
};
