import React, {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useAppSelector} from '../../hooks';
import {devBaseUrl, prodBaseUrl} from '../../api';
import {useGetUserProfileQuery, useUserContactsVerifyMutation} from '../Profile/Profile.api';

export const Redirect = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {token} = useAppSelector(state => state.app);
    const [userContactsVerify] = useUserContactsVerifyMutation();
    const {data} = useGetUserProfileQuery({token});

    useEffect(() => {
        const currentBaseUrl = process.env.NODE_ENV === 'development' ? devBaseUrl : prodBaseUrl;
        const params = getParams(location.search);
        const url = params['request'].replace(currentBaseUrl, '');
        if(token) {
            userContactsVerify({url, token, type: 'email'});
        }
    }, []);

    useEffect(() => {
        if(data?.email_verified_at) {
            navigate('/profile');
        }
    }, [data]);


    function getParams (url:string) {
        const params:{[key:string]: string} = {};
        const searchParams: string[] = decodeURIComponent(url).replace('?', '').trim().split('&');
        for (let i = 0; i < searchParams.length; i++) {
            const p = searchParams[i].split('=');
            params[p[0]] = decodeURIComponent(p[1]);
        }
        return params;
    }

    return (
        <></>
    );
};
