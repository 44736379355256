import React from 'react';
import classNames from 'classnames';
import {useFormContext} from 'react-hook-form';
import {Input, Select} from '../../../../fields';
import {IDisksDataOptionProps} from '../../types';
import {UiSelect} from '../../../../components/UiSelect';
import styles from './filterStyle.module.scss';

export const DataDisks = ({brand, handleSearchFromApi, handleHasMore}:IDisksDataOptionProps) => {
    const {control, register, formState:{errors}} = useFormContext();
    return (
        <div className={classNames(styles.form__col, styles.form__colData)}>
            <div className={classNames(styles.form__box, styles.form__boxMedium)}>
                <UiSelect
                    label={'Производитель'}
                    options={brand}
                    control={control}
                    fieldName={'brand'}
                    register={register}
                    errors={errors}
                    isInfinity={true}
                    handleFetchApi={handleSearchFromApi}
                    handleHasMore={handleHasMore}
                />
            </div>
            <div className={classNames(styles.form__box, styles.form__boxMedium)}>
                <Input
                    label={'Артикул или название'}
                    placeholder={''}
                    register={register}
                    control={control}
                    fieldName={'original_sku'}
                    required={false}
                    errors={errors}/>
            </div>
        </div>
    );
};
