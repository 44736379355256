import React, {useEffect, useState} from 'react';
import {Button} from '../../fields';
import {ButtonType} from '../../fields/types';
import {ids} from '../../utils/constants';
import {IFormSubmitButtonProps} from './types';
import styles from './FormSubmitButton.module.scss';

export const FormSubmitButton = ({handleSubmit}: IFormSubmitButtonProps) => {
    const [offset, setOffset] = useState<number>(0);

    useEffect(() => {
        const {left} = getPosition();
        setOffset(left);
        window.addEventListener('resize', function () {
            const {left} = getPosition();
            setOffset(left);
        });
    }, []);

    const getPosition = () => {
        return document.querySelector(`#${ids.layoutRightBox}`)?.getBoundingClientRect() as DOMRect;
    };

    return (
        <div className={styles.formSubmitButton}>
            <div className={styles.formSubmitButton__inner} style={{marginLeft: `${offset}px`}}>
                <Button
                    text={'Сохранить'}
                    viewType={ButtonType.filling}
                    type={'button'}
                    onClick={handleSubmit}
                />
                {/*<Button*/}
                {/*    text={'Отмена'}*/}
                {/*    style={{color: colors.grey100}}*/}
                {/*    viewType={ButtonType.link} />*/}
            </div>
        </div>
    );
};

