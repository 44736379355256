import React from 'react';
import styles from './Registration.module.scss';
import {RegistrationForm} from './components';

export const Registration = () => {
    return (
        <div className={styles.registration}>
            <RegistrationForm />
        </div>
    );
};
