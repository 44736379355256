import {SetFieldValue} from 'react-hook-form';
import {cond, singleObj} from '../commonTypes';
import {IQueryItem} from './types';

interface IProps {
    data: any[];
    setValue: SetFieldValue<any>
}


const fieldsAdditionalFront: singleObj = {
    gte: '_from',
    lte: '_to',
};
const fieldsAdditionalRear: singleObj = {
    gte: '_from_rear',
    lte: '_to_rear',
};

function defaultCase(item: IQueryItem, setValue: SetFieldValue<any>) {
    setValue(`${item.key}`, item.payload);
}

function frontCase(item: IQueryItem, setValue: SetFieldValue<any>) {
    item.cond === cond.eq || item.key.includes('brand_dia')
        ? setValue(`${item.key}`, item.payload)
        : setValue(`${item.key}${fieldsAdditionalFront[item.cond]}`, item.payload);
}

function rearCase(item: IQueryItem, setValue: SetFieldValue<any>) {
    item.cond === cond.eq || item.key.includes('brand_dia')
        ? setValue(`${item.key}_rear`, item.payload)
        : setValue(`${item.key}${fieldsAdditionalRear[item.cond]}`, item.payload);
}

function restsCase(item: IQueryItem, setValue: SetFieldValue<any>) {
    item.cond === cond.lte ? setValue(`${item.key}_to`, item.payload) : setValue(`${item.key}`, item.payload);
}

export const setFormDataDisks = ({data, setValue}: IProps): { rear: boolean, isAdditional: boolean } => {

    const isRear = data.some(i => i.key === 'rear');
    const isAdditional = data.some(i => i.key === 'additional');

    const fields = data.filter(d => d.key !== 'rear' && d.key !== 'additional');
    const singleFields = fields.filter(i => i.collection === 'disks' && !i.group);

    for (const singleField of singleFields) {
        defaultCase(singleField, setValue);
    }
    const frontFields = fields.filter(i => i.group === 'front');
    for (const frontField of frontFields) {
        frontCase(frontField, setValue);
    }
    const rearFields = fields.filter(i => i.group === 'rear');
    for (const rearField of rearFields) {
        rearCase(rearField, setValue);
    }
    const restsFields = fields.filter(i => i.collection === 'rests');
    for (const restsField of restsFields) {
        restsCase(restsField, setValue);
    }
    return {
        rear: isRear, isAdditional
    };
};
