import React, { useEffect, useState} from 'react';
import classNames from 'classnames';
import styles from './Details.module.scss';
import {IDetailsProps} from './types';


export const Details = ({children, isVisible, handleClose}: IDetailsProps) => {
    const [open, setOpen] = useState<boolean>(isVisible);
    useEffect(() => {
        !isVisible
            ? setTimeout(() => setOpen(isVisible), 500)
            : setOpen(isVisible);
    }, [isVisible]);
    return (
        <div className={open ? classNames(styles.details, styles.details__visible) : styles.details}>
            <div className={styles.details__overlay} onClick={handleClose}/>
            <div className={isVisible ? classNames(styles.details__content, styles.details__contentShow) : styles.details__content}>
                {children}
            </div>
        </div>
    );
};
