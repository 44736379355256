import React, { KeyboardEvent, useRef, useState} from 'react';
import classNames from 'classnames';
import {ErrorMessage} from '@hookform/error-message';
import {Controller, FieldError, useFieldArray, useFormContext} from 'react-hook-form';
import {ArrowDown, ArrowUp} from '../icons';
import {colors} from '../utils/constants';
import {useListenClickDocument} from '../hooks';
import {SearchList} from '../components/SearchList';
import {IOption, message, SelectProps} from './types';
import styles from './fields.module.scss';

export const Select = (
    {
        label,
        options,
        onMouseDown,
        onMouseOut,
        multiple,
        control,
        errors,
        fieldName,
        isSearch,
        isInfinity,
        register,
        required,
        handleSearchFromApi,
        handleHasMore
    }: SelectProps
) => {
    const {watch, resetField, setValue} = useFormContext();
    const selectRef = useRef<HTMLDivElement>(null);

    const watchFieldName = watch(fieldName);

    const [open, setOpen] = useState(false);
    const [selectItem, setSelectItem] = useState<string[] | string>(watchFieldName);

    useListenClickDocument({
        ref: selectRef,
        cb: (state) => {
            setOpen(state);
        }
    });

    const renderIcon = (flag: boolean, error: FieldError | undefined) => {
        return flag
            ? <ArrowUp height={18} width={18} color={error ? colors.red : colors.blue}/>
            : <ArrowDown height={18} width={18} color={error ? colors.red : colors.grey200}/>;
    };

    const selected = ({label, value}: IOption) => {
        setSelectItem(label);
        resetField(fieldName);
        setValue(fieldName, value);
        setOpen(false);
    };

    const listenKeyPress = (event:KeyboardEvent<HTMLInputElement>) => {
        if(event.code === 'Tab') {
            setOpen(false);
        }
    };

    return (
        <Controller
            control={control}
            name={fieldName}
            rules={{required: true}}
            render={({field: {name, value}, fieldState: {error}}) => (
                <div className={styles.select} ref={selectRef}>
                    <input type={'text'} className={styles.select__hideInput} onFocus={() => setOpen(true)}/>
                    <label className={
                        open
                            ? error
                                ? classNames(styles.select__label, styles.colorError)
                                : classNames(styles.select__label, styles.colorActive)
                            : error
                                ? classNames(styles.select__label, styles.colorError)
                                : styles.select__label
                    }>{label}</label>
                    <div
                        className={styles.select__header}
                        style={{
                            borderColor: open
                                ? error ? colors.red : colors.blue
                                : error ? colors.red : colors.grey200
                        }}
                        onClick={() => {
                            return multiple
                                ? (setOpen(!open))
                                : setOpen(!open);
                        }}
                        onMouseOut={onMouseOut}
                        onMouseDown={onMouseDown}>
                        <input
                            {...register(name)}
                            className={styles.select__output}
                            readOnly
                        />
                        {renderIcon(open, error)}
                    </div>

                    <ErrorMessage
                        errors={errors}
                        name={fieldName}
                        render={({message}: { message: message }) => {
                            return <p className={styles.errorText}>{message}</p>;
                        }}
                    />
                    {open
                        ? (
                            isSearch
                                ? (
                                    <SearchList
                                        defaultValue={watchFieldName}
                                        options={options}
                                        listenClearSearchInput={search => setValue(fieldName, search)}
                                        handleSearchFromApi={handleSearchFromApi}
                                        infinityScroll={isInfinity}
                                        infinityFetch={handleHasMore}
                                        listenKeyPress={listenKeyPress}
                                        handleSelect={(item) => selected(item)}
                                    />
                                ) : (
                                    <ul className={styles.select__list}>
                                        {options.map((i, index) =>
                                            <li
                                                key={i.value}
                                                className={
                                                    selectItem === i.value
                                                        ? classNames(styles.select__item, styles.select__itemActive)
                                                        : styles.select__item
                                                }
                                                onClick={() => selected(i)}
                                            >{i.label}</li>
                                        )}
                                    </ul>)
                        ) : null
                    }
                </div>
            )}/>
    );
};
