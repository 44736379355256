import React, {useState} from 'react';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import classNames from 'classnames';
import {AvatarItemProps} from '../types';
import styles from '../Avatar.module.scss';
import {sidebarConfig} from '../../Sidebar/config';
import {logout} from '../../../store/app.slice';
import {useAppDispatch} from '../../../hooks';

export const AvatarMedium = ({image, full_name}: AvatarItemProps) => {
    const location = useLocation();
    const dispatch =useAppDispatch();
    const navigate = useNavigate();
    const [show, setShow] = useState<boolean>(false);

    const handleLogout = () => {
        dispatch(logout({navigate}));
    };
    return (
        <div className={styles.avatarM}
             onMouseOver={() => setShow(true)}
             onMouseOut={() => setShow(false)}
        >
            <div>
                <div className={styles.avatarM__imgWrap}>
                    {image !== undefined && <img className={styles.avatarM__img} src={image} alt={'avatar'}/>}
                </div>
            </div>
            <h2 className={styles.avatarM__name}>{full_name ? full_name : 'Укажите имя'}</h2>
            {show ? <ul className={styles.avatarM__list}>
                {sidebarConfig.map(i => {
                    if (i.path === 'exit') {
                        return (
                            <button
                                key={i.path}
                                className={classNames(styles.avatarM__item, styles.avatarM__itemActive)}
                                onClick={handleLogout}>
                                {i.label}
                            </button>);
                    }
                    return (
                        <NavLink
                            key={i.path}
                            to={i.path}
                            className={
                                i.path === location.pathname
                                    ? classNames(styles.avatarM__item, styles.avatarM__itemActive)
                                    : classNames(styles.avatarM__item)
                            }>
                            <span>{i.label}</span>
                        </NavLink>
                    );
                })}
            </ul> : null}
        </div>
    );
};
