import React, {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import * as yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import styles from '../RecoveryPassword.module.scss';
import {Button, ConfirmPassword, InputPassword} from '../../../fields';
import {ButtonType} from '../../../fields/types';
import {getParams} from '../../../helpers';
import {useAppDispatch} from '../../../hooks';
import {updatePassword} from '../../../store/app.slice';

const schema = yup.object({
    token: yup.string().required(),
    login: yup.string().email().required(),
    password: yup.string().min(6, 'Пароль не может быть меньше 6 символов').required('Придумайте пароль'),
    confirm_password: yup.string().oneOf([yup.ref('password')], 'Пароли не совпадают')
});


export const NewPasswordForm = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {control, register, setValue, formState: {errors}, handleSubmit} = useForm({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        const params = getParams(location.search);
        setValue('login', params[1]['email']);
        setValue('token', params[0]['token']);
    }, []);

    const submit = handleSubmit(values => {
        dispatch(
            updatePassword({
                login: values.login,
                navigate: navigate,
                password: values.password,
                token: values.token
            }));
    });

    return (
        <div className={styles.form}>
            <h1 className={styles.form__title}>Восстановление пароля</h1>
            <div className={styles.form__box}>
                <InputPassword
                    label={'Новый пароль'}
                    placeholder={''}
                    register={register}
                    control={control}
                    fieldName={'password'}
                    errors={errors}
                    required={true}
                />
            </div>
            <div className={styles.form__box}>
                <ConfirmPassword
                    label={'Повторите пароль'}
                    placeholder={''}
                    register={register}
                    control={control}
                    fieldName={'confirm_password'}
                    errors={errors}
                    required={true}
                />
            </div>
            <div className={styles.form__box}>
                <Button
                    text={'Сменить пароль'}
                    viewType={ButtonType.filling}
                    style={{
                        width: '100%'
                    }}
                    type={'button'}
                    onClick={submit}
                />
            </div>
        </div>
    );
};
