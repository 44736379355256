import React from 'react';
//import {MagnifyingGlass, ProgressBar, RotatingLines} from 'react-loader-spinner';
//import {colors} from '../../utils/constants';
import {LoaderIcon} from '../../icons';
import styles from './Loader.module.scss';

export const Loader = ({...props}) => {
    return (
        <div className={styles.loader} {...props}>
            <LoaderIcon />
        </div>
    );
};

