import React, {useState} from 'react';
import styles from './componentsStyle.module.scss';
import {BasicInformationForm} from './BasicInformationForm';
import {Companies} from './Companies';
//import {TabsSwitcher} from './TabsSwitcher';

export const Tabs = () => {
    const [currentTab] = useState<number>(0);
    return (
        <div className={styles.tabs}>
            {/*<TabsSwitcher handleSwitch={tab => setCurrentTab(tab)} currentTab={currentTab} />*/}
            <div className={styles.tabs__item}>
                {currentTab === 0
                    ? <BasicInformationForm/>
                    : <Companies />}
            </div>
        </div>
    );
};
