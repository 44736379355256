import axios from 'axios';

export const devBaseUrl = 'https://api.tirebase.desire-s.ru/api';
export const prodBaseUrl = 'https://api.tirebase.desire-s.ru/api';


export const api = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? devBaseUrl : prodBaseUrl,
});
//api.defaults.headers.common.accept = 'application/json';
