import React, {useEffect} from 'react';
import {useFormContext} from 'react-hook-form';
import {UiSelect} from '../../../../components/UiSelect';
import {IParamsTireProps} from '../../types';
import {Checkbox} from '../../../../fields';
import styles from './style.module.scss';

export const TypesParams = ({width, height, diameter, setRear, isRear}: IParamsTireProps) => {
    const {control, register, unregister, formState: {errors}} = useFormContext();

    useEffect(() => {
        if (!isRear) {
            unregister(['width_rear', 'height_rear', 'diameter_rear']);
        }
    }, [isRear]);
    return (
        <>
            <div className={styles.box}>
                <UiSelect
                    label={isRear ? 'Ширина передних' : 'Ширина'}
                    options={width}
                    control={control}
                    fieldName={'width'}
                    register={register}
                    errors={errors}
                    isInfinity={false}
                />
            </div>
            <div className={styles.box}>
                <UiSelect
                    label={isRear ? 'Высота передних' : 'Высота'}
                    options={height}
                    control={control}
                    fieldName={'height'}
                    register={register}
                    errors={errors}
                    isInfinity={false}
                />
            </div>
            <div className={styles.box}>
                <UiSelect
                    label={isRear ? 'Диаметр передних' : 'Диаметр'}
                    options={diameter}
                    control={control}
                    fieldName={'diameter'}
                    register={register}
                    errors={errors}
                    isInfinity={false}
                />
            </div>
            <div className={styles.box}>
                <Checkbox
                    label={'Разноширокие'}
                    state={isRear}
                    changeState={state => setRear(state)}
                />
            </div>
            {
                isRear ? (
                    <>
                        <div className={styles.box}>
                            <UiSelect
                                label={'Ширина задних'}
                                options={width}
                                control={control}
                                fieldName={'width_rear'}
                                register={register}
                                errors={errors}
                                isInfinity={false}
                            />
                        </div>
                        <div className={styles.box}>
                            <UiSelect
                                label={'Высота задних'}
                                options={height}
                                control={control}
                                fieldName={'height_rear'}
                                register={register}
                                errors={errors}
                                isInfinity={false}
                            />
                        </div>
                        <div className={styles.box}>
                            <UiSelect
                                label={'Диаметр задних'}
                                options={diameter}
                                control={control}
                                fieldName={'diameter_rear'}
                                register={register}
                                errors={errors}
                                isInfinity={false}
                            />
                        </div>
                    </>
                ): null
            }
        </>
    );
};
