import {ButtonHTMLAttributes, HTMLAttributes, ReactElement, ReactNode} from 'react';
import {FieldErrors, UseFormRegister} from 'react-hook-form';

export type message = string | ReactElement

export interface IOption {
    label: string;
    value: string | number;
}

export interface SelectNativeProps extends HTMLAttributes<HTMLDivElement> {
    label?: string;
    options: IOption[];
    defaultValue: string;
    handleSelect: (item:IOption) => void;
}

export interface SelectProps extends HTMLAttributes<HTMLDivElement> {
    label?: string;
    options: IOption[];
    multiple: boolean;
    control: any;
    fieldName: string;
    errors: FieldErrors;
    isSearch?: boolean;
    isInfinity?: boolean;
    required: boolean;
    register: UseFormRegister<any>;
    handleSearchFromApi?:(search: string) => void;
    handleHasMore?: () => void;
}

export interface ControlledSElectProps {
    label?: string;
    placeholder?: string;
    options: IOption[];
    multiple: boolean;
    handleSelect: (item: string[] | string) => void;
}

export interface InputProps extends HTMLAttributes<HTMLDivElement> {
    label: string;
    placeholder: string;
    defaultValue?: string;
    register: UseFormRegister<any>;
    control: any;
    fieldName: string;
    errors: FieldErrors;
    handleFocus?: () => void;
    required: boolean;
    inputType?: string;
}

export interface InputNativeProps extends HTMLAttributes<HTMLDivElement> {
    label: string;
    placeholder: string;
    value: string;
    defaultValue?: string;
    handleChange: (value: string) => void;
}



export interface CheckboxProps extends HTMLAttributes<HTMLDivElement> {
    state: boolean;
    changeState: (state: boolean) => void;
    tooltip?: string;
    label?: string;
}

export interface RadioButtonProps {
    initStatus: boolean;
    changeStatus: (status: boolean) => void;
    label?: string;
}

export enum ButtonType {
    outline = 'outline',
    filling = 'filling',
    link = 'link',
    light = 'light',
    vk = 'vk',
    arrowLeft = 'arrowLeft'
}

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    text: string;
    viewType: ButtonType;
}

export enum IconButtonType {
    outline = 'outline',
    vk = 'vk',
    arrowLeft = 'arrowLeft'
}

export interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    text: string;
    viewType: IconButtonType;
    icon: ReactNode;
}

