import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {Input} from '../../../fields';
import {Tooltip} from '../../../components/Tooltip';
import {FormSubmitButton} from '../../../components/FormSubmitButton';
import {defaultFormFields, IConfirmPhone, IProfile} from '../types';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {
    useGetUserProfileQuery,
    useUpdateUserProfileMutation,
    useUserContactsVerifyMutation
} from '../Profile.api';
import {Phone} from '../../../components/Phone';
import {Email} from '../../../components/Email';
import {changeEmail, clearConfirmPhone, sendChangePhone, sendConfirmPhone, verifyEmail} from '../Profile.slice';
import styles from './componentsStyle.module.scss';

const schema = yup.object({
    full_name: yup.string().required('Это поле обязательно к заполнению'),
    region: yup.string().required('Это поле обязательно к заполнению'),
    city: yup.string().required('Это поле обязательно к заполнению')
});


export const BasicInformationForm = () => {
    const dispatch = useAppDispatch();
    const {token} = useAppSelector(state => state.app);
    const {confirmPhone} = useAppSelector(state => state.profile);

    const {data} = useGetUserProfileQuery({token: token});
    const [userContactsVerify] = useUserContactsVerifyMutation();
    const [updateUserProfile] = useUpdateUserProfileMutation();

    const {
        control,
        register,
        setValue,
        reset,
        formState: {errors, dirtyFields},
        handleSubmit
    } = useForm<IProfile>({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        defaultValues: defaultFormFields
    });

    useEffect(() => {
        if (data) {
            reset(data);
            setFields(data);
        }
    }, [data]);

    const submit = handleSubmit(async (values) => {
        await updateUserProfile({body: values, token});
    });

    const setFields = (data: IProfile) => {
        setValue('full_name', data.full_name ?? '');
        setValue('region', data.region ?? '');
        setValue('city', data.city ?? '');
    };

    const handleSendCode = (code: string) => {
        const {base_url, verification_params} = confirmPhone as IConfirmPhone;
        const url = data?.phone_verified_at
            ? `${base_url}?code=${code}&expires=${verification_params.expires}&new_phone=${verification_params.new_phone}&signature=${verification_params.signature}`
            : `${base_url}?code=${code}&expires=${verification_params.expires}&signature=${verification_params.signature}`;
        if (token) {
            userContactsVerify({url, token, type: 'phone'});
            dispatch(clearConfirmPhone({payload: null}));
        }
    };
    const handleSendPhone = (phone: string) => {
        if (data?.phone_verified_at) {
            dispatch(sendChangePhone({phone}));
        } else {
            dispatch(sendConfirmPhone());
        }
    };

    const handleSendEmail = (email: string) => {
        if (data?.email_verified_at) {
            dispatch(changeEmail({email}));
        } else {
            dispatch(verifyEmail({email}));
        }


    };

    return (
        <div className={styles.basicInformation}>
            <h3 className={styles.basicInformation__title}>Основная информация</h3>
            <div className={styles.basicInformation__row}>
                <div className={styles.basicInformation__boxL}>
                    <Input
                        label={'Имя Фамилия'}
                        placeholder={''}
                        register={register}
                        control={control}
                        fieldName={'full_name'}
                        required={false}
                        errors={errors}
                    />
                </div>
            </div>
            <div className={styles.basicInformation__row}>
                <div className={styles.basicInformation__boxL}>
                    <Input
                        label={'Область'}
                        placeholder={''}
                        register={register}
                        control={control}
                        fieldName={'region'}
                        errors={errors}
                        required={false}
                    />
                </div>
                <div className={styles.basicInformation__boxR}>
                    <Input
                        label={'Город'}
                        placeholder={''}
                        register={register}
                        control={control}
                        fieldName={'city'}
                        errors={errors}
                        required={false}
                    />
                </div>
            </div>
            <div className={styles.basicInformation__row}>
                <div className={styles.basicInformation__boxL}>
                    <Phone
                        defaultValue={data?.phone ?? ''}
                        isVerify={data?.phone_verified_at ?? null}
                        handleSendCode={handleSendCode}
                        handleSendPhone={handleSendPhone}
                    />
                </div>
                <div className={styles.basicInformation__boxR}>
                    <Email
                        isVerify={data?.email_verified_at ?? null}
                        defaultValue={data?.email ?? ''}
                        handleSendEmail={handleSendEmail}/>
                </div>
            </div>
            {/*<div className={styles.rating}>*/}
            {/*    <h3 className={styles.rating__title}>Рейтинг</h3>*/}
            {/*    <div className={styles.rating__box}>*/}
            {/*        <p className={styles.rating__text}>270 баллов</p>*/}
            {/*        <Tooltip*/}
            {/*            width={400}*/}
            {/*            text={'Как рождается оформление сайта? Сначала рисуется дизайн-макет будущего сайта (как правило, его рисуют в фотошопе). В этом макете рисуют все: шапку сайта, логотип, меню, блоки с информацией и все остальное. Затем верстальщик берет инструмент Раскройка и разрезает этот макет на отдельные фрагменты. Для чего это надо? Каждый фрагмент сайта должен работать, а за работу отвечает код. Поэтому необходимо разделить макет на небольшие части и для каждой из них будет прописан свой код, чтобы в итоге потом на сайте все работало, нажималось, мерцало и тому подобное.'}/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {Object.keys(dirtyFields).length > 0 ? <FormSubmitButton handleSubmit={submit}/> : null}
        </div>
    );
};
