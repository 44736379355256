import React, {useEffect, KeyboardEvent,FocusEvent, useState} from 'react';
import {InputNative} from '../../fields';
import {IOption} from '../../fields/types';
import {CustomInfinityScroll} from '../CustomInfinityScroll';
import {ISearchListProps} from './types';
import styles from './SearchList.module.scss';

export const SearchList = (
    {
        defaultValue,
        options,
        handleSelect,
        listenClearSearchInput,
        handleSearchFromApi,
        infinityScroll,
        infinityFetch,
        listenKeyPress
    }: ISearchListProps) => {
    const [search, setSearch] = useState<string>(defaultValue);
    const [data, setData] = useState<IOption[]>(options);

    useEffect(() => {
        options !== undefined && setData(options);
    }, [options]);

    useEffect(() => {
        if (search !== '') {
            if (handleSearchFromApi) {
                handleSearchFromApi(search);
            } else {
                const newData = options.filter(i => i.label.toLowerCase().includes(search.toLowerCase()));
                setData(newData);
            }
        } else {
            if (handleSearchFromApi) handleSearchFromApi(search);

            listenClearSearchInput(search);
            setData(options);
        }
    }, [search]);

    const searchByOptions = (value: string) => {
        setSearch(value);
    };

    return (
        <div className={styles.searchList}>
            <InputNative
                label={''}
                placeholder={'поиск'}
                value={search}
                onKeyDown={(event:KeyboardEvent<HTMLInputElement>) => {
                    listenKeyPress(event);
                }}
                handleChange={searchByOptions}
            />
            {infinityScroll
                ? (
                    <CustomInfinityScroll
                        items={options}
                        fetchData={infinityFetch}
                        height={245}
                        hasMore={true} className={styles.list}>
                        <>{data?.map((i, index) =>
                            <li
                                key={i.value}
                                className={styles.list__item}
                                onClick={() => handleSelect(i)}
                            >{i.label}</li>
                        ) ?? []}</>
                    </CustomInfinityScroll>
                ) : (
                    <ul className={styles.list}>
                        {data?.map((i, index) =>
                            <li
                                key={i.value}
                                className={styles.list__item}
                                onClick={() => handleSelect(i)}
                            >{i.label}</li>
                        ) ?? null}
                    </ul>
                )}
        </div>
    );
};

