import React, {useState} from 'react';
import {Question} from '../../icons';
import {colors} from '../../utils/constants';
import {ITooltipProps} from './types';
import styles from './Tooltip.module.scss';

export const Tooltip = ({text, width, colorIcon}: ITooltipProps) => {
    const [hover, setHover] = useState<boolean>(false);
    return (
        <div className={styles.tooltip}>
            <Question
                width={16}
                height={16}
                color={colorIcon ?? colors.black}
                onMouseOver={() => setHover(true)}
                onMouseOut={() => setHover(false)}
            />
            {hover
                ? (<div className={styles.tooltip__textWrap} style={{width}}>
                    <p className={styles.tooltip__text}>{text}</p>
                </div>)
                : null}
        </div>
    );
};

