import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {Radio, RadioUnselect} from '../icons';
import {colors} from '../utils/constants';
import {RadioButtonProps} from './types';
import styles from './fields.module.scss';

export const RadioButton = (
    {
        label,
        initStatus = false,
        changeStatus
    }: RadioButtonProps
) => {
    const [checked, setChecked] = useState(initStatus);
    const [isHover, setHover] = useState(false);

    useEffect(() => {
        setChecked(initStatus);
    }, [initStatus]);

    const handleChecked = () => {
        setChecked(!checked);
        changeStatus(!checked);
    };

    return (
        <div className={styles.radioButton} onClick={handleChecked}>
            {checked
                ? <Radio width={24} height={24} color={colors.blue}/>
                : <RadioUnselect
                    width={24}
                    height={24}
                    color={isHover ? colors.grey100 : colors.grey200}
                    onMouseOver={() => setHover(true)}
                    onMouseOut={() => setHover(false)}
                />}
            {label
                ? <span
                    className={
                    checked
                        ? classNames(styles.radioButton__label, styles.colorActive)
                        : styles.radioButton__label
                }
                >{label}</span> : null}
        </div>
    );
};
