import React, {useEffect} from 'react';
import {Navigate, Outlet, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {Home} from './pages/Home';
import {Login} from './pages/Login';
import {Registration} from './pages/Registration';
import {RecoveryPassword} from './pages/RecoveryPassword';
import {Profile} from './pages/Profile';
import {useAppAlert, useAppSelector} from './hooks';
import {ErrorPage} from './pages/ErrorPage';
import {FavoriteSuppliers} from './pages/FavoriteSuppliers';
import {NewPassword} from './pages/RecoveryPassword/NewPassword';
import {Cart} from './pages/Cart';
import {alertTypes} from './store/types';
import {Suppliers} from './pages/Suppliers';
import {Tires} from './pages/Tires';
import {Redirect} from './pages/Redirect';
import {Disks} from './pages/Disks';
import {useExpiresToken} from './hooks/useExpiresToken';

const App = () => {
    const {initAlert} = useAppAlert();
    const {errorReq, successReq} = useAppSelector(state => state.app);

    useEffect(() => {
        errorReq !== null && initAlert({
            type: alertTypes.ERROR,
            text: errorReq?.message ?? '',
        });
        successReq !== null && initAlert({
            type: alertTypes.SUCCESS,
            text: successReq?.message ?? '',
        });
    }, [errorReq, successReq]);

    return (
        <Routes>
            <Route path={'/login'} element={<Login/>}/>
            <Route path={'/registration'} element={<Registration/>}/>
            <Route path={'/recovery-password'} element={<RecoveryPassword/>}/>
            <Route path={'/reset-password'} element={<NewPassword/>}/>
            <Route path={'/'} element={<PrivateRoute/>}>
                {/*<Route path={'/'} element={<Home/>}/>*/}
                <Route path={'/profile'} element={<Profile/>}/>
                <Route path={'/redirect'} element={<Redirect/>}/>
                <Route path={'/favorite-suppliers'} element={<FavoriteSuppliers/>}/>
                <Route path={'/cart'} element={<Cart/>}/>
                <Route path={'/'} element={<Tires/>}/>
                <Route path={'/tires'} element={<Tires/>}/>
                <Route path={'/disks'} element={<Disks/>}/>
                <Route path={'/suppliers'} element={<Suppliers/>}/>
                <Route path={'*'} element={<ErrorPage/>}/>
            </Route>
        </Routes>
    );
};

function PrivateRoute() {
    const location = useLocation();
    const navigation = useNavigate();
    const {not_expires} = useExpiresToken();
    const {token} = useAppSelector(state => state.app);

    useEffect(() => {
       const isOuterTransition: string = location.search;
       if(isOuterTransition !== '' && token) {
            navigation(isOuterTransition);
       }
    }, [token]);

    if (token && not_expires) {
        return <Outlet/>;
    } else {
        return <Navigate to={'/login'}/>;
    }
}

export default App;
