import React from 'react';
import styles from './RecoveryPassword.module.scss';
import {RecoveryForm} from './components';

export const RecoveryPassword = () => {
    return (
        <div className={styles.recoveryPassword}>
            <RecoveryForm />
        </div>
    );
};
