import React, {useState} from 'react';
import classNames from 'classnames';
import {useFormContext} from 'react-hook-form';
import {Checkbox, Select} from '../../../../../fields';
import styles from '../filterStyle.module.scss';
import {IDisksParamsOptionProps} from '../../../types';
import {UiSelect} from '../../../../../components/UiSelect';

interface IProps extends IDisksParamsOptionProps{
    setRear: () => void;
    isRear: boolean;
}

export const ParamsDisks = (
    {
        width, outfit, diameter, pcd, brand, brand_dia, isRear, setRear
    }:IProps
) => {
    const {control, register, formState: {errors}} = useFormContext();
    //const [addParams, setAddParams] = useState<boolean>(false);
    return (
        <>
            <div className={classNames(styles.form__col, styles.form__colParams)}>
                <div className={styles.form__colParams__inner}>
                    <div className={classNames(styles.form__box)}>
                        <UiSelect
                            label={'Ширина, от'}
                            options={width}
                            control={control}
                            fieldName={'width_from'}
                            register={register}
                            errors={errors}
                            isInfinity={false}
                        />
                    </div>
                    <span className={styles.form__tire}>—</span>
                    <div className={classNames(styles.form__box)}>
                        <UiSelect
                            label={'до'}
                            options={width}
                            control={control}
                            fieldName={'width_to'}
                            register={register}
                            errors={errors}
                            isInfinity={false}
                        />
                    </div>
                    <div className={styles.space}/>
                    <div className={classNames(styles.form__box)}>
                        <UiSelect
                            label={'Диаметр'}
                            options={diameter}
                            control={control}
                            fieldName={'diameter'}
                            register={register}
                            errors={errors}
                            isInfinity={false}
                        />
                    </div>
                    <div className={classNames(styles.form__box)}>
                        <UiSelect
                            label={'PCD'}
                            options={pcd}
                            control={control}
                            fieldName={'pcd'}
                            register={register}
                            errors={errors}
                            isInfinity={false}
                        />
                    </div>
                    <div className={classNames(styles.form__box)}>
                        <UiSelect
                            label={'Центр отв. от'}
                            options={brand_dia}
                            control={control}
                            fieldName={'brand_dia'}
                            register={register}
                            errors={errors}
                            isInfinity={false}
                        />
                    </div>
                    <div className={styles.space}/>
                    <div className={classNames(styles.form__box)}>
                        <UiSelect
                            label={'Вылет, от'}
                            options={outfit}
                            control={control}
                            fieldName={'outfit_from'}
                            register={register}
                            errors={errors}
                            isInfinity={false}
                        />
                    </div>
                    <span className={styles.form__tire}>—</span>
                    <div className={classNames(styles.form__box)}>
                        <UiSelect
                            label={'до'}
                            options={outfit}
                            control={control}
                            fieldName={'outfit_to'}
                            register={register}
                            errors={errors}
                            isInfinity={false}
                        />
                    </div>
                    <div className={classNames(styles.form__box, styles.form__boxSparka)}>
                        <Checkbox
                            label={'Разноширокие'}
                            state={isRear}
                            changeState={setRear}
                        />
                    </div>
                </div>
                <div className={classNames(styles.form__colParams__inner, styles.form__colParams__innerRear)}>
                    {isRear ? (
                        <>
                            <div className={classNames(styles.form__box)}>
                                <UiSelect
                                    label={'Ширина зад. от'}
                                    options={width}
                                    control={control}
                                    fieldName={'width_from_rear'}
                                    register={register}
                                    errors={errors}
                                    isInfinity={false}
                                />
                            </div>
                            <span className={styles.form__tire}>—</span>
                            <div className={classNames(styles.form__box)}>
                                <UiSelect
                                    label={'до'}
                                    options={width}
                                    control={control}
                                    fieldName={'width_to_rear'}
                                    register={register}
                                    errors={errors}
                                    isInfinity={false}
                                />
                            </div>
                            <div className={styles.space}/>
                            <div className={classNames(styles.form__box)}>

                                <UiSelect
                                    label={'Диаметр зад.'}
                                    options={diameter}
                                    control={control}
                                    fieldName={'diameter_rear'}
                                    register={register}
                                    errors={errors}
                                    isInfinity={false}
                                />
                            </div>
                            <div className={classNames(styles.form__box)}>
                                <UiSelect
                                    label={'PCD зад.'}
                                    options={pcd}
                                    control={control}
                                    fieldName={'pcd_rear'}
                                    register={register}
                                    errors={errors}
                                    isInfinity={false}
                                />
                            </div>
                            <div className={classNames(styles.form__box)}>
                                <UiSelect
                                    label={'Центр отв. зад.'}
                                    options={brand_dia}
                                    control={control}
                                    fieldName={'brand_dia_rear'}
                                    register={register}
                                    errors={errors}
                                    isInfinity={false}
                                />
                            </div>
                            <div className={styles.space}/>
                            <div className={classNames(styles.form__box)}>
                                <UiSelect
                                    label={'Вылет зад. от'}
                                    options={outfit}
                                    control={control}
                                    fieldName={'outfit_from_rear'}
                                    register={register}
                                    errors={errors}
                                    isInfinity={false}
                                />
                            </div>
                            <span className={styles.form__tire}>—</span>
                            <div className={classNames(styles.form__box)}>
                                <UiSelect
                                    label={'до'}
                                    options={outfit}
                                    control={control}
                                    fieldName={'outfit_to_rear'}
                                    register={register}
                                    errors={errors}
                                    isInfinity={false}
                                />
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
        </>
    );
};
