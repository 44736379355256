import React, {useEffect, useState} from 'react';
import {SingleLayout} from '../../layouts';
import { useAppSelector} from '../../hooks';
import styles from './Tires.module.scss';
import {TypesFilter} from './components/filter';
import {TableTires} from './components/table';

export const Tires = () => {
    const {meta} = useAppSelector(state => state.tires);
    const [isRear, setRear] = useState<boolean>(false);
    return (
        <SingleLayout>
            <div className={styles.tires}>
                <TypesFilter setRear={state => setRear(state)} isRear={isRear}/>
                <TableTires isRear={isRear}/>
            </div>
        </SingleLayout>
    );
};
