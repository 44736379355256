import React, {useRef, useState} from 'react';
import classNames from 'classnames';
import {Details} from '../../../../components/Details';
import {IDisk} from '../../types';
import {useAppDispatch, useAppSelector} from '../../../../hooks';
import {getDisksRests} from '../../thunks';
import styles from './tableStyles.module.scss';
import {TableItem} from './TableItem';
import {Product} from './Product';
import {ProductCard} from './ProductCard';


interface ITableProductAccordionProps extends IDisk {
    viewType: 'grid' | 'list'
}

export const TableProductAccordion = (
    {
        brand,
        bulk_price,
        countries,
        id,
        model,
        original_sku,
        quantity,
        retail_price,
        title,
        type,
        viewType,
        color,
        step, image
    }: ITableProductAccordionProps) => {
    const dispatch = useAppDispatch();
    const {filterPayload} = useAppSelector(state => state.disks);
    const refAccordion = useRef<HTMLDivElement | null>(null);
    const [open, setOpen] = useState<boolean>(false);

    const handleGetDetails = (product_id: string) => {
        dispatch(getDisksRests({data:filterPayload, product_id}));
        setOpen(!open);
    };

    return (
        <div className={viewType === 'list'
            ? step !== undefined && step <=2 ? classNames(styles.tableItem, styles.tableItemBg) : styles.tableItem
            : classNames(styles.tableItem, styles.tableItemCard)}>
            <div
                ref={refAccordion}
                aria-expanded={!open}
                className={open
                    ? classNames(styles.tableItemHeader, styles.tableItemHeaderActive)
                    : styles.tableItemHeader
                }
            >
                <div
                    className={viewType === 'list'
                        ? classNames(styles.tableItemHeader__inner, styles.tableItemHeader__innerList)
                        : styles.tableItemHeader__inner}
                    id={id?.toString()}
                    onClick={
                    () => viewType === 'list'
                        ? handleGetDetails(id?.toString())
                        : null}>
                    {viewType === 'list'
                        ? <Product
                            isOpen={open}
                            showChevron={true}
                            brand={brand}
                            bulk_price={bulk_price}
                            countries={countries}
                            id={id}
                            model={model}
                            original_sku={original_sku}
                            quantity={quantity}
                            retail_price={retail_price}
                            title={title}
                            type={type} color={color}
                            image={image}
                        />
                        : <ProductCard
                            brand={brand}
                            bulk_price={bulk_price}
                            countries={countries}
                            id={id}
                            model={model}
                            original_sku={original_sku}
                            quantity={quantity}
                            retail_price={retail_price}
                            title={title}
                            type={type}
                            color={color}
                            image={image}
                            isOpen={open}
                            handleShowDetails={() => handleGetDetails(id.toString())}
                        />}
                </div>
            </div>
            <Details handleClose={() => setOpen(!open)} isVisible={open}>
                <TableItem
                    brand={brand}
                    bulk_price={bulk_price}
                    countries={countries}
                    id={id}
                    model={model}
                    original_sku={original_sku}
                    quantity={quantity}
                    retail_price={retail_price}
                    title={title}
                    type={type}
                    color={color}
                    image={image}
                    viewType={viewType}
                    isVisible={open}
                    handleClose={() => setOpen(!open)}
                />
            </Details>
        </div>
    );
};
