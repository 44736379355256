export type IProfileApi = {
    name: string
}

export interface IConfirmPhone {
    message: string
    verification_params: {
        expires: string
        signature: string
        new_phone : string;
    },
    base_url: string
}

export interface IProfileResponse {
    message: string;
    user: IProfile
}

export interface IProfile {
    email: string,
    email_verified_at: Date | null,
    phone: string,
    phone_verified_at: Date | null,
    full_name: string,
    region: string,
    city: string,
    permissions: {
        [key: string]: boolean
    } | {},
    avatar: string;
}

export const defaultFormFields: IProfile = {
    email: '',
    email_verified_at: null,
    phone: '',
    phone_verified_at: null,
    full_name: '',
    region: '',
    city: '',
    permissions: {},
    avatar: '',
};

export interface IProfileState {
    confirmPhone: IConfirmPhone | null
}


