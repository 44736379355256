import {Cart, DisksIcon, Home, Like, Plus, TyresIcon} from '../../icons';
import {IconProps} from '../../icons/types';
import {INavConfig, INavMobile} from './types';

export const screens: INavConfig[] = [
    {
        path: '/tires',
        label: 'Шины'
    }, {
        path: '/disks',
        label: 'Диски'
    },
    // {
    //     path: '/suppliers',
    //     label: 'Поставщики'
    // },
];

export const mobileScreens: INavMobile[] = [
    {
        path: '/tires',
        label: 'Главная',
        Icon: ({width, color, height, ...props}: IconProps) =>
            <Home
                width={width}
                height={height}
                color={color}
                {...props} />
    },{
        path: '/tires',
        label: 'Шины',
        Icon: ({width, color, height, ...props}: IconProps) =>
            <TyresIcon
                width={width}
                height={height}
                color={color}
                {...props} />
    },{
        path: '/disks',
        label: 'Диски',
        Icon: ({width, color, height, ...props}: IconProps) =>
            <DisksIcon
                width={width}
                height={height}
                color={color}
                {...props} />
    },
    // {
    //     path: '/favorites',
    //     label: 'Избранное',
    //     Icon: ({width, color, height, ...props}: IconProps) =>
    //         <Like
    //             width={width}
    //             height={height}
    //             color={color}
    //             {...props} />
    // },
    // {
    //     path: '/add-products',
    //     label: 'Добавить товары',
    //     Icon: ({width, color, height, ...props}: IconProps) =>
    //         <Plus
    //             width={width}
    //             height={height}
    //             color={color}
    //             {...props} />
    // },
    // {
    //     path: '/cart',
    //     label: 'Корзина',
    //     Icon: ({width, color, height, ...props}: IconProps) =>
    //         <Cart
    //             width={width}
    //             height={height}
    //             color={color}
    //             {...props} />
    // },
    {
        path: '/profile',
        label: 'Профиль',
        Icon: ({width, color, height, ...props}: IconProps) =>
            <Home
                width={width}
                height={height}
                color={color}
                {...props} />
    },

];
