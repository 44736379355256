import React from 'react';
import styles from './Login.module.scss';
import {LoginForm} from './components';

export const Login = () => {

    return (
        <div className={styles.login}>
            <LoginForm />
        </div>
    );
};
