import React from 'react';
import defaultImage from '../../../../assets/images/disk.png';
import {Button} from '../../../../fields';
import {ButtonType} from '../../../../fields/types';
import {ArrowDown, ArrowUp} from '../../../../icons';
import {colors} from '../../../../utils/constants';
import {IDisk} from '../../types';
import {numberFormat} from '../../../../helpers';
import styles from './tableStyles.module.scss';

interface IProductCardProps extends IDisk{
    isOpen:boolean;
    handleShowDetails: () => void;
}

export const ProductCard = (
    {
        isOpen,
        handleShowDetails,
        brand,
        bulk_price,
        countries,
        id,
        model,
        original_sku,
        quantity,
        retail_price,
        title,
        type,
        color,
        step,
        image
    }:IProductCardProps) => {
    return (
        <div className={styles.card} onClick={handleShowDetails}>
            <div className={styles.card__body}>
                <img  src={image !== null ? image : defaultImage} className={styles.card__image} alt={'product'}/>
                <p className={styles.card__title}>{title}</p>
                <div className={styles.cardColor}>
                    <span className={styles.cardColor__text}>{color ? color : '-'}</span>
                </div>
                <div className={styles.cardPrice}>
                    <p className={styles.cardPrice__from}>{`Опт. ${numberFormat(bulk_price)} ₽`}</p>
                    <p className={styles.cardPrice__to}>{`Розн. ${numberFormat(retail_price)} ₽`}</p>
                </div>
                {/*<Button*/}
                {/*    text={'В корзину'}*/}
                {/*    viewType={ButtonType.outline}*/}
                {/*    style={{*/}
                {/*        width: '100%',*/}
                {/*        marginTop: 'auto'*/}
                {/*    }}*/}
                {/*/>*/}
            </div>
            <div className={styles.card__footer}>
                <p className={styles.card__footer__text}>Остаток: {numberFormat(quantity)}</p>
                {isOpen
                    ? <ArrowUp
                        width={24}
                        height={24}
                        color={colors.grey200}
                        style={{cursor: 'pointer'}}
                       // onClick={handleShowDetails}
                    />
                    : <ArrowDown
                        width={24}
                        height={24}
                        color={colors.grey200}
                        style={{cursor: 'pointer'}}
                        //onClick={handleShowDetails}
                    />}
            </div>
        </div>
    );
};
