import React from 'react';
import styles from './componentsStyle.module.scss';
const logo = require('../../../assets/images/company-logo-default.png');

export const Companies = () => {
    return (
        <div>
            <div className={styles.company}>
                <div className={styles.company__header}>
                    <div className={styles.company__logo}>
                        <img src={logo} alt={'logo'}/>
                    </div>
                    <div className={styles.company__box}>
                        <p className={styles.company__name}>Компания A</p>
                        <p className={styles.company__text}>г. Екатеринбург, ул 40 лет Комсомола 32 Б, литер А</p>
                    </div>
                </div>
                <p className={styles.company__email}>company_A_examplemail@gmail.com</p>
            </div>
            <div className={styles.company}>
                <div className={styles.company__header}>
                    <div className={styles.company__logo}>
                        <img src={logo} alt={'logo'}/>
                    </div>
                    <div className={styles.company__box}>
                        <p className={styles.company__name}>Компания A</p>
                        <p className={styles.company__text}>г. Екатеринбург, ул 40 лет Комсомола 32 Б, литер А</p>
                    </div>
                </div>
                <p className={styles.company__email}>company_A_examplemail@gmail.com</p>
            </div>
            <div className={styles.company}>
                <div className={styles.company__header}>
                    <div className={styles.company__logo}>
                        <img src={logo} alt={'logo'}/>
                    </div>
                    <div className={styles.company__box}>
                        <p className={styles.company__name}>Компания A</p>
                        <p className={styles.company__text}>г. Екатеринбург, ул 40 лет Комсомола 32 Б, литер А</p>
                    </div>
                </div>
                <p className={styles.company__email}>company_A_examplemail@gmail.com</p>
            </div>
        </div>
    );
};
