import React from 'react';
import {Check} from '../../icons';
import {colors} from '../../utils/constants';
import styles from './SuccessCheck.module.scss';

export const SuccessCheck = () => {
    return (
        <div className={styles.successCheck}>
            <Check width={80} height={80} color={colors.white} />
        </div>
    );
};

