import React from 'react';
import {SingleLayout} from '../../layouts';
import styles from './Suppliers.module.scss';
import {Filter, Table} from './components';

export const Suppliers = () => {
    return (
        <SingleLayout>
            <div className={styles.suppliers}>
                <h1 className={styles.suppliers__title}>
                    Поставщики
                    <span className={styles.suppliers__count}>1670</span>
                </h1>
                <Filter />
                <Table />
            </div>
        </SingleLayout>
    );
};
