import React from 'react';
import {IconProps} from './types';

export const Radio = ({width, height, color, ...props}:IconProps) => {
    return (
        <svg {...props} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="12" fill="#472CF5"/>
            <rect x="7" y="7" width="10" height="10" rx="5" fill="white"/>
        </svg>
    );
};
