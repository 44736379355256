import React, {KeyboardEvent, useEffect, useRef, useState, ChangeEvent} from 'react';
import classNames from 'classnames';
import {ErrorMessage} from '@hookform/error-message';
import {Controller, FieldError, useFormContext} from 'react-hook-form';
import {useFuse, useListenClickDocument} from '../../hooks';
import {ArrowDown, ArrowUp} from '../../icons';
import {colors, defaultOptionItem} from '../../utils/constants';
import {IOption} from '../../fields/types';
import {CustomInfinityScroll} from '../CustomInfinityScroll';
import {IUiSelectProps} from './types';
import styles from './styles.module.scss';

export const UiSelect = (
    {
        label,
        options,
        onMouseDown,
        onMouseOut,
        control,
        errors,
        fieldName,
        isInfinity,
        useFilter = true,
        register,
        handleFetchApi,
        handleHasMore,
        heightList = 185
    }: IUiSelectProps<string>
) => {
    const {result, handleSearch} = useFuse(options);
    const refOption = useRef<HTMLLIElement>(null);
    const refInput = useRef<HTMLInputElement>(null);
    const {watch, resetField, setValue} = useFormContext();
    const selectRef = useRef<HTMLDivElement>(null);
    const [dataOptions, setDataOptions] = useState<IOption[]>(options);
    const [search, setSearch] = useState<string>('');
    const [open, setOpen] = useState(false);
    const [optionText, setOptionText] = useState<string>('');
    const [currentIndexOption, setCurrentIndexOption] = useState<number>(-1);

    const watchFieldName = watch(fieldName);

    useEffect(() => {
        watchFieldName && setSearch(watchFieldName);
    }, [watchFieldName]);

    useEffect(() => {
        if (handleFetchApi !== undefined) handleFetchApi(search);
        if (useFilter) {
            if (search !== '' && options !== undefined) {
                const newData = result.filter(i => i.score <= 0.001).map(i => i.item);
                setDataOptions(newData);
            } else {
                setValue(fieldName, search);
                setDataOptions(options);
            }
        } else {
            setDataOptions(options);
        }
    }, [search, options, result]);

    useEffect(() => {
        const timerId = refInput.current
            ? setTimeout(() => {
                refInput.current.focus();
                refInput.current.select();
            }, 300)
            : null;

        const currentIndex = dataOptions?.findIndex(i => i.label === search) ?? 0;
        setCurrentIndexOption(currentIndex);

        return () => {
            clearTimeout(timerId);
            setCurrentIndexOption(0);
        };
    }, [open]);

    useEffect(() => {
        const el = document.querySelector('li[data-current=true]');
        if (el) {
            el.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest'
            });
            setOptionText(el.textContent);
        }
    }, [currentIndexOption, search, dataOptions]);

    useListenClickDocument({
        ref: selectRef,
        cb: (state) => {
            setOpen(state);
        }
    });

    const renderIcon = (flag: boolean, error: FieldError | undefined) => {
        return flag
            ? <ArrowUp height={18} width={18} color={error ? colors.red : colors.blue}/>
            : <ArrowDown height={18} width={18} color={error ? colors.red : colors.grey200}/>;
    };

    const selected = ({value}: IOption) => {
        resetField(fieldName);
        setValue(fieldName, value);
        setOpen(false);
    };

    const listenKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.code === 'Backspace') setValue(fieldName, '');
        if (event.keyCode === 13) {
            if (optionText !== defaultOptionItem.label) {
                setValue(fieldName, optionText !== '' ? optionText : search);
            } else {
                setValue(fieldName, '');
            }
            setOpen(false);
        }
        if (event.code === 'Tab') {
            const ifThere = dataOptions.find(i => i.label === search && i.label !== defaultOptionItem.label);
            setOpen(false);
            setValue(fieldName, ifThere?.label ?? '');
        };
    };

    const listenKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
        const isUp: boolean = event.code === 'ArrowUp' && currentIndexOption > 0;
        const isDown: boolean = event.code === 'ArrowDown' && currentIndexOption < dataOptions.length - 1;
        if (isDown) {
            setCurrentIndexOption((currentIndexOption + 1));
        }
        if (isUp) {
            setCurrentIndexOption(currentIndexOption - 1);
        }
    };

    const handleChangeInput = (search: string) => {
        setSearch(search);
        setCurrentIndexOption(0);
    };

    return (
        <Controller
            control={control}
            name={fieldName}
            rules={{required: true}}
            render={({field: {name, value}, fieldState: {error}}) => (
                <div className={styles.select} ref={selectRef}>
                    <input type={'text'} className={styles.select__hideInput} onFocus={() => {
                        setTimeout(() => setOpen(true), 200);
                    }}/>
                    <label className={
                        open
                            ? error
                                ? classNames(styles.select__label, styles.colorError)
                                : classNames(styles.select__label, styles.colorActive)
                            : error
                                ? classNames(styles.select__label, styles.colorError)
                                : styles.select__label
                    }>{label}</label>
                    <div
                        className={styles.select__header}
                        style={{
                            borderColor: open
                                ? error ? colors.red : colors.blue
                                : error ? colors.red : colors.grey200
                        }}
                        onClick={() => setOpen(!open)}
                        onMouseOut={onMouseOut}
                        onMouseDown={onMouseDown}>
                        <input
                            {...register(name)}
                            className={styles.select__output}
                            readOnly
                        />
                        {renderIcon(open, error)}
                    </div>

                    <ErrorMessage
                        errors={errors}
                        name={fieldName}
                        render={({message}: { message: string }) => {
                            return <p className={styles.errorText}>{message}</p>;
                        }}
                    />
                    {open ? (
                        <div className={styles.searchList}>
                            <input
                                ref={refInput}
                                placeholder={'поиск'}
                                className={styles.input}
                                value={search}
                                style={{borderColor: colors.blue}}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    handleChangeInput(event.target.value);
                                    handleSearch(event.target.value);
                                } }
                                onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => listenKeyDown(event)}
                                onKeyUp={(event: KeyboardEvent<HTMLInputElement>) => listenKeyUp(event)}
                            />
                            <CustomInfinityScroll
                                items={dataOptions}
                                fetchData={handleHasMore}
                                hasMore={isInfinity}
                                height={heightList}
                                className={styles.list}>
                                <>{dataOptions?.map((i, index) =>
                                    <li
                                        ref={refOption}
                                        key={i.value}
                                        data-current={currentIndexOption === index ? 'true' : 'false'}
                                        className={
                                            currentIndexOption === index
                                                ? classNames(styles.list__item, styles.list__item__active)
                                                : styles.list__item
                                        }
                                        onClick={() => selected(i)}
                                    >{i.label}</li>
                                ) ?? []}</>
                            </CustomInfinityScroll>
                        </div>
                    ) : null}
                </div>
            )}/>
    );
};
