import React from 'react';
import {IModalProps} from './types';
import styles from './Modal.module.scss';

export const Modal = ({open, handleOpen, children}: IModalProps) => {
    if (!open) {
        return <></>;
    }
    return (
        <div className={styles.modal}>
            <div className={styles.modal__overlay} onClick={handleOpen}/>
            <div className={styles.modal__content}>{children}</div>
        </div>
    );
};

