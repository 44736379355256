import React, {useState} from 'react';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {colors} from '../utils/constants';
import {InputProps, message} from './types';
import styles from './fields.module.scss';

export const Input = (
    {
        label,
        placeholder,
        control,
        register,
        fieldName,
        errors,
        handleFocus,
        required,
        inputType = 'text'
    }: InputProps
) => {
    const [isFocus, setFocus] = useState<boolean>(false);
    return (
        <Controller
            control={control}
            name={fieldName}
            rules={{
                required: required,
            }}
            render={({field: {name}, fieldState : {error}}) => (
                <div
                    className={styles.inputField}
                >
                    <label
                        className={
                            isFocus
                                ? error
                                    ? classNames(styles.inputField__label, styles.colorError)
                                    : classNames(styles.inputField__label, styles.colorActive)
                                : error
                                    ? classNames(styles.inputField__label, styles.colorError)
                                    : styles.inputField__label
                        }
                    >{label}</label>
                    <input
                        {...register(name)}
                        placeholder={placeholder}
                        className={styles.inputField__input}
                        autoComplete={'off'}
                        type={inputType}
                        style={{
                            borderColor: isFocus
                                ? error ? colors.red : colors.blue
                                : error ? colors.red : colors.grey200
                        }}
                        onFocus={() => {
                            setFocus(true);
                            handleFocus && handleFocus();
                        }}
                        onBlur={() => setFocus(false)}
                    />
                    <ErrorMessage
                        errors={errors}
                        name={name}
                        render={({ message }:{message: message}) => <p className={styles.errorText}>{message}</p>}
                    />
                </div>
            )} />
    );
};
