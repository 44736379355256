import React, {useState} from 'react';
import classNames from 'classnames';
import {Details} from '../../../components/Details';
import styles from './componentsStyle.module.scss';
import {IRow} from './config';
import {CompanyDetails} from './companyDetails';

export const TableRow = ({image, city, site, company, distance}: IRow) => {
    const [visible, setVisible] = useState<boolean>(false);


    return (
        <>
            <div className={classNames(styles.tableRow)} onClick={() => setVisible(!visible)}>
                <div className={classNames(styles.tableCell)}>
                    <img className={styles.tableRow__image} src={image} alt={'company'}/>
                </div>
                <div className={classNames(styles.tableCell)}>
                    <p className={styles.tableRow__text}>{company}</p>
                </div>
                <div className={classNames(styles.tableCell)}>
                    <p className={styles.tableRow__text}>{city}</p>
                </div>
                <div className={classNames(styles.tableCell)}>
                    <p className={styles.tableRow__text}>{distance}</p>
                </div>
                <div className={classNames(styles.tableCell)}>
                    <p className={styles.tableRow__text}>{site}</p>
                </div>
                <div className={classNames(styles.tableCell)}/>
            </div>
            <Details isVisible={visible} handleClose={() => setVisible(false)}>
                <CompanyDetails
                    handleVisible={() => setVisible(!visible)}
                    image={image}
                    company={company}
                    city={city}
                    distance={distance}
                    site={site}/>
            </Details>

        </>
    );
};
