import {cond, singleObj} from '../commonTypes';
import {IGenerateRequestProps, IQueryItem} from './types';

function createObjRequest({key, payload, cond, collection, group}: IQueryItem) {
    if (group !== undefined) {
        return {key, payload, cond, collection, group};
    }
    return {key, payload, cond, collection};
}

const caseRearArray: singleObj = {
    width_from: 'width',
    width_to: 'width',
    width_from_rear: 'width',
    width_to_rear: 'width',
    diameter: 'diameter',
    diameter_rear: 'diameter',
    pcd: 'pcd',
    pcd_rear: 'pcd',
    brand_dia: 'brand_dia',
    brand_dia_rear: 'brand_dia',
    outfit_from: 'outfit',
    outfit_to: 'outfit',
    outfit_from_rear: 'outfit',
    outfit_to_rear: 'outfit',
};

const caseAdditional: singleObj = {
    bulk_price: 'bulk_price',
    bulk_price_to: 'bulk_price',
};

const ignoredKeysRear = [
    'width_from',
    'width_to',
    'width_from_rear',
    'width_to_rear',
    'diameter',
    'diameter_rear',
    'pcd',
    'pcd_rear',
    'brand_dia',
    'brand_dia_rear',
    'outfit_from',
    'outfit_to',
    'outfit_from_rear',
    'outfit_to_rear',
];

const ignoredKeysAdditional = [
    'bulk_price_to',
    'bulk_price'
];

function getCond(key: string) {
    if (key.includes('_from') || key.includes('bulk_price')) return cond.gte;
    if (key.includes('_to')) return cond.lte;
    if (key.includes('brand_dia')) return cond.gte;
    return cond.eq;
}

const getCollection = (key:string, type:string) => {
    if(['bulk_price', 'bulk_price_to', 'year'].includes(key)) return 'rests';
    if(['city'].includes(key)) return 'warehouses';
    return type;
};

function defaultCase(key: string, values: any, type: string) {
    const result: IQueryItem[] = [];
    if (Object.keys(caseRearArray).includes(key)) {
        const currentKey = caseRearArray[key];
        const obj = createObjRequest({
            key: currentKey,
            payload: values[key],
            cond: getCond(key),
            collection: type,
            group: 'front'
        });
        result.push(obj);
    } else {
        const obj = createObjRequest({
            key: key,
            payload: values[key],
            cond: cond.eq,
            collection: type,
        });

        result.push(obj);
    }

    return result;
}

function isRear(key: string, values: any, type: string) {
    const result: IQueryItem[] = [];
    if (Object.keys(caseRearArray).includes(key)) {
        const currentKey = caseRearArray[key];
        if (!key.includes('_rear')) {
            const obj = createObjRequest({
                key: currentKey,
                payload: values[key],
                cond: getCond(key),
                collection: type,
                group: 'front'
            });
            result.push(obj);
        } else {
            const obj = createObjRequest({
                key: currentKey,
                payload: values[key],
                cond: getCond(key),
                collection: type,
                group: 'rear'
            });
            result.push(obj);
        }
    }
    if (!ignoredKeysRear.includes(key)) {
        const obj = createObjRequest({
            key: key,
            payload: values[key],
            cond: cond.eq,
            collection: type,
        });

        result.push(obj);
    }

    return result;
}

function isAdditional(key: string, values: any, type: string) {
    const result: IQueryItem[] = [];
    if (Object.keys(caseAdditional).includes(key)) {
        const currentKey = caseAdditional[key];
        if (key.includes('_to')) {
            const obj = createObjRequest({
                key: currentKey,
                payload: values[key],
                cond: cond.lte,
                collection: 'rests',
            });
            result.push(obj);
        } else {
            const obj = createObjRequest({
                key: currentKey,
                payload: values[key],
                cond: cond.gte,
                collection: 'rests',
            });
            result.push(obj);
        }
    }
    if (Object.keys(caseRearArray).includes(key)) {
        const currentKey = caseRearArray[key];
        if (!key.includes('_rear')) {
            const obj = createObjRequest({
                key: currentKey,
                payload: values[key],
                cond: getCond(key),
                collection: type,
                group: 'front'
            });
            result.push(obj);
        } else {
            const obj = createObjRequest({
                key: currentKey,
                payload: values[key],
                cond: getCond(key),
                collection: type,
                group: 'rear'
            });
            result.push(obj);
        }
    }
    if (![...ignoredKeysAdditional, ...ignoredKeysRear].includes(key)) {
        const obj = createObjRequest({
            key: key,
            payload: values[key],
            cond: key === 'quantity' ? cond.gte : cond.eq,
            collection: getCollection(key, type)
        });

        result.push(obj);
    }

    return result;
}

function fullCase(key: string, values: any, type: string) {
    const result: IQueryItem[] = [];
    if (Object.keys(caseRearArray).includes(key)) {
        const currentKey = caseRearArray[key];
        if (!key.includes('_rear')) {
            const obj = createObjRequest({
                key: currentKey,
                payload: values[key],
                cond: getCond(key),
                collection: type,
                group: 'front'
            });
            result.push(obj);
        } else {
            const obj = createObjRequest({
                key: currentKey,
                payload: values[key],
                cond: getCond(key),
                collection: type,
                group: 'rear'
            });
            result.push(obj);
        }
    }
    if (Object.keys(caseAdditional).includes(key)) {
        const currentKey = caseAdditional[key];
        if (key.includes('_to')) {
            const obj = createObjRequest({
                key: currentKey,
                payload: values[key],
                cond: cond.lte,
                collection: 'rests',
            });
            result.push(obj);
        } else {
            const obj = createObjRequest({
                key: currentKey,
                payload: values[key],
                cond: cond.gte,
                collection: 'rests',
            });
            result.push(obj);
        }

    }
    if (![...ignoredKeysRear, ...ignoredKeysAdditional].includes(key)) {
        const obj = createObjRequest({
            key: key,
            payload: values[key],
            cond: key === 'quantity' ? cond.gte : cond.eq,
            collection: getCollection(key, type),
        });

        result.push(obj);
    }

    return result;
}


export const generateRequestDisks = (
    {
        values, type, rear, additional
    }: IGenerateRequestProps): { data: IQueryItem[] } => {
    const data: IQueryItem[] = [];

    for (const valuesKey in values) {
        if (!rear && !additional) {
            const result = defaultCase(valuesKey, values, type);
            data.push(...result);
        }
        if (rear && !additional) {
            const result = isRear(valuesKey, values, type);
            data.push(...result);
        }
        if (!rear && additional) {
            const result = isAdditional(valuesKey, values, type);
            data.push(...result);
        }
        if (rear && additional) {
            const result = fullCase(valuesKey, values, type);
            data.push(...result);
        }
    }

    if (rear) data.push({key: 'rear', payload: 'true', cond: cond.eq, collection: type});
    if (additional) data.push({key: 'additional', payload: 'true', cond: cond.eq, collection: type});

    const result = data.filter(i => i.payload !== '');

    return {data: result};
};
