import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {NavigateFunction} from 'react-router-dom';
import {api} from '../api';
import {helperHandleError} from '../helpers';
import {IError, ISuccess} from '../helpers/types';
import {ILoginProps, IRegistrationProps, IStateApp, ITokenRes, IUpdatePasswordProps} from './types';

const initialState: IStateApp = {
    loading: false,
    errorReq: null,
    successReq: null,
    resetSuccess: false,
    token: null,
    expires_at: null,
    brands: [],
    carBrands: [],
    carBrandsTotal: 0,
    brandsTotal: 0,
    order_by: {
        key: null,
        direction: null
    }
};

export const registration = createAsyncThunk(
    'app/registration',
    async ({login, password, navigate}: IRegistrationProps, {dispatch}) => {
        try {
            const res = await api.post<ITokenRes>('/auth/register', {login, password});
            if (res.status === 200) {
                localStorage.setItem('token', JSON.stringify(res.data.token));
                navigate('/profile');
                dispatch(setToken({token: res.data.token, expires_at: res.data.expires_at}));
            }
            return;
        } catch (e: any) {
            helperHandleError('registration, appSlice', e, dispatch);
        }
    }
);

export const login = createAsyncThunk(
    'app/login',
    async ({login, password, navigate, remember_me}: ILoginProps, {dispatch}) => {
        try {
            const res = await api.post<ITokenRes>('/auth/login', {login, password, remember_me});
            if (res.status === 200) {
                localStorage.setItem('token', JSON.stringify(res.data.token));
                dispatch(setToken({token: res.data.token, expires_at: res.data.expires_at}));
                navigate('/tires');
            }
            return;
        } catch (e) {
            helperHandleError('registration, appSlice', e, dispatch);
        }
    }
);

export const resetPassword = createAsyncThunk(
    'app/resetPassword',
    async ({login}: { login: string }, {dispatch, getState}) => {
        try {
            const res = await api.post<{ message: string, close_page: boolean }>('auth/reset-password/reset', {login});
            const {close_page} = res.data;
            if (!close_page) {
                dispatch(handleResetPassword(true));
            }
            return res.data;
        } catch (e: any) {
            helperHandleError('registration, appSlice', e, dispatch);
        }
    }
);

export const updatePassword = createAsyncThunk(
    'app/updatePassword',
    async ({token, password, login, navigate}: IUpdatePasswordProps, {dispatch}) => {
        try {
            const res = await api.post<ITokenRes>(`/auth/reset-password/update/${token}`, {login, password});
            if (res.status === 200) {
                localStorage.setItem('token', JSON.stringify({token: res.data.token, expires_at: res.data.expires_at}));
                dispatch(setToken({token: res.data.token, expires_at: res.data.expires_at}));
                navigate('/profile');
            }
            return;
        } catch (e: any) {
            helperHandleError('registration, appSlice', e, dispatch);
        }
    }
);

export const logout = createAsyncThunk(
    'app/logout',
    async ({navigate}: { navigate: NavigateFunction }, {getState, dispatch}) => {
        const {app} = getState() as { app: IStateApp };
        try {
            const res = await api.post<{ message: string }>('/auth/logout', null, {
                headers: {
                    'Authorization': `${app?.token?.token_type} ${app?.token?.token}`
                }
            });

            if (res.status === 200) {
                await localStorage.removeItem('token');
                dispatch(setToken({token: null, expires_at: null}));
                navigate('/login');
            }
            return;
        } catch (e) {
            helperHandleError('registration, appSlice', e, dispatch);
        }
    }
);

export const getBrands = createAsyncThunk(
    'app/getBrands',
    async ({search}: { search: string }, {dispatch, getState}) => {
        const {app} = getState() as { app: IStateApp };
        const req = {
            vocabularies: [{
                vocabulary: 'App\\Models\\Catalog\\SysProduct.brand',
                page: 0,
                per_page: 100,
                search
            }]
        };
        try {
            const res = await api.post('/vocabulary', req, {
                headers: {
                    'Authorization': `${app?.token?.token_type} ${app?.token?.token}`
                }
            });
            const {data} = await res;
            return data;
        } catch (e) {
            helperHandleError('registration, appSlice', e, dispatch);
        }
    }
);

export const handleMoreBrands = createAsyncThunk(
    'app/handleMoreBrands',
    async ({page}: { page: number }, {dispatch, getState}) => {
        try {
            const {app} = getState() as { app: IStateApp };
            const req = {
                vocabularies: [{
                    vocabulary: 'App\\Models\\Catalog\\SysProduct.brand',
                    page: page,
                    per_page: 100
                }]
            };
            const res = await api.post('/vocabulary', req, {
                headers: {
                    'Authorization': `${app?.token?.token_type} ${app?.token?.token}`
                }
            });

            const {data} = await res;
            return data;
        } catch (e) {
            helperHandleError('registration, appSlice', e, dispatch);
        }
    }
);

export const getCarBrands = createAsyncThunk(
    'app/getCarBrands',
    async ({search}: { search: string }, {dispatch, getState}) => {
        const {app} = getState() as { app: IStateApp };
        const req = {
            vocabularies: [{
                vocabulary: 'App\\Models\\Catalog\\SysProduct.car_brand',
                page: 0,
                per_page: 100,
                search
            }]
        };
        try {
            const res = await api.post('/vocabulary', req, {
                headers: {
                    'Authorization': `${app?.token?.token_type} ${app?.token?.token}`
                }
            });
            const {data} = await res;

            return data;
        } catch (e) {
            helperHandleError('registration, appSlice', e, dispatch);
        }
    }
);

export const handleMoreCarBrands = createAsyncThunk(
    'app/handleMoreCarBrands',
    async ({page}: { page: number }, {dispatch, getState}) => {
        try {
            const {app} = getState() as { app: IStateApp };
            const req = {
                vocabularies: [{
                    vocabulary: 'App\\Models\\Catalog\\SysProduct.car_brand',
                    page: page,
                    per_page: 100
                }]
            };
            const res = await api.post('/vocabulary', req, {
                headers: {
                    'Authorization': `${app?.token?.token_type} ${app?.token?.token}`
                }
            });

            const {data} = await res;
            return data;
        } catch (e) {
            helperHandleError('registration, appSlice', e, dispatch);
        }
    }
);

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        handleError: (state, {payload}: { payload: IError | null }) => {
            state.errorReq = payload;
        },
        handleSuccess: (state, {payload}: { payload: ISuccess | null }) => {
            state.successReq = payload;
        },
        handleResetPassword: (state, {payload}: { payload: boolean }) => {
            state.resetSuccess = payload;
        },
        setToken: (state, {payload}) => {
            state.token = payload.token;
            state.expires_at = payload.token?.expires_at ?? null;
        },
        setSortBy: (state, {payload}) => {
            state.order_by.key = payload.key;
            state.order_by.direction = payload.direction;
        },
        clearSortBy:(state) => {
            state.order_by.key = null;
            state.order_by.direction = null;
        }
    },
    extraReducers: builder => {

        builder.addCase(getBrands.fulfilled, (state: IStateApp, {payload}) => {
            state.brandsTotal = payload[0].total;
            state.brands = payload[0].brand;
        });
        builder.addCase(handleMoreBrands.fulfilled, (state: IStateApp, {payload}) => {
            state.brands = [...state.brands, ...payload[0].brand];
        });


        builder.addCase(getCarBrands.fulfilled, (state: IStateApp, {payload}) => {
            state.brandsTotal = payload[0].total;
            state.carBrands = payload[0].car_brand;
        });
        builder.addCase(handleMoreCarBrands.fulfilled, (state: IStateApp, {payload}) => {
            state.carBrands = [...state.carBrands, ...payload[0].car_brand];
        });
    }
});

export const {handleError, handleSuccess, handleResetPassword, setToken, setSortBy, clearSortBy} = appSlice.actions;

export default appSlice.reducer;
