import React from 'react';
import {NavLink} from 'react-router-dom';
import styles from './Navigation.module.scss';
import {screens} from './config';
import {CustomLink} from './CustomLink';

export const Navigation = () => {
    return (
        <div className={styles.navigation}>
            {screens.map(i => (
                <CustomLink key={i.path} path={i.path}>{i.label}</CustomLink>
            ))}
        </div>
    );
};

