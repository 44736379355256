export const enum avatarSizes {
    l= 'large',
    m = 'medium',
    sm = 'small'
}

export interface IAvatarProps {
    size: avatarSizes,
    full_name: string | undefined
}

export interface AvatarItemProps {
    image: string | undefined;
    full_name?: string | null
}

export interface IResponseUploadFile {
    name: string;
    mime: string;
    hash: string;
    extension: string;
    original_name: string;
    size: number;
    path: string;
    disk: string;
    group: string;
    user_id: number;
    updated_at: string;
    created_at: string;
    id: number;
    url:string;
    relativeUrl: string;
}
