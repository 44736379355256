interface CreateInitOptionsFilterProps {
    type: 'tires' | 'disks' | 'suppliers'
}

type filterOptions = {
    vocabulary: string;
    search?: string;
    page: number;
    per_page: number;
};

export interface IInitFilterRequest {
    vocabularies: filterOptions[]
}

export const createInitOptionsFilter = ({type}: CreateInitOptionsFilterProps): IInitFilterRequest => {
    const data: filterOptions[] = [];
    if (type === 'tires') {
        data.push(
            {vocabulary: 'App\\Models\\Catalog\\SysProduct.width', page: 0, per_page: 100},
            {vocabulary: 'App\\Models\\Catalog\\SysProduct.height', page: 0, per_page: 100},
            {vocabulary: 'App\\Models\\Catalog\\SysProduct.diameter', page: 0, per_page: 100},
            {vocabulary: 'App\\Models\\Catalog\\SysProduct.season', page: 0, per_page: 100},
            //{vocabulary: 'App\\Models\\Catalog\\SysProduct.brand', page: 0, per_page: 100},
            {vocabulary: 'App\\Models\\SysWarehouse.city', page: 0, per_page: 200},
        );
    }
    if( type === 'disks') {
        data.push(
            {vocabulary: 'App\\Models\\Catalog\\SysProduct.width', page: 0, per_page: 100},
            {vocabulary: 'App\\Models\\Catalog\\SysProduct.diameter', page: 0, per_page: 100},
            {vocabulary: 'App\\Models\\Catalog\\SysProduct.type', page: 0, per_page: 100},
            {vocabulary: 'App\\Models\\SysWarehouse.city', page: 0, per_page: 200},
            {vocabulary: 'App\\Models\\Catalog\\SysProduct.model', page: 0, per_page: 100},
            {vocabulary: 'App\\Models\\Catalog\\SysProduct.pcd', page: 0, per_page: 100},
            {vocabulary: 'App\\Models\\Catalog\\SysProduct.color', page: 0, per_page: 100},
            {vocabulary: 'App\\Models\\Catalog\\SysProduct.bolts_count', page: 0, per_page: 100},
            {vocabulary: 'App\\Models\\Catalog\\SysProduct.outfit', page: 0, per_page: 100},
            {vocabulary: 'App\\Models\\Catalog\\SysProduct.brand_dia', page: 0, per_page: 100},
        );
    }

    return {vocabularies: data};
};
