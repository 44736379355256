import React, {ReactNode} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import classNames from 'classnames';
import styles from './Navigation.module.scss';

export const CustomLink = ({path, children}:{path: string; children: ReactNode}) => {
    const {pathname} = useLocation();

    return pathname === path
        ? <span className={classNames(styles.navigation__item, styles.navigation__itemActive)}>{children}</span>
        : <NavLink to={path} className={styles.navigation__item}>{children}</NavLink>;
};
