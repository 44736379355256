import React from 'react';
import {IconProps} from './types';

export const Like = ({width, height, color, ...props}: IconProps) => {
    return (
        <svg {...props} width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.7663 15.1493C26.3753 14.7849 25.9111 14.4959 25.4002 14.2987C24.8893 14.1015 24.3417 14 23.7887 14C23.2357 14 22.6881 14.1015 22.1772 14.2987C21.6663 14.4959 21.2021 14.7849 20.8112 15.1493L19.9998 15.9051L19.1884 15.1493C18.3987 14.4137 17.3277 14.0004 16.2109 14.0004C15.0941 14.0004 14.023 14.4137 13.2333 15.1493C12.4436 15.8849 12 16.8827 12 17.923C12 18.9634 12.4436 19.9611 13.2333 20.6967L14.0447 21.4526L19.3182 26.365C19.7022 26.7228 20.2974 26.7228 20.6814 26.365L25.9549 21.4526L26.7663 20.6967C27.1574 20.3325 27.4677 19.9001 27.6793 19.4242C27.891 18.9483 28 18.4382 28 17.923C28 17.4079 27.891 16.8977 27.6793 16.4218C27.4677 15.9459 27.1574 15.5135 26.7663 15.1493Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" stroke={color}/>
        </svg>
    );
};
