import {SetFieldValue} from 'react-hook-form';
import {cond} from '../commonTypes';
import {IQueryItem} from './types';

interface IProps {
    data: any[];
    setValue: SetFieldValue<any>
}

function defaultCase(item: IQueryItem, setValue: SetFieldValue<any>){
    setValue(`${item.key}`, item.payload);
}
function frontCase(item: IQueryItem, setValue: SetFieldValue<any>){
    setValue(`${item.key}`, item.payload);
}
function rearCase(item: IQueryItem, setValue: SetFieldValue<any>){
    setValue(`${item.key}_rear`, item.payload);
}
function bulkPriceCase(item: IQueryItem, setValue: SetFieldValue<any>){
    item.cond === cond.gte
        ? setValue(`${item.key}`, item.payload)
        : setValue(`${item.key}_to`, item.payload);
}

function yearCase(item: IQueryItem, setValue: SetFieldValue<any>) {
    setValue(`${item.key}`, true);
}

export const setFormDataTyres = ({data, setValue}: IProps): { rear: boolean, isAdditional: boolean } => {
    const isRear = data.some(i => i.key === 'rear');
    const isAdditional = data.some(i => i.key === 'additional');

    const fields = data.filter(d => d.key !== 'rear' && d.key !== 'additional');

    const singleFields = fields.filter(i => i.collection === 'tyres' && !i.group);
    const singleFieldsCity = fields.filter(i => i.collection === 'warehouses' && !i.group);
    const singleFieldsSale = fields.filter(i => i.key === 'is_sale');

    for (const singleField of [...singleFields, ...singleFieldsCity, ...singleFieldsSale]) {
        defaultCase(singleField, setValue);
    }
    const frontFields = fields.filter(i => i.group === 'front');
    for (const frontField of frontFields) {
        frontCase(frontField, setValue);
    }
    const rearFields = fields.filter(i => i.group === 'rear');
    for (const rearField of rearFields) {
        rearCase(rearField, setValue);
    }
    const bulkPriceFields = fields.filter(i => i.key === 'bulk_price');
    for (const bulkPriceField of bulkPriceFields) {
        bulkPriceCase(bulkPriceField, setValue);
    }
    const yearFields = fields.filter(i => i.key === 'year');
    for (const restsField of yearFields) {
        yearCase(restsField, setValue);
    }

    return {
        rear: isRear, isAdditional
    };
};
