import {useEffect} from 'react';

interface IProps {
    ref: any;
    cb: (b:boolean) => void;
}

export const useListenClickDocument = ({ref, cb}:IProps) => {
    useEffect(() => {
        document.addEventListener('mousedown', function (event:any) {
            if(ref.current) {
                const container = ref?.current;
                const isCurrent = event.composedPath().includes(container);
                if(!isCurrent) {
                    cb(false);
                } else {
                    cb(true);
                }
            }
        });
    },[]);
};
