import React, {HTMLAttributes, useEffect, useRef, useState} from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useLocation, useSearchParams} from 'react-router-dom';
import * as qs from 'qs';
import {Button} from '../../../../fields';
import {ButtonType} from '../../../../fields/types';
import {useAppDispatch, useAppSelector, useDebounce, useResizeWindow} from '../../../../hooks';
import {colors} from '../../../../utils/constants';
import {Close} from '../../../../icons';
import {createInitOptionsFilter} from '../../../../helpers/createInitOptionsFilter';
import {useInitDisksFilterOptionsMutation} from '../../Disks.api';
import {Loader} from '../../../../components/Loader';
import {sendFilterDisks} from '../../thunks';
import {generateRequestDisks, setFormDataDisks} from '../../../../helpers';
import {clearDisks, setFilterDisksPayload} from '../../Disks.slice';
import {clearSortBy, getBrands, getCarBrands, handleMoreBrands, handleMoreCarBrands} from '../../../../store/app.slice';
import {checkQuantity} from '../../../../helpers/checkQuantity';
import styles from './filterStyle.module.scss';
import {AdditionalSections} from './AdditionalSections';
import {DataDisks} from './DataDisks';
import {ParamsDisks} from './paramsDisk';


const schema = yup.object({
    width_from: yup.string(),
    width_to: yup.string(),
    width_from_rear: yup.string(),
    width_to_rear: yup.string(),
    diameter: yup.string(),
    diameter_rear: yup.string(),
    pcd: yup.string(),
    pcd_rear: yup.string(),
    brand_dia: yup.string(),
    brand_dia_rear: yup.string(),
    outfit_from: yup.string(),
    outfit_to: yup.string(),
    outfit_from_rear: yup.string(),
    outfit_to_rear: yup.string(),

    brand: yup.string(),
    car_brand: yup.string(),
    model: yup.string(),
    original_sku: yup.string(),
    bolts_count: yup.string(),
    color: yup.string(),
    type: yup.string(),
    quantity: yup.string(),

    bulk_price: yup.string(),
    bulk_price_to: yup.string(),
});

export const FilterDisks = () => {
    const refFilter = useRef<HTMLDivElement>(null);
    const width = useResizeWindow();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const {token, brands, order_by, carBrands} = useAppSelector(state => state.app);
    const initOptions = createInitOptionsFilter({type: 'disks'});
    const [brandPage, setBrandPage] = useState<number>(0);
    const [brandCarPage, setBrandCarPage] = useState<number>(0);
    const [show, setShow] = useState<boolean>(false);
    const [filterHeight, setFilterHeight] = useState<string>('auto');
    const [_, setSearchParams] = useSearchParams();
    const [switchFilter, setSwitchFilter] = useState<boolean>(false);
    const [isRear, setRear] = useState<boolean>(false);
    const [quantity, setQuantity] = useState<boolean>(false);
    const [initDisksFilterOptions, {data, isSuccess}] = useInitDisksFilterOptionsMutation();

    const [searchBrand, setSearchBrand] = useState<string>('');
    const debounceValue = useDebounce({value: searchBrand, delay: 300});

    const [searchCarBrand, setSearchCarBrand] = useState<string>('');
    const debounceCarBrandValue = useDebounce({value: searchCarBrand, delay: 300});

    const [showLoader, setShowLoader] = useState<boolean>(false);

    useEffect(() => {
        if (location.search !== '') {
            const strParams = location.search.split('?')[1];
            const obj = qs.parse(strParams);
            const data: any[] = [];
            for (const datum of Object.values(obj)) {
                data.push(datum);
            }

            const {rear, isAdditional} = setFormDataDisks({data, setValue: methods.setValue});

            setSwitchFilter(isAdditional);
            setRear(rear);

            const reqData = data.filter(d => d.key !== 'rear' && d.key !== 'additional');

            dispatch(sendFilterDisks({data: reqData, sort: order_by}));
            setTimeout(() => {
                setShowLoader(false);
                setFilterHeight('auto');
            }, 400);
        } else {
            const promise = dispatch(sendFilterDisks({data: [], sort: order_by}));
            setTimeout(() => {
                setShowLoader(false);
                setFilterHeight('auto');
            }, 400);
            return () => promise.abort();
        }

        return () => {
            dispatch(clearDisks());
        };
    }, [location, order_by]);

    useEffect(() => {
        dispatch(getBrands({search: ''}));
        dispatch(getCarBrands({search: ''}));
        const request = initDisksFilterOptions({token, body: initOptions});

        return () => {
            request.abort();
            dispatch(clearSortBy());
        };
    }, []);

    useEffect(() => {
        if (debounceValue !== '') {
            dispatch(getBrands({search: debounceValue}));
        } else {
            dispatch(getBrands({search: ''}));
        }
    }, [debounceValue]);

    useEffect(() => {
        if (debounceCarBrandValue !== '') {
            dispatch(getCarBrands({search: debounceCarBrandValue}));
        } else {
            dispatch(getCarBrands({search: ''}));
        }
    }, [debounceCarBrandValue]);


    useEffect(() => {
        if (!isRear) {
            methods.unregister([
                'width_from_rear',
                'width_to_rear',
                'diameter_rear',
                'pcd_rear',
                'brand_dia_rear',
                'outfit_from_rear',
                'outfit_to_rear',
            ]);
        }
    }, [isRear]);

    const methods = useForm({
        resolver: yupResolver(schema),
    });

    const clearFilter = () => {
        setFilterHeight(`${refFilter.current.clientHeight}px`);
        setShowLoader(true);
        setSearchParams('');
        dispatch(setFilterDisksPayload(null));
        initDisksFilterOptions({token, body: initOptions});
        methods.reset();
    };

    const handleHasMore = () => {
        dispatch(handleMoreBrands({page: brandPage + 1}));
        setBrandPage(brandPage + 1);
    };

    const handleHasMoreCarBrand = () => {
        dispatch(handleMoreCarBrands({page: brandCarPage + 1}));
        setBrandCarPage(brandCarPage + 1);
    };

    const submit = methods.handleSubmit(values => {
        const fields = checkQuantity(values, quantity);
        const {data} = generateRequestDisks({
            values: fields, type: 'disks', rear: isRear, additional: switchFilter
        });
        const params = qs.stringify(data);
        setSearchParams(params);
        width < 999 && setShow(false);
    });

    return !show && width < 1000
        ? (
            <div
                className={styles.switchButton}>
                <Button text={'Фильтры'} viewType={ButtonType.link} onClick={() => setShow(true)}/>
            </div>
        ) : (
            <FormProvider {...methods} >
                <div ref={refFilter} className={styles.filter} style={{
                    height: filterHeight
                }}>
                    <div className={styles.filter__header}>
                        <h3 className={styles.form__title}>Фильтры</h3>
                        <Close
                            width={40}
                            height={40}
                            color={colors.grey200}
                            style={{cursor: 'pointer'}}
                            onClick={() => setShow(false)}
                        />
                    </div>

                    {
                        showLoader
                            ? <div className={styles.filter__loader}><Loader/></div>
                            : (
                                <div className={styles.form__row}>
                                    <ParamsDisks
                                        width={data?.width}
                                        brand={data?.brand}
                                        outfit={data?.outfit}
                                        diameter={data?.diameter}
                                        brand_dia={data?.brand_dia}
                                        pcd={data?.pcd}
                                        isRear={isRear}
                                        setRear={() => setRear(!isRear)}
                                    />
                                    <DataDisks
                                        brand={brands}
                                        handleSearchFromApi={search => setSearchBrand(search)}
                                        handleHasMore={handleHasMore}
                                    />
                                </div>
                            )
                    }

                    {
                        width > 1000
                            ? switchFilter && !showLoader
                                ? <AdditionalSections
                                    colors={data?.color}
                                    type={data?.type}
                                    city={data?.city}
                                    quantity={quantity}
                                    car_brand={carBrands}
                                    setQuantity={() => setQuantity(!quantity)}
                                    handleSearchFromApi={search => setSearchCarBrand(search)}
                                    handleHasMore={handleHasMoreCarBrand}
                                />
                                : null
                            : <AdditionalSections
                                colors={data?.color}
                                type={data?.type}
                                city={data?.city}
                                car_brand={carBrands}
                                quantity={quantity}
                                setQuantity={() => setQuantity(!quantity)}
                                handleSearchFromApi={search => setSearchCarBrand(search)}
                                handleHasMore={handleHasMoreCarBrand}
                            />
                    }
                    <div className={styles.buttonsWrap}>
                        <div className={styles.buttons}>
                            <Button
                                text={'Подобрать'}
                                className={styles.button}
                                viewType={ButtonType.filling}
                                type={'button'}
                                onClick={submit}
                            />
                            <Button
                                text={'Все фильтры'}
                                className={styles.button}
                                viewType={switchFilter ? ButtonType.outline : ButtonType.filling}
                                type={'button'}
                                onClick={() => setSwitchFilter(!switchFilter)}
                            />
                            <Button
                                text={'Очистить фильтры'}
                                className={styles.button}
                                viewType={ButtonType.outline}
                                onClick={clearFilter}
                            />
                        </div>
                    </div>

                </div>
            </FormProvider>
        );
};
