import React from 'react';
import {useAppSelector} from '../../../../hooks';
import {Loader} from '../../../../components/Loader';
import styles from './tableStyles.module.scss';
import {RestItem} from './RestItem';

export const InnerTableProduct = () => {
    const {rests, restLoading} = useAppSelector(state => state.disks);

    return (
        <div>
            <div className={styles.innerHeader}>
                <div className={styles.box__sm} />
                <div className={styles.innerProvider}>
                    <span className={styles.head}>Поставщик</span>
                </div>
                <div className={styles.innerRemoteness}>
                    <span className={styles.head}>Удаленность</span>
                </div>
                <div className={styles.innerYear}>
                    <span className={styles.head}>Год</span>
                </div>
                <div className={styles.innerRemainder}>
                    <span className={styles.head}>Остаток</span>
                </div>
                <div className={styles.innerWholesale}>
                    <span className={styles.head}>Опт, ₽</span>
                </div>
                <div className={styles.innerRetail}>
                    <span className={styles.head}>Розница, ₽</span>
                </div>
                <div className={styles.data__empty} />
            </div>
            <div className={styles.innerlist}>
                {restLoading ? <Loader/>
                    : (
                        rests.map((rest, index) => (
                            <RestItem
                                key={index}
                                year={rest.year}
                                city={rest.city}
                                warehouse_original_sku={rest.warehouse_original_sku}
                                warehouse={rest.warehouse}
                                is_sale={rest.is_sale}
                                last_available_at={rest.last_available_at}
                                bulk_price={rest.bulk_price}
                                retail_price={rest.retail_price}
                                quantity={rest.quantity}/>
                        ))
                    )}
            </div>

        </div>
    );
};
