import React, {HTMLAttributes, useRef, useState} from 'react';
import classNames from 'classnames';
import {ArrowDown, ArrowLeft, ArrowRight, ArrowUp, Spikes, Summer, Winter} from '../../../../icons';
import {colors} from '../../../../utils/constants';
import {ITire} from '../../types';
import {numberFormat} from '../../../../helpers';
import {useListenClickDocument} from '../../../../hooks';
import {Modal} from '../../../../components/Modal';
import styles from './tableStyles.module.scss';
const defaultImage = require('../../../../assets/images/tires.jpg');

interface IProductProps extends ITire {
    showChevron: boolean;
    isOpen: boolean;
    //handleShowDetails?: () => void;
}

export const Product = (
    {
        isOpen,
        showChevron,
        id,
        retail_price,
        bulk_price,
        original_sku,
        model,
        season,
        title,
        quantity, is_spikes
    }: IProductProps) => {

    return (
        <>
            {showChevron ? <div className={classNames(styles.box, styles.box__sm)}>
                {isOpen
                    ? <ArrowLeft
                        width={8}
                        height={16}
                        color={colors.grey200}
                        style={{cursor: 'pointer'}}
                    />
                    : <ArrowRight
                        width={8}
                        height={16}
                        color={colors.grey200}
                        style={{cursor: 'pointer'}}
                    />
                }
            </div> : null}
            <div className={classNames(styles.box, styles.box__name)}>
                {/*<div className={styles.image}>*/}
                {/*    <img src={defaultImage} alt={'tire'}/>*/}
                {/*</div>*/}
                <p className={styles.text}>{title}</p>
            </div>
            <div className={classNames(styles.box, styles.box__empty)}>
                {season === 'Лето'
                    ? <Summer width={24} height={24} color={colors.yellow}/>
                    : <Winter width={24} height={24} color={colors.winter}/>}
                {is_spikes ? <Spikes width={24} height={24} color={colors.grey200}/> : false}
            </div>
            <div className={classNames(styles.box, styles.box__remainder)}>
                <p className={styles.text}>{numberFormat(quantity)}</p>
            </div>
            <div className={classNames(styles.box, styles.box__wholesale)}>
                <p className={styles.text}>{`${numberFormat(bulk_price)} ₽`}</p>
            </div>
            <div className={classNames(styles.box, styles.box__retail)}>
                <p className={styles.text}>{`${numberFormat(retail_price)} ₽`}</p>
            </div>
        </>
    );
};
