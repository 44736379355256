import React from 'react';
import {IconProps} from './types';

export const Summer = ({width, height, color, ...props}:IconProps) => {
    return (
        <svg {...props} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="5" fill={color}/>
            <path d="M12 3V5" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            <path d="M12 19V21" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            <path d="M19 12H21" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            <path d="M3 12H5" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            <path d="M5.29282 5.29282L6.70703 6.70703" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            <path d="M18.7072 5.29282L17.293 6.70703" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            <path d="M5.29282 18.7072L6.70703 17.293" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            <path d="M18.7072 18.7072L17.293 17.293" stroke={color} strokeWidth="2" strokeLinecap="round"/>
        </svg>
    );
};
