import React from 'react';
import {IconProps} from './types';

export const Winter = ({width, height, color, ...props}:IconProps) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 20V4" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            <path d="M4 12L20 12" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            <path d="M6 6L8 8" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            <path d="M6 18L8 16" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            <path d="M16 16L18 18" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            <path d="M16 7.99999L18 6" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            <circle cx="12" cy="12" r="2" fill="white"/>
        </svg>
    );
};
