import React from 'react';
import {NavLink} from 'react-router-dom';
import styles from './RulesLinks.module.scss';

export const RulesLinks = () => {
    return (
        <div className={styles.rulesLinks}>
            <p className={styles.rulesLinks__text}>Нажимая на кнопку, вы принимаете
                <NavLink to={'/'} className={styles.rulesLinks__link}>условия использования</NavLink>
                и
                <NavLink to={'/'} className={styles.rulesLinks__link}>политику конфиденциальности</NavLink>
            </p>
        </div>
    );
};

