import React from 'react';
import {NavLink} from 'react-router-dom';
import styles from './layouts.module.scss';

export const Footer = () => {
    return (
        <div className={styles.footer}>
            <NavLink to={'/'} className={styles.footer__link}>Условия использования</NavLink>
            <NavLink to={'/'} className={styles.footer__link}>Политика конфиденциальности</NavLink>
        </div>
    );
};
