import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {devBaseUrl, prodBaseUrl} from '../../api';
import {IInitFilterRequest} from '../../helpers/createInitOptionsFilter';
import {IRequestProps} from '../../commonTypes';
import {IInitTiresOptions} from './types';


export const TiresApi = createApi({
    reducerPath: 'tiresApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.NODE_ENV === 'development'
            ? devBaseUrl
            : prodBaseUrl
    }),
    endpoints: build => ({
        initTiresFilterOptions: build.mutation<IInitTiresOptions, IRequestProps<IInitFilterRequest>>({
            query: ({token, body}) => ({
                url: '/vocabulary',
                method: 'POST',
                body: body,
                headers: {
                    'Authorization': `${token?.token_type} ${token?.token}`
                },
            }),
            transformResponse: (response: IInitTiresOptions[]) => {
                const data: IInitTiresOptions = {} as IInitTiresOptions;

                for (const res of response) {
                    const key = Object.keys(res)[0];
                    data[key as keyof IInitTiresOptions] = Object.values(res)[0];
                }
                return data;
            }
        })
    })
});

export const {useInitTiresFilterOptionsMutation} = TiresApi;
