import React from 'react';
import PaginationButton from 'rc-pagination';
import {DoubleArrowLeft, DoubleArrowRight} from '../../icons';
import {colors} from '../../utils/constants';
import {IPaginationProps} from './types';
import './Pagination.scss';

export const Pagination = ({count, handle, current, showLessItems, pageSize, loading}: IPaginationProps) => {
    const onChangePage = (page: number) => {
        handle(page);
    };

    return count > 15 ? (
        <div className={'wrapper'}>
            <PaginationButton
                className={'pagination'}
                onChange={onChangePage}
                current={current}
                total={count}
                showLessItems={showLessItems}
                prevIcon={<DoubleArrowLeft width={24} height={24} color={colors.grey100}/>}
                nextIcon={<DoubleArrowRight width={24} height={24} color={colors.grey100}/>}
                jumpNextIcon={<>...</>}
                jumpPrevIcon={<>...</>}
                showTitle={false}
                disabled={loading}
                pageSize={pageSize}
            />
        </div>
    ) : <></>;
};

