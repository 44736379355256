import React, {useRef, useState} from 'react';
import classNames from 'classnames';
import {Details} from '../../../../components/Details';
import {ITire} from '../../types';
import {useAppDispatch, useAppSelector} from '../../../../hooks';
import {getTiresRests} from '../../thunks';
import styles from './tableStyles.module.scss';
import {TableItem} from './TableItem';
import {Product} from './Product';

interface ITableTiresAccordion extends ITire{
    isRear: boolean
}

export const TableTiresAccordion = (
    {
        id,
        retail_price,
        bulk_price,
        original_sku,
        model,
        season,
        title,
        brand,
        quantity,
        is_spikes,
        isRear,
        step
    }: ITableTiresAccordion) => {
    const dispatch = useAppDispatch();
    const {filterPayload} = useAppSelector(state => state.tires);
    const refAccordion = useRef<HTMLDivElement | null>(null);
    const [open, setOpen] = useState<boolean>(false);

    const handleGetDetails = (product_id: string) => {
        dispatch(getTiresRests({data: filterPayload, product_id}));
    };

    return (
        <div className={isRear && step <= 2 ? classNames(styles.tableItem, styles.tableItemBg) : styles.tableItem}>
            <div
                ref={refAccordion}
                aria-expanded={!open}
                className={open
                    ? classNames(styles.tableItemHeader, styles.accordionHeaderActive)
                    : styles.tableItemHeader
                }
            >
                <div className={styles.tableItemHeader__inner}
                     id={id.toString()}
                     onClick={() => {
                         handleGetDetails(id.toString());
                         setOpen(true);
                     }}>
                    <Product
                        title={title}
                        id={id}
                        brand={brand}
                        retail_price={retail_price}
                        bulk_price={bulk_price}
                        quantity={quantity}
                        season={season}
                        model={model}
                        original_sku={original_sku}
                        isOpen={open}
                        is_spikes={is_spikes}
                        showChevron={true}
                    />
                </div>
            </div>
            <Details handleClose={() => setOpen(!open)} isVisible={open}>
                <TableItem
                    title={title}
                    id={id}
                    brand={brand}
                    retail_price={retail_price}
                    bulk_price={bulk_price}
                    quantity={quantity}
                    season={season}
                    model={model}
                    original_sku={original_sku}
                    is_spikes={is_spikes}
                    isVisible={open}
                    handleClose={() => setOpen(!open)}
                />
            </Details>
        </div>
    );
};
