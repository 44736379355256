import React from 'react';
import {useTimer} from 'react-timer-hook';
import {ITimerProps} from './types';
import styles from './Timer.module.scss';

export const Timer = ({expiryTimestamp, handleReSendCode}: ITimerProps) => {
    const {
        seconds,
        minutes,
        hours,
        restart
    } = useTimer({
        expiryTimestamp,
    });

    const resendCode = () => {
        const time = new Date();
        time.setSeconds(time.getSeconds() + 3600);
        restart(time);
        handleReSendCode();
    };

    return (
        <div className={styles.timer}>
            <p className={styles.timer_item}><span>{hours}</span>
                :<span>{minutes}</span>
                :<span>{seconds}</span>
            </p>
            <button className={styles.timer__button} onClick={resendCode}>Запросить еще раз</button>
        </div>
    );
};

