import {cond} from '../commonTypes';
import {IGenerateRequestProps, IQueryItem} from './types';
import {getPastTime} from './getPastTime';


function createObjRequest({key, payload, cond, collection, group}: IQueryItem) {
    if (group !== undefined) {
        return {key, payload, cond, collection, group};
    }
    return {key, payload, cond, collection};
}


const caseRearArray: string[] = [
    'width_rear',
    'height_rear',
    'diameter_rear',
];

const caseAdditional: string[] = [
    'bulk_price_to'
];

const caseGetYear: string[] = [
    'year'
];

const ignoredKeysRear = [
    'width',
    'height',
    'diameter',
    'width_rear',
    'height_rear',
    'diameter_rear',
];

const ignoredKeysAdditional = [
    'bulk_price',
    'bulk_price_to',
    'year'
];

const stopCase = ['year', 'is_run_flat', 'is_at', 'is_light_duty', 'is_mud', 'is_sale'];

const getCollection = (key: string, type: string) => {
    if (['is_sale', 'bulk_price', 'bulk_price_to', 'year'].includes(key)) return 'rests';
    if (['city'].includes(key)) return 'warehouses';
    return type;
};

function defaultCase(key: string, values: any, type: string) {
    const result: IQueryItem[] = [];
    const obj = createObjRequest({
        key: key,
        payload: values[key],
        cond: cond.eq,
        collection: type,
    });

    result.push(obj);
    return result;
}

function isRear(key: string, values: any, type: string) {
    const result: IQueryItem[] = [];
    if (caseRearArray.includes(key)) {
        const keyValue = key.replace('_rear', '').trim();
        const payload1 = values[keyValue];
        const payload2 = values[key];
        const obj = createObjRequest({
            key: keyValue,
            payload: payload1,
            cond: cond.eq,
            collection: 'tyres',
            group: 'front'
        });
        result.push(obj);
        const obj2 = createObjRequest({
            key: keyValue,
            payload: payload2,
            cond: cond.eq,
            collection: 'tyres',
            group: 'rear'
        });
        result.push(obj2);
    }
    if (!ignoredKeysRear.includes(key)) {
        const obj = createObjRequest({
            key: key,
            payload: values[key],
            cond: cond.eq,
            collection: type,
        });

        result.push(obj);
    }

    return result;
}

function isAdditional(key: string, values: any, type: string) {
    if (stopCase.includes(key) && !values[key]) {
        return;
    }
    const result: IQueryItem[] = [];
    if (caseAdditional.includes(key)) {
        const keyValue = key.replace('_to', '').trim();
        const payload1 = values[keyValue];
        const payload2 = values[key];
        const obj = createObjRequest({
            key: keyValue,
            payload: payload1,
            cond: cond.gte,
            collection: 'rests',
        });
        result.push(obj);
        const obj2 = createObjRequest({
            key: keyValue,
            payload: payload2,
            cond: cond.lte,
            collection: 'rests',
        });
        result.push(obj2);
    }
    if (caseGetYear.includes(key)) {
        const year = getPastTime({backTime: 3});
        const obj = createObjRequest({
            key: key,
            payload: year,
            cond: cond.gte,
            collection: 'rests',
        });
        result.push(obj);
    }
    if (!ignoredKeysAdditional.includes(key)) {
        const obj = createObjRequest({
            key: key,
            payload: values[key],
            cond: key === 'quantity' ? cond.gte : cond.eq,
            collection: getCollection(key, type),
        });
        result.push(obj);
    }

    return result;
}

function fullCase(key: string, values: any, type: string) {
    const result: IQueryItem[] = [];
    if (caseRearArray.includes(key)) {
        const keyValue = key.replace('_rear', '').trim();
        const payload1 = values[keyValue];
        const payload2 = values[key];
        const obj = createObjRequest({
            key: keyValue,
            payload: payload1,
            cond: cond.eq,
            collection: 'tyres',
            group: 'front'
        });
        result.push(obj);
        const obj2 = createObjRequest({
            key: keyValue,
            payload: payload2,
            cond: cond.eq,
            collection: 'tyres',
            group: 'rear'
        });
        result.push(obj2);
    }
    if (caseAdditional.includes(key)) {
        if (stopCase.includes(key) && !values[key]) {
            return;
        }
        const keyValue = key.replace('_to', '').trim();
        const payload1 = values[keyValue];
        const payload2 = values[key];
        const obj = createObjRequest({
            key: keyValue,
            payload: payload1,
            cond: cond.gte,
            collection: 'rests',
        });
        result.push(obj);
        const obj2 = createObjRequest({
            key: keyValue,
            payload: payload2,
            cond: cond.lte,
            collection: 'rests',
        });
        result.push(obj2);
    }
    if (caseGetYear.includes(key)) {
        const year = getPastTime({backTime: 3});
        const obj = createObjRequest({
            key: key,
            payload: year,
            cond: cond.gte,
            collection: 'rests',
        });

        result.push(obj);
    }
    if (![...ignoredKeysRear, ...ignoredKeysAdditional].includes(key)) {
        const obj = createObjRequest({
            key: key,
            payload: values[key],
            cond: key === 'quantity' ? cond.gte : cond.eq,
            collection: getCollection(key, type),
        });

        result.push(obj);
    }

    return result;
}

export const generateRequestTyres = (
    {
        values, type, rear, additional
    }: IGenerateRequestProps
): { data: IQueryItem[] } => {

    const data: IQueryItem[] = [];

    for (const valuesKey in values) {
        if (!rear && !additional) {
            const result = defaultCase(valuesKey, values, type);
            data.push(...result);
        }
        if (rear && !additional) {
            const result = isRear(valuesKey, values, type);
            data.push(...result);
        }
        if (!rear && additional) {
            const result = isAdditional(valuesKey, values, type);
            result &&
            data.push(...result);
        }
        if (rear && additional) {
            const result = fullCase(valuesKey, values, type);
            result &&
            data.push(...result);
        }
    }

    if (rear) data.push({key: 'rear', payload: 'true', cond: cond.eq, collection: type});
    if (additional) data.push({key: 'additional', payload: 'true', cond: cond.eq, collection: type});

    const result = data.filter(i => i.payload !== '');

    return {data: result};
};
