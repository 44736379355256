import React, {useEffect} from 'react';
import {SelectSort} from '../../fields';
import {sortKeys, sortOptions, sortValues} from '../../utils/constants';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {IOption} from '../../fields/types';
import {clearSortBy, setSortBy} from '../../store/app.slice';
import styles from './style.module.scss';



export const SortByOrders = () => {
    const dispatch = useAppDispatch();
    const {order_by} = useAppSelector(state => state.app);

    const handleSelect = ({label, value}: IOption) => {
        dispatch(setSortBy({key: sortKeys[label], direction: value}));
    };

    return (
        <div className={styles.filter}>
            <SelectSort
                placeholder={'По наименованию А-Я'}
                defaultValue={order_by.key !== null ? sortValues[`${order_by.key}_${order_by.direction}`] : ''}
                handleSelect={handleSelect}
                options={sortOptions}
            />
            {order_by.key !== null ?
                <span
                    className={styles.filter__clearBtn}
                    onClick={() => dispatch(clearSortBy())}>очистить сортировку</span>
                : null}
        </div>
    );
};
