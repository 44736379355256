import React from 'react';
import ReactFileReader from 'react-file-reader';
import styles from '../Avatar.module.scss';
import {Photo} from '../../../icons';
import {colors} from '../../../utils/constants';
import {AvatarItemProps, IResponseUploadFile} from '../types';
import {api} from '../../../api';
import {useAppSelector} from '../../../hooks';
import {useDeleteAvatarMutation, useUpdateAvatarMutation} from '../../../pages/Profile/Profile.api';

export const AvatarLarge = ({image, full_name}: AvatarItemProps) => {
    const {token} = useAppSelector(state => state.app);
    const [updateAvatar] = useUpdateAvatarMutation();
    const [deleteAvatar, {reset}] = useDeleteAvatarMutation();

    const handleFiles = async (files: any) => {
        const formData = new FormData();
        formData.append('file', files[0]);
        formData.append('group', 'avatar');

        const res = await api.post<IResponseUploadFile>('/files/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `${token?.token_type} ${token?.token}`
            }
        });
        const {id} = await res.data;
        if(token) {
            updateAvatar({id, token});
        }
    };

    const removeAvatar = () => {
        if(token) {
            deleteAvatar({token});
            reset();
        }
    };

    return (
        <>
            <ReactFileReader fileTypes={['.png', '.jpg', '.jpeg', '.gif']} handleFiles={handleFiles}>
                <div className={styles.avatar}>
                    <div className={styles.avatar__imgWrap}>
                        {image !== undefined && <img className={styles.avatar__img} src={image} alt={'avatar'}/>}
                    </div>
                    <Photo className={styles.avatar__icon} width={24} height={24} color={colors.white}/>
                </div>
            </ReactFileReader>
            <div className={styles.avatar__boxName}>
                <h2 className={styles.avatar__name}>{full_name ? full_name : 'Укажите имя'}</h2>
                <button
                    className={styles.avatar__removeImage}
                    onClick={removeAvatar}
                >Удалить фото</button>
            </div>
        </>
    );
};
