import React from 'react';

export const LoaderIcon = () => {
    return (
        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_2416_54960" style={{maskType:'luminance'}} maskUnits="userSpaceOnUse" x="0" y="0" width="33"
                  height="33">
                <path
                    d="M16.46 32.23C25.2855 32.23 32.44 25.0755 32.44 16.25C32.44 7.42451 25.2855 0.27002 16.46 0.27002C7.63447 0.27002 0.47998 7.42451 0.47998 16.25C0.47998 25.0755 7.63447 32.23 16.46 32.23Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask0_2416_54960)">
                <path
                    d="M33.2202 24.63L29.1702 23.69C28.1376 23.4518 27.2418 22.8136 26.6793 21.9155C26.1169 21.0175 25.9338 19.9329 26.1702 18.9C26.2881 18.3877 26.5058 17.9036 26.8109 17.4755C27.116 17.0474 27.5025 16.6836 27.9483 16.405C28.3941 16.1264 28.8904 15.9384 29.409 15.8517C29.9275 15.7651 30.458 15.7815 30.9702 15.9L35.0202 16.84"
                    fill="#472CF5"/>
                <path
                    d="M31.89 6.11002L29.05 9.11002C28.3269 9.88522 27.3257 10.3417 26.2664 10.3792C25.207 10.4167 24.176 10.0322 23.4 9.31002C23.0146 8.95185 22.7038 8.52119 22.4851 8.04269C22.2665 7.5642 22.1444 7.04727 22.1258 6.52151C22.1072 5.99576 22.1925 5.47151 22.3769 4.97877C22.5612 4.48604 22.8409 4.0345 23.2 3.65002L26.03 0.650024"
                    fill="#472CF5"/>
                <path
                    d="M15.17 -2L16.39 2C16.5445 2.5023 16.5985 3.03012 16.5489 3.5533C16.4993 4.07648 16.3471 4.58477 16.101 5.04911C15.8549 5.51344 15.5197 5.92474 15.1146 6.25948C14.7095 6.59423 14.2424 6.84586 13.74 7C13.237 7.15429 12.7086 7.20786 12.1849 7.15762C11.6612 7.10739 11.1526 6.95435 10.6882 6.70726C10.2237 6.46018 9.81256 6.1239 9.47827 5.71769C9.14397 5.31148 8.89308 4.84331 8.73998 4.34L7.47998 0.34"
                    fill="#472CF5"/>
                <path
                    d="M-0.199951 8.42004L3.85005 9.36004C4.36237 9.47793 4.84642 9.69566 5.27454 10.0008C5.70265 10.3059 6.06642 10.6924 6.34504 11.1382C6.62367 11.584 6.81168 12.0803 6.89833 12.5988C6.98499 13.1174 6.96858 13.6479 6.85005 14.16C6.73216 14.6724 6.51443 15.1564 6.20933 15.5845C5.90422 16.0126 5.51772 16.3764 5.07192 16.655C4.62612 16.9337 4.12977 17.1217 3.61125 17.2083C3.09273 17.295 2.56222 17.2786 2.05005 17.16L-1.94995 16.23"
                    fill="#472CF5"/>
                <path
                    d="M1.14014 26.95L3.98014 23.9C4.70453 23.1262 5.70645 22.6716 6.76582 22.6359C7.82519 22.6003 8.85539 22.9866 9.63014 23.71C10.4053 24.4331 10.8618 25.4342 10.8993 26.4936C10.9368 27.553 10.5523 28.584 9.83014 29.36L6.99014 32.36"
                    fill="#472CF5"/>
                <path
                    d="M17.85 35.05L16.63 31.05C16.4757 30.5471 16.4221 30.0187 16.4724 29.495C16.5226 28.9713 16.6757 28.4627 16.9227 27.9982C17.1698 27.5338 17.5061 27.1226 17.9123 26.7883C18.3185 26.454 18.7867 26.2032 19.29 26.05C19.7923 25.8956 20.3201 25.8416 20.8433 25.8911C21.3665 25.9407 21.8748 26.0929 22.3391 26.339C22.8034 26.5851 23.2147 26.9203 23.5495 27.3254C23.8842 27.7305 24.1359 28.1976 24.29 28.7L25.51 32.7"
                    fill="#472CF5"/>
            </g>
        </svg>
    );
};
