import React from 'react';
import styles from './styles.module.scss';

interface IDataItemProps {
    label: string;
    text:string;
}

export const DataItem = ({label, text}:IDataItemProps) => {
    return (
        <div className={styles.dataItem}>
            <span className={styles.dataItem__Label}>{label}</span>
            <span className={styles.dataItem__text}>{text}</span>
        </div>
    );
};
