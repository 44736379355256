import React from 'react';
import {IconProps} from './types';

export const TyresIcon = ({width, height, color}:IconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 29 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.1731 15.875L21.7692 14.8125M12 15.875L14.5962 14.8125M5.15385 15.875L7.75 14.8125M19.1731 11.625L21.7692 12.6875M12 11.625L14.5962 12.6875M5.15385 11.625L7.75 12.6875M21.25 7.375L23.8462 6.3125M14 7.375L16.5962 6.3125M7.23077 7.375L9.82692 6.3125M21.25 3.125L23.8462 4.1875M14 3.125L16.5962 4.1875M7.23077 3.125L9.82692 4.1875M1 13.75C1 16.0972 2.85974 18 5.15385 18H21.7692C24.0633 18 25.9231 16.0972 25.9231 13.75C25.9231 11.4028 24.0633 9.5 21.7692 9.5H5.15385C2.85974 9.5 1 11.4028 1 13.75ZM3.07692 5.25C3.07692 7.59721 4.93666 9.5 7.23077 9.5H23.8462C26.1403 9.5 28 7.59721 28 5.25C28 2.90279 26.1403 1 23.8462 1H7.23077C4.93666 1 3.07692 2.90279 3.07692 5.25Z"
                stroke={color} strokeWidth="2" strokeLinecap="round"/>
        </svg>

    );
};
