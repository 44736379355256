import React from 'react';
import {IconProps} from './types';

export const Close = ({width, height, color, ...props}:IconProps) => {
    return (
        <svg {...props} width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25 25L20 20M20 20L25 15M20 20L15 25M20 20L15 15" stroke={color} strokeWidth="2"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" stroke={color}/>
        </svg>
    );
};
