import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {IRequestProps} from '../../commonTypes';
import {devBaseUrl, prodBaseUrl} from '../../api';
import {IInitDiskOptions} from './types';

export const DisksApi = createApi({
    reducerPath: 'disksApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.NODE_ENV === 'development'
            ? devBaseUrl
            : prodBaseUrl
    }),
    endpoints: build => ({
        initDisksFilterOptions: build.mutation<IInitDiskOptions, IRequestProps<any>>({
            query: ({token, body}) => ({
                url: '/vocabulary',
                method: 'POST',
                body: body,
                headers: {
                    'Authorization': `${token?.token_type} ${token?.token}`
                }
            }),
            transformResponse: (response: IInitDiskOptions[]) => {
                const data: IInitDiskOptions = {} as IInitDiskOptions;
                for (const res of response) {
                    const key = Object.keys(res)[0];
                    data[key as keyof IInitDiskOptions] = Object.values(res)[0];
                    if(key === 'brand') {
                        data['totalBrandItem'] = Object.values(res)[1];
                    }
                }
                return data;
            }
        }),
    })
});

export const {useInitDisksFilterOptionsMutation} = DisksApi;
