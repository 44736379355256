import React, {useEffect, useMemo, useRef, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useLocation, useSearchParams} from 'react-router-dom';
import * as qs from 'qs';
import {Button} from '../../../../fields';
import {ButtonType} from '../../../../fields/types';
import {useAppDispatch, useAppSelector, useDebounce, useResizeWindow} from '../../../../hooks';
import {colors} from '../../../../utils/constants';
import {Close} from '../../../../icons';
import {createInitOptionsFilter} from '../../../../helpers/createInitOptionsFilter';
import {useInitTiresFilterOptionsMutation} from '../../Tires.api';
import {Loader} from '../../../../components/Loader';
import {clearTires, setFilterPayload} from '../../Tires.slice';
import {generateRequestTyres, setFormDataTyres} from '../../../../helpers';
import {sendFilter} from '../../thunks';
import {clearSortBy, getBrands, handleMoreBrands} from '../../../../store/app.slice';
import {checkQuantity} from '../../../../helpers/checkQuantity';
import styles from './style.module.scss';
import {TypesParams} from './TypesParams';
import {TypesData} from './TypesData';
import {TypesAdditional} from './TypesAdditional';


const schema = yup.object({
    width: yup.string(),
    width_rear: yup.string(),
    height: yup.string(),
    height_rear: yup.string(),
    diameter: yup.string(),
    diameter_rear: yup.string(),

    brand: yup.string(),
    model: yup.string(),
    original_sku: yup.string(),
    is_light_duty: yup.boolean(),
    is_run_flat: yup.boolean(),
    is_spikes: yup.boolean(),
    is_mud: yup.boolean(),
    is_at: yup.boolean(),
    weight: yup.string(),
    volume: yup.string(),
    season: yup.string(),
    bulk_price: yup.string(),
    bulk_price_to: yup.string(),
    retail_price: yup.number(),
    quantity: yup.string(),
    is_sale: yup.boolean(),
    year: yup.boolean(),
    city: yup.string()
});

interface IFilterProps {
    isRear: boolean;
    setRear: (state: boolean) => void;
}

export const TypesFilter = ({isRear, setRear}: IFilterProps) => {
    const methods = useForm({
        resolver: yupResolver(schema),
    });

    const refFilter = useRef<HTMLDivElement>(null);
    const width = useResizeWindow();
    const dispatch = useAppDispatch();
    const {token, brands, order_by} = useAppSelector(state => state.app);
    const location = useLocation();
    const [_, setSearchParams] = useSearchParams();
    const initOptions = createInitOptionsFilter({type: 'tires'});
    const [brandPage, setBrandPage] = useState<number>(0);
    const [show, setShow] = useState<boolean>(false);
    const [filterHeight, setFilterHeight] = useState<string>('auto');
    const [switchFilter, setSwitchFilter] = useState<boolean>(false);
    const [quantity, setQuantity] = useState<boolean>(false);
    const [searchBrand, setSearchBrand] = useState<string>('');
    const debounceValue = useDebounce({value: searchBrand, delay: 300});

    const [initTiresFilterOptions, {data}] = useInitTiresFilterOptionsMutation();

    const [showLoader, setShowLoader] = useState<boolean>(false);

    useEffect(() => {

        if (location.search !== '') {
            const strParams = location.search.split('?')[1];
            const obj = qs.parse(strParams);
            const data: any[] = [];
            for (const datum of Object.values(obj)) {
                data.push(datum);
            }

            const {rear, isAdditional} = setFormDataTyres({data, setValue: methods.setValue});
            setSwitchFilter(isAdditional);
            setRear(rear);

            const reqData = data.filter(d => d.key !== 'rear' && d.key !== 'additional');

            dispatch(sendFilter({data: reqData, sort: order_by}));
            setTimeout(() => {
                setShowLoader(false);
                setFilterHeight('auto');
            }, 400);
        } else {
            const promise = dispatch(sendFilter({data: [], sort: order_by}));
            setTimeout(() => {
                setShowLoader(false);
                setFilterHeight('auto');
            }, 400);
            return () => promise.abort();
        }

        return () => {
            dispatch(clearTires());
        };
    }, [location, order_by]);

    useEffect(() => {
        dispatch(getBrands({search: ''}));
        const request = initTiresFilterOptions({token, body: initOptions});
        return () => {
            request.abort();
            dispatch(clearSortBy());
        };
    }, []);

    useEffect(() => {

        if (debounceValue !== '') {
            dispatch(getBrands({search: debounceValue}));
        } else {
            dispatch(getBrands({search: ''}));
        }
    }, [debounceValue]);

    const clearFilter = () => {
        setFilterHeight(`${refFilter.current.clientHeight}px`);
        setShowLoader(true);
        setBrandPage(0);
        setSearchParams('');
        dispatch(setFilterPayload([]));
        initTiresFilterOptions({token, body: initOptions});
        methods.reset();
    };

    useEffect(() => {
        if (!switchFilter) {
            methods.unregister([
                'bulk_price',
                'bulk_price_to',
                'quantity',
                'city',
                'is_sale',
                'year',
                'is_run_flat',
                'is_at',
            ]);
        }
    }, [switchFilter]);

    const handleHasMore = () => {
        dispatch(handleMoreBrands({page: brandPage + 1}));
        setBrandPage(brandPage + 1);
    };

    const submit = methods.handleSubmit(values => {

        const fields = checkQuantity(values, quantity);
        const {data} = generateRequestTyres(
            {
                values: fields,
                type: 'tyres',
                rear: isRear,
                additional: switchFilter
            });

        const params = qs.stringify(data);
        setSearchParams(params);
        setBrandPage(0);
        width < 999 && setShow(false);
    });

    return !show && width < 1000
        ? (
            <div className={styles.switchButton}>
                <Button
                    text={'Фильтры'}
                    viewType={ButtonType.link}
                    onClick={() => setShow(true)}/>
            </div>
        ) : (
            <FormProvider {...methods} >
                <div ref={refFilter} className={styles.filter} style={{
                    height: filterHeight
                }}>
                    <div className={styles.filter__header}>
                        <h3 className={styles.form__title}>Фильтры</h3>
                        <Close
                            width={40}
                            height={40}
                            color={colors.grey200}
                            style={{cursor: 'pointer'}}
                            onClick={() => setShow(false)}
                        />
                    </div>

                    {showLoader ? <div className={styles.filter__loader}><Loader/></div>
                        : (
                            <div className={styles.row}>
                                <div className={styles.col}>
                                    <TypesParams
                                        height={data?.height}
                                        width={data?.width}
                                        diameter={data?.diameter}
                                        isRear={isRear}
                                        setRear={(state) => setRear(state)}
                                    />
                                </div>
                                <div className={styles.col}>
                                    <TypesData
                                        season={data?.season}
                                        brand={brands}
                                        handleSearchFromApi={search => setSearchBrand(search)}
                                        handleHasMore={() => handleHasMore()}
                                    />
                                </div>
                            </div>
                        )}

                    {width > 1000
                        ? switchFilter && !showLoader
                            ?
                            <TypesAdditional
                                city={data?.city}
                                setQuantity={() => setQuantity(!quantity)}
                                quantity={quantity}/>
                            : null
                        : <TypesAdditional
                            city={data?.city}
                            setQuantity={() => setQuantity(!quantity)}
                            quantity={quantity}/>}
                    <div className={styles.buttonsWrap}>
                        <div className={styles.buttons}>
                            <Button
                                text={'Подобрать'}
                                className={styles.button}
                                viewType={ButtonType.filling}
                                type={'button'}
                                onClick={submit}
                            />
                            <Button
                                text={'Все фильтры'}
                                className={styles.button}
                                viewType={switchFilter ? ButtonType.outline : ButtonType.filling}
                                type={'button'}
                                onClick={() => setSwitchFilter(!switchFilter)}
                            />
                            <Button
                                text={'Очистить фильтры'}
                                className={styles.button}
                                viewType={ButtonType.outline}
                                onClick={clearFilter}
                            />
                        </div>
                    </div>
                </div>
            </FormProvider>
        );
};
