import React, {useState} from 'react';
import classNames from 'classnames';
import {Close, Like} from '../../../../icons';
import {colors} from '../../../../utils/constants';
import {IRow} from '../config';
import {useResizeWindow} from '../../../../hooks';
import styles from './styles.module.scss';
import {CompanyData} from './CompanyData';
import {CompanyInfo} from './CompanyInfo';

interface ICompanyDetailsProps extends IRow {
    handleVisible: () => void;
}

export const CompanyDetails = ({handleVisible, company, site, city, image, distance}: ICompanyDetailsProps) => {
    const [liked, setLiked] = useState<boolean>(false);
    const width = useResizeWindow();
    const [currentTab, setCurrentTab] = useState<number>(0);
    return (
        <div className={styles.company}>
            <div className={styles.company__close}>
                        <span
                            className={styles.company__dateRegistration}>Дата регистрации на сайте: 27 декабря 2022</span>
                <Close
                    width={40}
                    height={40}
                    color={colors.grey200}
                    onClick={handleVisible}/>
            </div>
            <div className={styles.company__body}>
                <div className={styles.company__header}>
                    <img className={styles.company__image} src={image} alt={'logo'}/>
                    <div>
                        <h2 className={styles.company__title}>{company}</h2>
                        <p className={styles.company__address}>г. Екатеринбург, ул 40 лет Комсомола 32 Б, литер
                            А</p>
                    </div>
                    <Like
                        width={40}
                        height={40}
                        color={liked ? colors.red : colors.grey200}
                        style={{
                            marginLeft: 'auto',
                            cursor: 'pointer'
                        }}
                        onClick={() => setLiked(!liked)}
                    />
                </div>
                <div
                    className={styles.buttons}
                    style={{transform: `translateX(${width < 540 ? currentTab === 0 ? 14 : -166 : 0}px)`}}>
                    <button
                        style={{ minWidth: '244px'}}
                        className={
                            currentTab === 1
                                ? classNames(styles.buttons__item, styles.activeButton)
                                : styles.buttons__item
                        }
                        onClick={() => setCurrentTab(0)}
                    >Данные компании
                    </button>
                    <button
                        style={{ minWidth: '244px'}}
                        className={
                            currentTab === 0
                                ? classNames(styles.buttons__item, styles.activeButton)
                                : styles.buttons__item
                        }
                        onClick={() => setCurrentTab(1)}
                    >Информация о компании
                    </button>
                </div>
                {
                    currentTab === 0
                        ? <CompanyData/>
                        : <CompanyInfo />
                }
            </div>
        </div>
    );
};
