import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {Button, ControlledSelect} from '../../../../fields';
import {ButtonType} from '../../../../fields/types';
import {Pagination} from '../../../../components/Pagination';
import {useAppDispatch, useAppSelector, useResizeWindow} from '../../../../hooks';
import {Grid, List} from '../../../../icons';
import {colors} from '../../../../utils/constants';
import {Loader} from '../../../../components/Loader';
import {clearDisks} from '../../Disks.slice';
import {IDisk} from '../../types';
import {getPageDisk, sendFilterDisks, showMoreDisks} from '../../thunks';
import {SortByOrders} from '../../../../components/SortByOrders';
import styles from './tableStyles.module.scss';
import {TableProductAccordion} from './TableProductAccordion';


export const TableProduct = () => {
    const width = useResizeWindow();
    const dispatch = useAppDispatch();
    const {meta, disks, loading, filterPayload, getMoreLoading} = useAppSelector(state => state.disks);
    const {order_by} = useAppSelector(state => state.app);
    const [viewType, setViewType] = useState<'grid' | 'list'>('list');

    useEffect(() => {
        return () => {
            dispatch(clearDisks());
        };
    }, []);

    useEffect(() => {
        if(Object.values(disks).some(i => i?.step) && viewType === 'grid') {
            setViewType('list');
        }
    }, [disks]);

    const handleMore = () => {
        if (meta.current_page < meta.last_page) {
            const url = `${meta.path}?page=${meta.current_page + 1}`;
            dispatch(showMoreDisks({data: filterPayload, url, sort: order_by}));
        }
    };

    const handlePagination = (page: number) => {
        const url = `${meta.path}?page=${page}`;
        dispatch(getPageDisk({data: filterPayload, url, sort: order_by}));
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    if (loading) {
        return <Loader style={{
            margin: '200px 0 0 0'
        }}/>;
    }

    if (disks.length === 0) {
        return (
            <div className={styles.notFound}>
                <p className={styles.notFound__title}>Нет подходящих товаров</p>
                <p className={styles.notFound__text}>попробуйте смягчить условия поиска</p>
            </div>
        );
    }

    return (
        <>
            <div className={viewType === 'list' ? styles.table : classNames(styles.table, styles.tableCard)}>
                <div className={styles.table__body}>
                    {!Object.values(disks).some(i => i?.step)
                        ? <div className={styles.table__filter}>
                            <SortByOrders/>
                            <Grid
                                width={53}
                                height={35}
                                color={viewType === 'grid' ? colors.black : colors.grey200}
                                background={viewType === 'grid' ? colors.grey400 : colors.white}
                                style={{
                                    marginLeft: '6px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => setViewType('grid')}
                            />
                            <List
                                width={35}
                                height={35}
                                color={viewType === 'list' ? colors.black : colors.grey200}
                                background={viewType === 'list' ? colors.grey400 : colors.white}
                                style={{
                                    marginLeft: '6px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => setViewType('list')}
                            />
                        </div> : null}
                    {viewType === 'list' ? <div className={styles.header}>
                        <div className={classNames(styles.box, styles.box__sm)}/>
                        <div className={classNames(styles.box, styles.box__name)}>
                            <span className={styles.head}>Название</span>
                        </div>
                        <div className={classNames(styles.box, styles.box__empty)}/>
                        <div className={classNames(styles.box, styles.box__remainder)}>
                            <span className={styles.head}>Остаток</span>
                        </div>
                        <div className={classNames(styles.box, styles.box__wholesale)}>
                            <span className={styles.head}>Опт, ₽</span>
                        </div>
                        <div className={classNames(styles.box, styles.box__retail)}>
                            <span className={styles.head}>Розница, ₽</span>
                        </div>
                    </div> : null}
                    <div
                        className={viewType === 'list' ? styles.table__inner : classNames(styles.table__inner, styles.table__innerFlex)}>
                        {disks.map((item: IDisk) =>
                            <TableProductAccordion
                                key={item.id}
                                brand={item.brand}
                                bulk_price={item.bulk_price}
                                countries={item.countries}
                                id={item.id}
                                model={item.model}
                                original_sku={item.original_sku}
                                quantity={item.quantity}
                                retail_price={item.retail_price}
                                title={item.title}
                                type={item.type}
                                viewType={viewType}
                                color={item.color}
                                step={item.step}
                                image={item.image}
                            />)}
                    </div>
                </div>
            </div>
            <div className={styles.table__footer}>
                {meta.current_page !== meta.last_page
                    ? getMoreLoading
                        ? <Loader/>
                        : <Button
                            style={{width: '100%'}}
                            text={'Показать еще'}
                            viewType={ButtonType.light}
                            onClick={handleMore}
                        /> : null}
                <Pagination
                    showLessItems={width < 768}
                    count={meta.total}
                    pageSize={meta.per_page}
                    handle={handlePagination}
                    loading={loading}
                    current={meta.current_page}/>
            </div>
        </>
    );
};
