import React, {useState} from 'react';
import Fuse from 'fuse.js';
import {IOption} from '../fields/types';

const options = {
    includeScore: true,
    shouldSort: true,
    keys: ['label']
};

export const useFuse = (list:IOption[]) => {
    const [result, setResult] = useState<any[]>([]);
    const fuse = new Fuse(list, options);

    const handleSearch = (value: string) => {
        const result = fuse.search(value);
        setResult(result);
    };

    return {
        result,
        handleSearch
    };
};
