import React from 'react';
import {IconProps} from './types';

export const Photo = ({width, height, color, ...props}:IconProps) => {
    return (
        <svg {...props} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M6.83205 6.5547L8.53518 4H15.4648L17.1679 6.5547C17.3534 6.8329 17.6656 7 18 7H21C21.2652 7 21.5196 7.10536 21.7071 7.29289C21.8946 7.48043 22 7.73478 22 8V19C22 19.2652 21.8946 19.5196 21.7071 19.7071C21.5196 19.8946 21.2652 20 21 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V8C2 7.73478 2.10536 7.48043 2.29289 7.29289C2.48043 7.10536 2.73478 7 3 7H6C6.33435 7 6.64658 6.8329 6.83205 6.5547ZM7 13C7 10.2386 9.23858 8 12 8C14.7614 8 17 10.2386 17 13C17 15.7614 14.7614 18 12 18C9.23858 18 7 15.7614 7 13Z"
                  fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M8 2C7.66565 2 7.35342 2.1671 7.16795 2.4453L5.46482 5H3C2.20435 5 1.44129 5.31607 0.87868 5.87868C0.316071 6.44129 0 7.20435 0 8V19C0 19.7957 0.316071 20.5587 0.87868 21.1213C1.44129 21.6839 2.20435 22 3 22H21C21.7957 22 22.5587 21.6839 23.1213 21.1213C23.6839 20.5587 24 19.7957 24 19V8C24 7.20435 23.6839 6.44129 23.1213 5.87868C22.5587 5.31607 21.7957 5 21 5H18.5352L16.8321 2.4453C16.6466 2.1671 16.3344 2 16 2H8ZM6.83205 6.5547L8.53518 4H15.4648L17.1679 6.5547C17.3534 6.8329 17.6656 7 18 7H21C21.2652 7 21.5196 7.10536 21.7071 7.29289C21.8946 7.48043 22 7.73478 22 8V19C22 19.2652 21.8946 19.5196 21.7071 19.7071C21.5196 19.8946 21.2652 20 21 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V8C2 7.73478 2.10536 7.48043 2.29289 7.29289C2.48043 7.10536 2.73478 7 3 7H6C6.33435 7 6.64658 6.8329 6.83205 6.5547Z"
                  fill={color}/>
            <path
                d="M9 13C9 11.3431 10.3431 10 12 10C13.6569 10 15 11.3431 15 13C15 14.6569 13.6569 16 12 16C10.3431 16 9 14.6569 9 13Z"
                fill={color}/>
        </svg>
    );
};
