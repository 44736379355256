import React from 'react';
import {IconProps} from './types';

export const Link = ({width, height, color, ...props}: IconProps) => {
    return (
        <svg {...props} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 7V15M17 7H9M17 7L7 17" stroke={color} strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    );
};
