import React, {useState} from 'react';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import classNames from 'classnames';
import {useAppDispatch, useResizeWindow} from '../../hooks';
import {logout} from '../../store/app.slice';
import {Menu} from '../../icons';
import {colors} from '../../utils/constants';
import styles from './Sidebar.module.scss';
import {sidebarConfig} from './config';

export const Sidebar = () => {
    const width = useResizeWindow();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [showMenu, setShowMenu] = useState<boolean>(false);

    const handleLogout = () => {
        dispatch(logout({navigate}));
    };

    return (
        <>
            <div className={styles.sidebarMobile}>
                 <div className={styles.sidebarMobile__title}>Профиль</div>
                <Menu
                    width={40}
                    height={40}
                    color={colors.grey200}
                    style={{cursor: 'pointer'}}
                    onClick={() => setShowMenu(!showMenu)}
                />
            </div>
            <ul className={showMenu ? classNames(styles.sidebarList, styles.sidebarListShow) : styles.sidebarList}>
                {sidebarConfig.map(i => {
                    if (i.path === 'exit') {
                        return (
                            <button
                                key={i.path}
                                className={classNames(styles.sidebarList__item, styles.sidebarList__itemLogout)}
                                onClick={handleLogout}>
                                {i.label}
                            </button>);
                    }
                    return (
                        <NavLink
                            key={i.path}
                            to={i.path}
                            className={
                                location.pathname === i.path
                                    ? classNames(styles.sidebarList__itemActive, styles.sidebarList__item)
                                    : classNames(styles.sidebarList__item)}
                        >{i.label}</NavLink>);
                })}
            </ul>
        </>
    );
};

