import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {helperHandleError} from '../../helpers';
import {handleSuccess} from '../../store/app.slice';
import {api} from '../../api';
import {IStateApp} from '../../store/types';
import {IConfirmPhone, IProfileState} from './types';

const initialState:IProfileState = {
    confirmPhone: null
};

export const verifyEmail = createAsyncThunk(
    'profile/verifyEmail',
    async ({email}:{email:string}, {dispatch, getState}) => {
        const {app} = getState() as {app: IStateApp };
        try {
            const res = await api.post<{message: string}>('/user/email/verify', {email}, {
                headers : {
                    'Authorization': `${app.token?.token_type} ${app.token?.token}`
                }
            });
            const data = res.data;
            if(res.status === 200) {
                dispatch(handleSuccess(data));
            }
        } catch (e) {
            helperHandleError('registration, appSlice', e, dispatch);
        }
    }
);

export const changeEmail = createAsyncThunk(
    'profile/changeEmail',
    async ({email}:{email: string}, {dispatch, getState}) => {
        const {app} = getState() as {app: IStateApp };
        try {
            const res = await api.post<{message: string}>('/user/email/change', {email}, {
                headers : {
                    'Authorization': `${app.token?.token_type} ${app.token?.token}`
                }
            });
            const data = res.data;
            if(res.status === 200) {
                dispatch(handleSuccess(data));
            }
        } catch (e:any) {
            helperHandleError('registration, appSlice', e, dispatch);
        }
    }
);

export const sendConfirmPhone = createAsyncThunk(
    'profile/confirmPhone',
    async (_, {dispatch, getState}) => {
        const {app} = getState() as { app: IStateApp };

        try {
            const res = await api.get<IConfirmPhone>('/user/phone/verify',  {
                headers : {
                    'Authorization': `${app.token?.token_type} ${app.token?.token}`
                }
            });
            return res.data;
        } catch (e:any) {
            helperHandleError('registration, appSlice', e, dispatch);
        }
    }
);

export const sendChangePhone = createAsyncThunk(
    'profile/sendChangePhone',
    async ({phone}:{phone: string}, {dispatch, getState}) => {
        const {app} = getState() as { app: IStateApp };

        try {
            const res = await api.post<IConfirmPhone>('/user/phone/change',  {phone},{
                headers : {
                    'Authorization': `${app.token?.token_type} ${app.token?.token}`
                }
            });
            return res.data;
        } catch (e:any) {
            helperHandleError('registration, appSlice', e, dispatch);
        }
    }
);

const ProfileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers:{
        clearConfirmPhone: (state, {payload}) => {
            state.confirmPhone = payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(sendConfirmPhone.fulfilled, (state, {payload}:any) => {
            state.confirmPhone = payload;
        });
        builder.addCase(sendChangePhone.fulfilled, (state, {payload}:any) => {
            state.confirmPhone = payload;
        });

    }
});

export const {clearConfirmPhone} = ProfileSlice.actions;

export default ProfileSlice.reducer;
