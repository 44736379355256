import React from 'react';
import {IconProps} from './types';

export const Eye = ({width, height, color, ...props}: IconProps) => {
    return (
        <svg {...props} width={width} height={height} viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.82704 13.4965C2.23624 12.5749 2.23624 11.4251 2.82704 10.5035C4.14522 8.44728 7.08767 5 12 5C16.9123 5 19.8548 8.44728 21.173 10.5035C21.7638 11.4251 21.7638 12.5749 21.173 13.4965C19.8548 15.5527 16.9123 19 12 19C7.08767 19 4.14522 15.5527 2.82704 13.4965Z"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};
