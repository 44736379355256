import {IQueryItem, ISort} from '../helpers/types';

export const defaultMeta:IMetaResponse = {
    page: 0,
    per_page: 0,
    total: 0,
    current_page: 0,
    from: 0,
    last_page: 0,
    path: '',
    to: 0,
};

export const defaultLinks:ILinksResponse = {
    prev: null, first : null, last: null, next: null
};

export interface IMetaResponse {
    page: number;
    per_page: number;
    total: number
    current_page: number;
    from: number;
    last_page: number;
    path: string;
    to: number;
}

export interface ILinksResponse {
    first: string | null;
    last: string | null;
    next : string | null;
    prev: string | null
}

export interface IRequest { data: IQueryItem[], sort?: ISort, url?: string }

export interface IReqData {
    query: IQueryItem[];
    order_by?: ISort
}
