import React from 'react';
import {MainLayout} from '../../layouts';
import {Avatar} from '../../components/Avatar';
import {avatarSizes} from '../../components/Avatar/types';
import {useAppSelector} from '../../hooks';
import styles from './Profile.module.scss';
import {Tabs} from './components';
import {useGetUserProfileQuery} from './Profile.api';

export const Profile = () => {
    const {token} = useAppSelector(state => state.app);
    const {data} = useGetUserProfileQuery({token: token});

    return (
        <MainLayout>
            <div className={styles.profile}>
                <div className={styles.profile__header}>
                    <div className={styles.profile__header__box}>
                        <Avatar full_name={data?.full_name} size={avatarSizes.l} />
                    </div>
                    <div className={styles.profile__box}>
                        {/*<p className={styles.data__text}>Дата регистрации на сайте: 27 декабря 2022</p>*/}
                        {/*<p className={styles.data__text}>Приглашение от <strong>Компания A</strong></p>*/}
                    </div>
                </div>
                <div className={styles.profile__content}>
                    <Tabs/>
                </div>
            </div>
        </MainLayout>
    );
};
