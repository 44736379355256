import React from 'react';
import styles from './RecoveryPassword.module.scss';
import {NewPasswordForm} from './components';



export const NewPassword = () => {
    return (
        <div className={styles.recoveryPassword}>
            <NewPasswordForm />
        </div>
    );
};
