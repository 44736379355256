import React from 'react';
import {IconProps} from './types';

interface IButtonProps extends IconProps{
    background: string;
}

export const List = ({width = 40, height = 40, color = '#BCBCCC', background = 'white', ...props}:IButtonProps) => {
    return (
        <svg {...props} width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" fill={background}/>
            <path d="M26 15H14" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M26 20H14" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M26 25H14" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" stroke="#BCBCCC"/>
        </svg>

    );
};
