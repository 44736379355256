import {ITire} from '../pages/Tires/types';
import {IDisk} from '../pages/Disks/types';

type Data = ITire[] | IDisk[];

export const setStepsToTypes = (data:Data) => {
    let step:number = 0;
    return data.map((item,index) => {
        if(index % 4 === 0) step = 0;
        step ++;
        return {...item, step: step};
    });
};
