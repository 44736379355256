import React from 'react';
import {MainLayout} from '../../layouts/MainLayout';
import styles from './ErrorPage.module.scss';

export const ErrorPage = () => {
    return (
        <MainLayout>
            <div className={styles.errorPage}>
                <h1>404</h1>
            </div>
        </MainLayout>
    );
};
