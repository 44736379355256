import React, {useMemo} from 'react';
import {NavLink} from 'react-router-dom';
import {Navigation, NavigationMobile} from '../components/Navigation';
import {Logo} from '../icons';
import {Avatar} from '../components/Avatar';
import {avatarSizes} from '../components/Avatar/types';
//import {CartSwitcher} from '../components/CartSwitcher';
import {useAppSelector, useResizeWindow} from '../hooks';
import {useGetUserProfileQuery} from '../pages/Profile/Profile.api';
import styles from './layouts.module.scss';

export const Header = () => {
    const width = useResizeWindow();
    const {token} = useAppSelector(state => state.app);
    const {data} = useGetUserProfileQuery({token: token});

    const renderAvatar = useMemo(() => <Avatar full_name={data?.full_name} size={avatarSizes.m}/>, [data]);

    return (
        <div>
            <div className={styles.desktop}>
                <span>
                    <Logo/>
                </span>
                <Navigation/>
                <div className={styles.desktop__rightBox}>
                    {/*<NavLink to={'/profile'} className={styles.desktop__link}>*/}
                    {/*    <Plus width={12} height={12} color={colors.black}/>*/}
                    {/*    Добавить товары*/}
                    {/*</NavLink>*/}
                    {/*<CartSwitcher/>*/}
                    {width > 998 ? renderAvatar : null}
                </div>
            </div>
            <>
                <NavigationMobile/>
            </>
        </div>
    );
};

