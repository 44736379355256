import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import classNames from 'classnames';
import styles from '../RecoveryPassword.module.scss';
import {Button, ConfirmPassword, Input, InputPassword} from '../../../fields';
import {ButtonType} from '../../../fields/types';
import {Modal} from '../../../components/Modal';
import {SuccessCheck} from '../../../components/SuccessCheck';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {handleResetPassword, resetPassword, updatePassword} from '../../../store/app.slice';
import {isValidEmail, isValidPhoneAndEmail} from '../../../utils/constants';
import {CodeInput} from '../../../components/CodeInput';

const schema = yup.object({
    login: yup.string().matches(isValidPhoneAndEmail, 'Телефон или e-mail').required('Это поле обязательно к заполнению'),
});

const schemaPhone = yup.object({
    login: yup.string().matches(isValidPhoneAndEmail, 'Телефон или e-mail').required('Это поле обязательно к заполнению'),
    token: yup.string().required('Введите код'),
    password: yup.string().min(6, 'Пароль не может быть меньше 6 символов'),
    confirm_password: yup.string().oneOf([yup.ref('password')], 'Пароли не совпадают')
});

export const RecoveryForm = () => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + 3600); // 1 hour timer

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {resetSuccess} = useAppSelector(state => state.app);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');

    const [isPhone, setPhone] = useState<boolean>(false);

    const [waitingCode, setWaitingCode] = useState<{ code: string, phone: string }>({code: '', phone: ''});

    const {control, register, setValue, formState: {errors}, handleSubmit, reset} = useForm<any>({
        resolver: yupResolver(waitingCode.phone !== '' ? schemaPhone : schema),
        mode: 'onBlur'
    });

    useEffect(() => {
        setOpenModal(resetSuccess);
    }, [resetSuccess]);

    useEffect(() => {
        const isUnMounted = true;
        return () => {
            dispatch(handleResetPassword(!isUnMounted));
        };
    }, []);

    const handleJump = () => {
        navigate('/login');
    };

    const handleCloseModal = () => {
        dispatch(handleResetPassword(false));
        setOpenModal(false);
        reset();
    };

    const submit = handleSubmit(values => {
        if (isValidEmail.test(values.login)) {
            setEmail(values.login);
        } else {
            setPhone(true);
            setWaitingCode({...waitingCode, phone: values.login});
            if (values.password !== undefined) {
                return dispatch(updatePassword({
                    login: values.login,
                    navigate: navigate,
                    password: values.password,
                    token: values.token
                }));
            }
        }
        dispatch(resetPassword({...values}));
    });

    const sendCode = (code: string) => {
        setWaitingCode({...waitingCode, code});
        setValue('token', code);
    };

    const handleReSendCode = async () => {
        setPhone(false);
        setWaitingCode({...waitingCode, code: ''});
        await dispatch(resetPassword({login: waitingCode.phone}));
        setPhone(true);
    };

    return openModal ?
        (
            <Modal open={openModal} handleOpen={() => null}>
                <div className={styles.formSuccess}>
                    <SuccessCheck/>
                    <p className={styles.formSuccess__title}>Письмо отправлено</p>
                    <p className={styles.formSuccess__text}>
                        Мы отправили вам ссылку для восстановления на
                        <span
                            className={
                                classNames(
                                    styles.formSuccess__text,
                                    styles.formSuccess__textEmail
                                )
                            }>{email}</span>
                    </p>
                    <Button
                        text={'Письмо не пришло'}
                        viewType={ButtonType.link}
                        className={styles.formSuccess__button}
                        onClick={handleCloseModal}/>
                </div>
            </Modal>
        ) : (
            <div className={styles.form}>
                <h1 className={styles.form__title}>Восстановление пароля</h1>
                <div className={styles.form__box}>
                    <Input
                        label={'логин'}
                        placeholder={''}
                        register={register}
                        control={control}
                        fieldName={'login'}
                        required={true}
                        errors={errors}/>
                </div>
                <div className={styles.form__box}>
                    <CodeInput
                        show={isPhone}
                        sendCode={sendCode}
                        handleReSendCode={handleReSendCode}
                        error={errors['token'] !== undefined}
                        time={time}/>
                </div>
                {waitingCode.code !== ''
                    ? (<>
                        <div className={styles.form__box}>
                            <InputPassword
                                label={'Пароль'}
                                placeholder={''}
                                register={register}
                                control={control}
                                fieldName={'password'}
                                required={true}
                                errors={errors}
                            />
                        </div>
                        <div className={styles.form__box}>
                            <ConfirmPassword
                                label={'Повторите пароль'}
                                placeholder={''}
                                register={register}
                                control={control}
                                fieldName={'confirm_password'}
                                required={true}
                                errors={errors}
                            />
                        </div>
                    </>) : null}
                <div className={styles.form__box}>
                    <Button
                        text={'Восстановить пароль'}
                        viewType={ButtonType.filling}
                        style={{
                            width: '100%'
                        }}
                        type={'button'}
                        onClick={submit}
                    />
                </div>
                <div className={styles.form__box}>
                    <Button
                        text={'Я помню пароль 🙃'}
                        viewType={ButtonType.outline}
                        style={{width: '100%'}}
                        type={'button'}
                        onClick={handleJump}
                    />
                </div>
            </div>
        );
};
