import React from 'react';
import {IconProps} from './types';

export const EyeOff = ({width, height, color, ...props}: IconProps) => {
    return (
        <svg {...props} width={width} height={height} viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.51707 6.66431C4.76208 7.82973 3.55155 9.3739 2.82863 10.5011C2.23764 11.4226 2.23624 12.5749 2.82704 13.4965C4.14522 15.5528 7.08767 19 12 19C14.1687 19 15.9535 18.3281 17.384 17.4007"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M9.60397 10.1945C9.22482 10.6968 9 11.3222 9 12C9 13.6569 10.3431 15 12 15C12.732 15 13.4028 14.7378 13.9235 14.3023"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4 4L20 20" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M21.1729 10.5035L22.0148 9.96382L21.1729 10.5035ZM21.1729 13.4965L20.3311 12.9568L21.1729 13.4965ZM10.7115 5.0815L10.584 4.08965C10.2058 4.13824 9.88819 4.3977 9.76506 4.75858C9.64193 5.11947 9.73475 5.51897 10.0044 5.7886L10.7115 5.0815ZM20.3201 14.6901L19.613 15.3972C19.8146 15.5988 20.0926 15.7048 20.3773 15.6885C20.6619 15.6722 20.926 15.5352 21.1033 15.3119L20.3201 14.6901ZM12 6C16.3913 6 19.0718 9.07887 20.3311 11.0432L22.0148 9.96382C20.6377 7.8157 17.4333 4 12 4V6ZM20.3311 11.0432C20.711 11.6359 20.711 12.3641 20.3311 12.9568L22.0148 14.0362C22.8165 12.7857 22.8165 11.2143 22.0148 9.96382L20.3311 11.0432ZM10.8389 6.07334C11.2101 6.02565 11.5969 6 12 6V4C11.5118 4 11.0398 4.03109 10.584 4.08965L10.8389 6.07334ZM10.0044 5.7886L19.613 15.3972L21.0272 13.983L11.4186 4.37439L10.0044 5.7886ZM20.3311 12.9568C20.114 13.2954 19.8498 13.6743 19.537 14.0683L21.1033 15.3119C21.463 14.8589 21.766 14.4243 22.0148 14.0362L20.3311 12.9568Z"
                fill={color}/>
        </svg>
    );
};
