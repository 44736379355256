import React from 'react';
import styles from '../Avatar.module.scss';
import {AvatarItemProps} from '../types';

export const AvatarSmall = ({image, full_name}:AvatarItemProps) => {
    return (
        <div className={styles.avatarS}>
            <div className={styles.avatarS__imgWrap}>
                {image !== undefined && <img className={styles.avatarS__img} src={image} alt={'avatar'}/>}
            </div>
        </div>
    );
};
