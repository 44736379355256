import React from 'react';
import {IconProps} from './types';

export const File = ({width, height, color, ...props}:IconProps) => {
    return (
        <svg {...props} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 4H7C5.89543 4 5 4.89543 5 6V18C5 19.1046 5.89543 20 7 20H17C18.1046 20 19 19.1046 19 18V11M12 4V11H19M12 4L19 11"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};
