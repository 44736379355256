import React from 'react';
import {IconProps} from './types';

export const Spikes = ({width, height, color, ...props}:IconProps) => {
    return (
        <svg {...props} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9 20H15M9 20H6M9 20V14M15 20H18M15 20V14M15 14H16C17.1046 14 18 13.1046 18 12V9C18 7.89543 17.1046 7 16 7H14M15 14H9M9 14H8C6.89543 14 6 13.1046 6 12V9C6 7.89543 6.89543 7 8 7H10M10 7V5C10 4.44772 10.4477 4 11 4H13C13.5523 4 14 4.44772 14 5V7M10 7H14"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};
