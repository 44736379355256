import React from 'react';
import classNames from 'classnames';
import {Cart, Link} from '../../../../icons';
import {colors} from '../../../../utils/constants';
import {ITiresRests} from '../../../Tires/types';
import {numberFormat} from '../../../../helpers';
import styles from './tableStyles.module.scss';

export const RestItem = (
    {
        retail_price, bulk_price, last_available_at, is_sale, city, warehouse_original_sku, warehouse, year, quantity
    }: ITiresRests
) => {
    const handleLink = () => {

    };
    return (
        <div className={styles.innerContent}>
            <div className={styles.box__sm}/>
            <div className={styles.innerProvider}>
                    <span className={classNames(styles.text, styles.text__children)}>
                        -
                        <Link
                            width={24}
                            height={24}
                            color={colors.blue}
                            style={{cursor: 'pointer'}}
                            onClick={handleLink}/>
                    </span>
            </div>
            <div className={styles.innerRemoteness}>
                <span className={styles.text}>{warehouse.city}</span>
            </div>
            <div className={styles.innerYear}>
                <span className={styles.text}>{year === 0 ? '-' : year}</span>
            </div>
            <div className={styles.innerRemainder}>
                <span className={styles.text}>{numberFormat(quantity)}</span>
            </div>
            <div className={styles.innerWholesale}>
                <span className={styles.text}>{typeof bulk_price === 'string' ? '-' : `${numberFormat(bulk_price)} ₽`}</span>
            </div>
            <div className={styles.innerRetail}>
                <span className={styles.text}>{typeof retail_price === 'string' ? '-' : `${numberFormat(retail_price)} ₽`}</span>
            </div>
            <div className={styles.data}>
                {/*<span className={styles.data__text}>Склады в городе</span>*/}
                {/*<span className={styles.data__text}>*/}
                {/*        <Cart*/}
                {/*            style={{*/}
                {/*                marginRight: '12px'*/}
                {/*            }}*/}
                {/*            width={18}*/}
                {/*            height={18}*/}
                {/*            color={colors.grey200}*/}
                {/*        /> </span>*/}
            </div>
        </div>
    );
};
