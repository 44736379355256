import React from 'react';
import {useLocation} from 'react-router-dom';
import {MainLayout} from '../../layouts';
import styles from './Cart.module.scss';

export const Cart = () => {
    const location = useLocation();
    const cartName = location.state?.typeCart;

    return (
        <MainLayout>
            <div className={styles.cart}>
                <h1>{cartName}</h1>
            </div>
        </MainLayout>
    );
};
