import React, {ChangeEvent, useEffect, useState} from 'react';
import classNames from 'classnames';
import {colors} from '../../utils/constants';
import styles from './Email.module.scss';
import {IEmailProps} from './types';

const isValid = /^[\w]{1}[\w-\.]*@[\w-]+\.[a-z]{2,4}$/i;

export const Email = ({defaultValue, handleSendEmail, isVerify}:IEmailProps) => {
    const [email, setEmail] = useState<string>('');
    const [isFocus, setFocus] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        setEmail(defaultValue);
    }, [defaultValue]);

    const confirmEmail = () => {
        if(email.match(isValid)) {
            setError(false);
            handleSendEmail(email);
        } else {
            setError(true);
        }
    };

    return (
        <div className={styles.email}>
            <label
                className={
                    isFocus
                        ? error
                            ? classNames(styles.email__label, styles.colorError)
                            : classNames(styles.email__label, styles.colorActive)
                        : error
                            ? classNames(styles.email__label, styles.colorError)
                            : styles.email__label
                }
            >Email</label>
            <input
                className={styles.email__input}
                style={{
                    borderColor: isFocus
                        ? error ? colors.red : colors.blue
                        : error ? colors.red : colors.grey200
                }}
                value={email}
                onChange={(event:ChangeEvent<HTMLInputElement>) => {
                    setEmail(event.target.value);
                    setError(false);
                }}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
            />
            <p className={styles.email__changeText}>
                {isVerify ? 'Сменить адрес Email' : 'Адрес Email не подтвержден.'}
                <button
                    className={styles.email__button}
                    onClick={confirmEmail}
                >{defaultValue === email && defaultValue !== '' ? 'Подтверждено' : 'Подтвердить'}</button>
            </p>

            {error ? <p className={styles.errorText}>{'Не правильный формат почты'}</p>: null}
        </div>
    );
};

