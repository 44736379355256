import React from 'react';
import classNames from 'classnames';
import {IconButtonProps, IconButtonType} from './types';
import styles from './fields.module.scss';

export const IconButton = (
    {
        text,
        icon,
        viewType,
        className,
        ...props
    }: IconButtonProps
) => {
    return (
        <button {...props} className={classNames(styles[IconButtonType[viewType]], className)} >
            {icon}
            {text}
        </button>
    );
};
