import React from 'react';
import {useFormContext} from 'react-hook-form';
import {Checkbox, Input, Select} from '../../../../fields';
import {IOption} from '../../../../fields/types';
import {UiSelect} from '../../../../components/UiSelect';
import styles from './style.module.scss';

interface IProps {
    city: IOption[];
    quantity: boolean;
    setQuantity: () => void
}

export const TypesAdditional = ({city, setQuantity, quantity}: IProps) => {
    const {register, control, setValue, watch, formState: {errors}} = useFormContext();
    return (
        <>
            <div className={styles.dataAdditionalRow}>
                <div className={styles.dataAdditional}>
                    <Input
                        label={'Цена, от'}
                        placeholder={''}
                        register={register}
                        control={control}
                        fieldName={'bulk_price'}
                        errors={errors}
                        inputType={'number'}
                        required={false}
                    />
                </div>
                <div className={styles.dataAdditional}>
                    <Input
                        label={'Цена, до'}
                        placeholder={''}
                        register={register}
                        control={control}
                        fieldName={'bulk_price_to'}
                        errors={errors}
                        inputType={'number'}
                        required={false}
                    />
                </div>
                <div className={styles.dataAdditional}>
                    <Input
                        label={'Количество, от'}
                        placeholder={''}
                        register={register}
                        control={control}
                        fieldName={'quantity'}
                        errors={errors}
                        inputType={'number'}
                        required={false}
                    />
                </div>
                <div className={styles.dataAdditional}>
                    <UiSelect
                        label={'Город'}
                        options={city}
                        control={control}
                        fieldName={'city'}
                        errors={errors}
                        register={register}
                        isInfinity={false}/>
                </div>
                <div className={styles.dataAdditional}>
                    <Checkbox className={styles.form__checkbox}
                              label={'В наличии'}
                              state={quantity}
                              changeState={setQuantity}
                              style={{
                                  marginRight: '10px'
                              }}
                    />
                </div>
                <div className={styles.dataAdditional}>
                    <Checkbox
                        className={styles.form__checkbox}
                        label={'Распродажа'}
                        state={watch('is_sale')}
                        changeState={(state) => {
                            setValue('is_sale', state);
                        }}/>
                </div>
            </div>
            <div className={styles.bottomCheckboxes}>
                <div className={styles.checkboxWrapper}>
                    <Checkbox
                        className={styles.form__checkbox}
                        label={'Не старше 3-х лет'}
                        state={watch('year')}
                        changeState={(state) => {
                            setValue('year', state);
                        }}/>
                </div>
                <div className={styles.checkboxWrapper}>
                    <Checkbox
                        className={styles.form__checkbox}
                        label={'a/t'}
                        state={watch('is_at')}
                        changeState={(state) => {
                            setValue('is_at', state);
                        }}
                        style={{
                            marginRight: '10px'
                        }}
                    />
                    <Checkbox
                        className={styles.form__checkbox}
                        label={'m/t'}
                        state={watch('is_mud')}
                        changeState={(state) => {
                            setValue('is_mud', state);
                        }}
                    />
                </div>
                <div className={styles.checkboxWrapper}>
                    <Checkbox
                        className={styles.form__checkbox}
                        label={'Легкогрузовые'}
                        state={watch('is_light_duty')}
                        changeState={(state) => {
                            setValue('is_light_duty', state);
                        }}
                        style={{
                            marginRight: '10px'
                        }}
                    />
                    <Checkbox
                        className={styles.form__checkbox}
                        label={'Беспробойные'}
                        state={watch('is_run_flat')}
                        changeState={(state) => {
                            setValue('is_run_flat', state);
                        }}
                    />
                </div>
            </div>
        </>
    );
};
