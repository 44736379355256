import React from 'react';
import {IconProps} from './types';

export const DisksIcon = ({width, height, color}:IconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.6923 1.34615L10.8675 4.6747C10.9472 6.19004 12.5722 7.11109 13.9133 6.4011L16.9231 4.80769M10.6923 18.6538L10.8675 15.3253C10.9472 13.81 12.5722 12.8889 13.9133 13.5989L16.9231 15.1923M9.30768 1.34615L9.1325 4.6747C9.05274 6.19004 7.42777 7.11109 6.08668 6.4011L3.07691 4.80769M9.30768 18.6538L9.1325 15.3253C9.05274 13.81 7.42777 12.8889 6.08668 13.5989L3.07691 15.1923M18.0725 6.11367L14.8519 8.19418C13.611 8.99581 13.5801 10.8002 14.7929 11.6438L18.017 13.8864M1.92866 6.11367L5.14927 8.19418C6.39019 8.99581 6.42104 10.8002 5.20825 11.6438L1.98414 13.8864M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10ZM12.0769 10C12.0769 11.1471 11.1471 12.0769 10 12.0769C8.85295 12.0769 7.92308 11.1471 7.92308 10C7.92308 8.85295 8.85295 7.92308 10 7.92308C11.1471 7.92308 12.0769 8.85295 12.0769 10Z"
                stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
    );
};
