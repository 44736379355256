import React from 'react';
import {NavLink} from 'react-router-dom';
import {colors} from '../../utils/constants';
import {Avatar} from '../Avatar';
import {avatarSizes} from '../Avatar/types';
import styles from './Navigation.module.scss';
import {mobileScreens} from './config';

export const NavigationMobile = () => {
    return (
        <div className={styles.navigationMobile}>
            {mobileScreens.map(({path, label, Icon}, index) => {
                if(path === '/profile') {
                    return (
                        <NavLink key={index} to={path} className={styles.navigationMobile__link}>
                            <Avatar full_name={undefined} size={avatarSizes.sm} />
                            {label}
                        </NavLink>
                    );
                }
                return (
                    <NavLink key={index} to={path} className={styles.navigationMobile__link}>
                        <Icon
                            width={16}
                            heigth={18}
                            color={colors.black}
                            style={{marginBottom: '6px'}}
                        />
                        {label}
                    </NavLink>
                );
            })}
        </div>
    );
};
