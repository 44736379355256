import React, {useEffect} from 'react';
import classNames from 'classnames';
import {Button} from '../../../../fields';
import {ButtonType} from '../../../../fields/types';
import {Pagination} from '../../../../components/Pagination';
import {useAppDispatch, useAppSelector, useResizeWindow} from '../../../../hooks';
import {Loader} from '../../../../components/Loader';
import {clearTires} from '../../Tires.slice';
import {getPage, showMoreTires} from '../../thunks';
import {SortByOrders} from '../../../../components/SortByOrders';
import styles from './tableStyles.module.scss';
import {TableTiresAccordion} from './TableTiresAccordion';
import {TableHeader} from './TableHeader';


export const TableTires = ({isRear}:{isRear: boolean;}) => {
    const width = useResizeWindow();
    const dispatch = useAppDispatch();
    const {meta, tires, loading, filterPayload, getMoreLoading} = useAppSelector(state => state.tires);
    const {order_by} = useAppSelector(state => state.app);
    useEffect(() => {
        return () => {
            dispatch(clearTires());
        };
    }, []);

    const handleMore = () => {
        if (meta.current_page < meta.last_page) {
            const url = `${meta.path}?page=${meta.current_page + 1}`;
            dispatch(showMoreTires({data: filterPayload, sort: order_by, url}));
        }
    };

    const handlePagination = (page: number) => {
        const url = `${meta.path}?page=${page}`;
        dispatch(getPage({data: filterPayload, sort: order_by, url}));
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    if (loading) {
        return <Loader style={{margin: '200px 0 0 0'}}/>;
    }

    if (tires.length === 0) {
        return (
            <div className={styles.notFound}>
                <p className={styles.notFound__title}>Нет подходящих товаров</p>
                <p className={styles.notFound__text}>попробуйте смягчить условия поиска</p>
            </div>
        );
    }

    return (
        <>
            <div className={styles.table}>
                <div className={styles.table__body}>
                    {!Object.values(tires).some(i => i?.step) ? <div className={styles.table__filter}>
                        <SortByOrders/>
                    </div> : null}
                    <div className={styles.header}>
                        <div className={classNames(styles.box, styles.box__sm)}/>
                        <div className={classNames(styles.box, styles.box__name)}>
                            <span className={styles.head}>Название</span>
                        </div>
                        <div className={classNames(styles.box, styles.box__empty)}/>
                        <div className={classNames(styles.box, styles.box__remainder)}>
                            <span className={styles.head}>Остаток</span>
                        </div>
                        <div className={classNames(styles.box, styles.box__wholesale)}>
                            <span className={styles.head}>Опт, ₽</span>
                        </div>
                        <div className={classNames(styles.box, styles.box__retail)}>
                            <span className={styles.head}>Розница, ₽</span>
                        </div>
                    </div>
                    {tires.map((item, index) =>
                        <TableTiresAccordion
                            key={`${index}-${item.id}`}
                            title={item.title}
                            id={item.id}
                            brand={item.brand}
                            retail_price={item.retail_price}
                            bulk_price={item.bulk_price}
                            quantity={item.quantity}
                            season={item.season}
                            model={item.model}
                            is_spikes={item.is_spikes}
                            original_sku={item.original_sku}
                            isRear={isRear}
                            step={item?.step}
                        />)}
                </div>
            </div>
            <div className={styles.table__footer}>
                {meta.current_page !== meta.last_page
                    ? getMoreLoading
                        ? <Loader/>
                        : <Button
                            style={{width: '100%'}}
                            text={'Показать еще'}
                            viewType={ButtonType.light}
                            onClick={handleMore}
                        /> : null}
                <Pagination
                    showLessItems={width < 768}
                    count={meta.total}
                    pageSize={meta.per_page}
                    handle={handlePagination}
                    loading={loading}
                    current={meta.current_page}/>
            </div>
        </>
    );
};
