import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {IInfinityScrollProps} from './types';



export const CustomInfinityScroll = (
    {items, fetchData, hasMore, children, className, height}: IInfinityScrollProps
) => {
    if (!items) return <></>;
    return (
        <InfiniteScroll
            className={className}
            dataLength={items.length}
            next={fetchData}
            hasMore={hasMore}
            loader={<span className={'infinity-scroll__info'}/>}
            height={height}
        >
            {children}
        </InfiniteScroll>
    );
};
