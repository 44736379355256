import React from 'react';
import {IconProps} from './types';

export const Unchecked = ({width, height, color, ...props}:IconProps) => {
    return (
        <svg {...props} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="23" height="23" rx="7.5" fill="#F7F7FA" stroke={color} />
        </svg>
    );
};
